
import $ from 'jquery';
export const randomDocumentKey = () => {
    return Number(Math.random().toString().substring(2, 7) + Date.now()).toString(36)
}
const parsePaagraphStyle = (el) => {
    const style = {};
    if (el.css('margin-block-end') && parseInt(el.css('margin-block-end')) > 0) {
        style.marginBlockEnd = el.css('margin-block-end');
    }
    if (el.css('line-height')) {
        style.lineHeight = el.css('line-height');
    }
    return;
}
const parseImageStyle = (el) => {
    const style = {};
    const width = parseInt(el.css('width'));
    if (width) {
        style.width = width;
    }
    const height = parseInt(el.css('height'));
    if (height) {
        style.height = height;
    }
    return style;
}
const mergeChildren = (paragraph, children) => {
    let curParagraph = { ...paragraph };
    // const paragraphs = [curParagraph]
    const paragraphs = []

    children.forEach((child, idx) => {
        if (child.type === 'next') {
            if (children[idx + 1] && children[idx + 1].type !== 'paragraph' && children[idx + 1].type !== 'next') {
                if (curParagraph.children.length > 0) {
                    paragraphs.push(curParagraph);
                }
                curParagraph = { ...paragraph }
                curParagraph.children = [];
                // paragraphs.push(curParagraph);
            } else if (!children[idx + 1]) {
                if (curParagraph.children.length > 0) {
                    paragraphs.push(curParagraph);
                }
                curParagraph = { ...paragraph }
                curParagraph.children = [{ text: '' }];
                // paragraphs.push(curParagraph);
            }
        } else if (child.type === 'paragraph') {
            if (curParagraph.children.length > 0) {
                paragraphs.push(curParagraph);
            }
            curParagraph = { ...paragraph, ...child }
            // paragraphs.push(curParagraph);
        } else {
            curParagraph.children.push(child);
        }
    })
    if (curParagraph.children.length > 0) {
        paragraphs.push(curParagraph);
    }
    // console.log('curParagraph--paragraphs', paragraphs)
    return paragraphs;
}
const getChildren = (childNodes, options) => {
    let children = [];
    childNodes.forEach((node) => {
        const p = transform($(node));
        children = children.concat(p.map(c => ({ ...options, ...c })));
    })
    return children;
}
const transform = (el, options, ext, isP) => {


    if (!el[0]) {
        return [{
            text: ''
        }]
    }
    const { nodeName } = el[0];
    const name = nodeName?.toLowerCase();
    // console.log('--node--', name)
    if (name === 'p' || name === 'div') {
        let children = []
        el[0].childNodes.forEach((node) => {
            const p = transform($(node));
            children = children.concat(p);
        })
        const paragraph = { children: [] };
        if (el.css('margin-block-end') && parseInt(el.css('margin-block-end')) > 0) {
            paragraph.marginBlockEnd = el.css('margin-block-end');
        }
        if (el.css('line-height')) {
            paragraph.lineHeight = el.css('line-height');
        }
        return mergeChildren(paragraph, children);
        // paragraph.children = children;
        // return [paragraph];
    } else if (name === 'span') {
        const node = {}
        // node.text = el.text();
        if (el.css('background-color')) {
            node.backgroundColor = el.css('background-color')
        }
        if (el.css('color')) {
            node.fontColor = el.css('color')
        }
        if (el.css('border-radius')) {
            node.borderRadius = el.css('border-radius')
        }
        if (el.css('font-weight')) {
            node.bold = el.css('font-weight') ? true : false;
        }
        if (el.css('font-family')) {
            node.fontFamily = el.css('font-family')
        }
        if (el.css('white-space') === 'pre') {
            node.type = 'paragraph';
            node.children = []
            let children = []
            el[0].childNodes.forEach((child) => {
                const p = transform($(child));
                p.forEach(c => {
                    if (c.type === 'paragraph') {
                        children = children.concat(c.children);
                    } else {
                        children.push(c);
                    }
                })
            })
            const nodes = mergeChildren(node, children);
            // console.log("---nodes", nodes);
            return nodes
            // return [{ ...node, children }];
        }

        let children = []
        el[0].childNodes.forEach((child) => {
            const p = transform($(child));
            children = children.concat(p.map((c) => ({ ...node, ...c })))
        })
        return children;
    } else if (name === 'img') {
        const url = el.attr('data-src') || el.attr('src')
        if (!url) {
            return [{ text: '' }]
        }
        const tt = {
            url,
            type: 'image',
            // height: 0,
            // width: 0,
            ...parseImageStyle(el),
            state: 'done',
            percentage: 100,
            children: [
                {
                    text: ''
                }
            ]
        };
        const node = [{ text: '' }, tt, { text: '' }];
        // const node = [{ type: 'paragraph', children: [{ text: '' }, tt, { text: '' }], ...options }];
        return node;
    } else if (name === 'hr') {
        return [{
            type: 'hr',
            children: [{ text: '' }],
            fontColor: "#e7e9e8",
            style: "solid",
            width: 2
        }]
    } else if (name === 'a') {
        const href = el.attr('href');
        // console.log('-ssaa--', el[0], el[0].childNodes)

        // let children = [];
        // el[0].childNodes.forEach((node) => {
        //     const p = transform($(node));
        //     children = children.concat(p);
        // })
        return [{
            type: 'link',
            href,
            children: getChildren(el[0].childNodes),
            // children: el[0].childNodes.map((node) => {
            //     const p = transform($(node));

            //     return {
            //         ...p[0],
            //     };
            // })
        }]
    } else if (name === 'ol' || name === 'ul') {
        const listStyle = el.css('list-style-type');
        const key = options && options.key ? options.key : randomDocumentKey();
        let c = [], type = options?.type, level = options?.level;
        if (!options) {
            if (!listStyle && name === 'ol') {
                type = 'ordered-list';
                level = 0;
            } else if (listStyle === 'lower-alpha') {
                type = 'ordered-list';
                level = 1;
            } else if (listStyle === 'lower-greek') {
                type = 'ordered-list';
                level = 2;
            } else if (listStyle === 'lower-roman') {
                type = 'ordered-list';
                level = 3;
            } else if (listStyle === 'upper-alpha') {
                type = 'ordered-list';
                level = 4;
            } else if (listStyle === 'upper-roman') {
                type = 'ordered-list';
                level = 5;
            }
            // unordered list
            else if (!listStyle && name === 'ul') {
                type = 'unordered-list';
                level = 0;
            } else if (listStyle === 'circle') {
                type = 'unordered-list';
                level = 1;
            } else if (listStyle === 'square') {
                type = 'unordered-list';
                level = 2;
            }
            level = 0;
        } else {
            level += 1;
        }

        let cc = 0;
        el[0].childNodes.forEach((node) => {
            if (node.nodeName?.toLowerCase() === 'li') {
                const pp = {
                    level, //options && options.level ? options && options.level: 1,
                    start: cc + 1,
                    key,
                    type,
                    template: undefined
                }
                if (ext?.level) {
                    pp.lineIndent = (ext?.level ? ext.level : 0) * 32;
                }
                const p = transform($(node), pp, {
                    level: ext?.level ? ext.level + 1 : 1
                });
                c = c.concat(p);
                cc++;
            } else {
                const p = transform($(node), { lineIndent: ((ext?.level ? ext.level : 0) + 1) * 32 }, undefined, isP);
                c = c.concat(p);
            }

        })
        return c;
    } else if (name === 'li') {
        const children = []
        const p = [{
            ...options,
            children: [{ children }],
        }];
        el[0].childNodes.forEach((node) => {
            const nodeName = node.nodeName?.toLowerCase()
            if (nodeName !== 'ol' && nodeName !== 'ul') {
                children.push(...transform($(node)))
            } else {
                p.push(...transform($(node), {
                    ...options,
                    // level: options?.level ? options?.level + 1 : 1
                }, ext))
            }
        })
        return p;
    } else if (name === 'strong') {
        const n = transform($(el[0].childNodes[0]));
        return [{
            bold: true,
            ...n[0]
        }]
    } else if (name === 's') {
        const n = transform($(el[0].childNodes[0]));
        return [{
            strikethrough: true,
            ...n[0]
        }]
    } else if (name === 'em') {
        const n = transform($(el[0].childNodes[0]));
        return [{
            italic: true,
            ...n[0]
        }]
    } else if (name === 'br') {
        // return [{
        //     text: '\n'
        // }]
        return [{
            type: 'next',
            children: [{ text: '' }]
        }]
    } else if (name === 'h1') {
        getChildren(el[0].childNodes, parsePaagraphStyle(el))
        return [{
            type: 'title',
            children: getChildren(el[0].childNodes, parsePaagraphStyle(el))
            // children: el[0].childNodes.map((node) => {
            //     const p = transform($(node));
            //     return {
            //         ...p[0],
            //         ...parsePaagraphStyle(el)
            //     };
            // })
        }]
    } else if (name === 'table') {
        const tbody = el.find('tbody');
        if (tbody[0]?.childNodes[0]?.childNodes.length > 0) {
            return [{
                type: 'table',
                colsWidth: new Array(3).fill(parseInt((678 / tbody[0].childNodes[0].childNodes.length).toString())),
                children: getChildren(el[0].childNodes)
                // children: tbody[0].childNodes.map((node) => {
                //     const p = transform($(node));

                //     return {
                //         ...p[0],
                //     };
                // })
            }]
        }
    } else if (name === 'td') {
        const cell = { type: 'table-cell' }
        if (el.css('colspan')) {
            cell.colspan = parseInt(el.css('colspan'));
        }
        if (el.css('rowspan')) {
            cell.rowspan = parseInt(el.css('rowspan'));
        }
        cell.children = getChildren(el[0].childNodes)
        // cell.children = el[0].childNodes.map((node) => {
        //     const p = transform($(node));

        //     return {
        //         ...p[0],
        //     };
        // })
        return [cell];
    } else if (name === 'tr') {
        const row = { type: 'table-row' }
        if (el.css('height')) {
            row.height = parseInt(el.css('height'));
        }

        row.children = getChildren(el[0].childNodes);
        // row.children = el[0].childNodes.map((node) => {
        //     const p = transform($(node));

        //     return {
        //         ...p[0],
        //     };
        // })
        return [row]
    } else if (name === '#text') {
        const text = el.text();
        // console.log('d--d-d-d--d', text)
        if (text === '\n') {
            return [{ type: 'paragraph', children: [{ text: '' }] }]
        }
        if (isP) {
            return [
                {
                    children: [{ text: text === '\n' ? '' : text }]
                }
            ]
        }
        return [{
            text
        }]
    }
    const text = el.text();
    if (text === '\n') {
        return [{ type: 'paragraph', children: [{ text: '' }] }]
    }
    return [{
        text
    }]
}
export const htmlToJson = ({ html }) => {
    // console.log('---parseHtml', html)
    // if (!html) {
    //     return [{
    //         children: [{ text: '' }]
    //     }]
    // }
    if (!html) return null;
    html = html.replace(/\n\n<br>/g, "\n")
    html = html.replace(/\n<br>/g, "\n")
    // html = html.replace(/\r\n           /g, ' ')
    // html = html.replace(/\r\n    /g, '')

    // html = html.replace(/\r\n/g, "")
    // html = html.replace(/\n/g, "")


    const root = $(`<div id="root">${html}</div>`);

    let paragraphs = [];
    root[0].childNodes.forEach((node) => {
        const list = transform($(node), undefined, undefined, true);
        paragraphs = paragraphs.concat(list);
    })
    paragraphs = paragraphs.map((p) => {
        if (p.type === 'paragraph') {
            delete p.type;
        }
        if (!p.children || p.type === 'image') {
            return { children: [{ text: '' }, p, { text: '' }] };
        }
        return p;
    })
    // console.log('转化后数据:', [...paragraphs]);
    return paragraphs;
}   