<template>
  <div class="editor-header-bg">
    <div ref="eidtorHeader" class="nav-header chat-nav editor-nav">
      <div class="d-center"></div>
      <div class="d-center">
        <el-dropdown
            @command="handleCommand"
            :popper-class="[$root.config.theme, 'nav-dropdown']"
            v-show="isVip"
        >
          <div class="el-dropdown-link">
            <svg-icon icon-class="003" class-name="svg-s2"></svg-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="right"
              >
                <svg-icon
                    icon-class="028"
                    class-name="svg-small"
                />
                侧边模式
              </el-dropdown-item
              >
              <el-dropdown-item command="fixed"
              >
                <svg-icon
                    icon-class="026"
                    class-name="svg-small"
                />
                弹窗模式
              </el-dropdown-item
              >
              <el-dropdown-item command="bottom"
              >
                <svg-icon
                    icon-class="027"
                    class-name="svg-small"
                />
                底部模式
              </el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <div class="btn btn-font-gray d-center" @click="onClose">
          <!-- <svg-icon icon-class="002" class-name="svg-s1"></svg-icon> -->
          <el-icon>
            <CloseBold/>
          </el-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../../../svgIcon/index.vue";
import {vipMobiles} from "../../../utils/vipMobile";

export default {
  name: "EditorHeader",
  components: {SvgIcon},
  props: {
    noFixed: {type: Boolean, default: true, resizeObserver: null},
  },
  data() {
    return {mobile: "", isVip: false};
  },
  created() {
    let user = JSON.parse(localStorage.getItem("__user"));
    this.mobile = user ? user.mobile : null;
    this.isVip = vipMobiles.includes(this.mobile);
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.clientHeight) {
          this.$refs.eidtorHeader.style.top = entry.target.clientHeight + "px";
        } else {
          this.$refs.eidtorHeader.style.top = "10px";
        }
      }
    });
    if (this.$root.config.toolbarType === "top") {
      if (document.getElementById("toolbarContainer")) {
        this.resizeObserver.observe(document.getElementById("toolbarContainer"));
      }
    }
  },
  beforeUnmount() {
    if (document.getElementById("toolbarContainer"))
      this.resizeObserver.unobserve(document.getElementById("toolbarContainer"));
  },
  methods: {
    onChangeFixed() {
      this.$emit("update:noFixed", !this.noFixed);
    },
    onClose() {
      this.$emit("close", false);
    },
    handleCommand(command) {
      this.$emit("change-size", command);
    },
  },
};
</script>

<style scoped></style>
