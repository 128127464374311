<template>
  <div
    class="tree-label"
    :class="{ 'tree-edit': edit }"
    @contextmenu="onContextMenu"
    @click="onClick"
    @dblclick="onDouble"
  >
    <div v-if="item.iconType === 0">
      <svg-icon v-if="item.active" icon-class="apple_s" class-name="apple" />
      <svg-icon v-else icon-class="apple" class-name="apple" />
    </div>
    <div v-else-if="item.iconType === 1">
      <svg-icon icon-class="appleRed_s" class-name="apple" />
    </div>
    <div v-else-if="item.iconType === 2">
      <svg-icon icon-class="tree_l_dark" class-name="tree-forest" />
    </div>
    <div v-else-if="item.iconType === 20">
      <svg-icon
        v-if="item.active"
        icon-class="icon-tree_s"
        class-name="icon-tree"
      />
      <svg-icon v-else icon-class="icon-tree" class-name="icon-tree" />
    </div>
    <div v-else-if="item.iconType === 99">
      <svg-icon v-if="item.active" icon-class="tree_s" class-name="tree" />
      <svg-icon v-else icon-class="tree" class-name="tree" />
    </div>
    <input ref="input" v-model="value" @blur="onBlur" @focus="onFocus" />
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: undefined },
    item: { item: Object, default: undefined },
  },
  computed: {
    value: {
      get() {
        return this.title;
      },
      set(val) {
        this.$emit("update:title", val);
      },
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    onContextMenu(e) {
      e.preventDefault();
      return false;
    },
    onFocus() {
      let treeItem = this.$refs.input.parent("tree-item");
      if (treeItem) treeItem.style.pointerEvents = "none";
      this.edit = true;
    },
    onBlur() {
      this.$refs.input.style.pointerEvents = "none";
      let treeItem = this.$refs.input.parent("tree-item");
      if (treeItem) treeItem.style.pointerEvents = "auto";
      this.edit = false;
    },
    onDouble(e) {
      if (!this.edit) {
        this.$refs.input.select();
      }
    },
    onClick(e) {
      if (this.edit) {
        e.stopPropagation();
        return;
      }
      if (!this.allowDoubleClick) {
        this.allowDoubleClick = true;
        clearTimeout(this.timer);
        this.$refs.input.style.pointerEvents = "auto";
        this.timer = setTimeout(() => {
          this.allowDoubleClick = false;
          this.$refs.input.style.pointerEvents = "none";
        }, 200);
      } else {
        clearTimeout(this.timer);
        this.allowDoubleClick = false;
        // console.log('----double', this.$el);
      }
    },
  },
};
</script>
