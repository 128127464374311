<template>
  <div
    ref="content"
    class="drag-win drag-cross"
    :class="'direction-' + direction"
  >
    <div v-if="oneShow" class="drag-block drag-one" :style="oneStyle">
      <slot name="left-top" />
      <div
        class="drag-line-common drag-line-right"
        v-if="direction === 'row' && upMode === DragCrossMode.enabled"
        :class="{ resize: dragHReady || resizeHAble }"
        @mouseenter="onReadyDray('dragHReady')"
        @mouseleave="onCancelReadyDray('dragHReady')"
        @mousedown="onMouseHDown"
      />
      <div
        class="drag-line-common drag-line-right"
        v-else-if="
          direction === 'column' && downMode !== DragCrossMode.disabled
        "
        :class="{ resize: dragHReady || resizeHAble }"
        @mouseenter="onReadyDray('dragHReady')"
        @mouseleave="onCancelReadyDray('dragHReady')"
        @mousedown="onMouseHDown"
      />
      <div
        class="drag-line-common drag-line-bottom"
        v-if="
          direction === 'row' &&
          downMode !== DragCrossMode.disabled &&
          upMode !== DragCrossMode.disabled
        "
        :class="{ resize: dragVReady || resizeVAble }"
        @mouseenter="onReadyDray('dragVReady')"
        @mouseleave="onCancelReadyDray('dragVReady')"
        @mousedown="onMouseVDown"
      />
      <div
        class="drag-line-common drag-line-bottom"
        v-if="
          (direction === 'column' && upMode !== DragCrossMode.disabled) ||
          downMode !== DragCrossMode.disabled
        "
        :class="{ resize: dragVReady || resizeVAble }"
        @mouseenter="onReadyDray('dragVReady')"
        @mouseleave="onCancelReadyDray('dragVReady')"
        @mousedown="onMouseVDown"
      />
    </div>
    <div v-else style="width: 0; height: 0" class="drag-block">
      <slot name="left-top" />
    </div>
    <div v-if="twoShow" class="drag-block drag-two" :style="twoStyle">
      <slot name="right-top"></slot>
      <div
        class="drag-line-common drag-line-right"
        v-if="direction === 'column' && downMode !== DragCrossMode.disabled"
        :class="{ resize: dragHReady || resizeHAble }"
        @mouseenter="onReadyDray('dragHReady')"
        @mouseleave="onCancelReadyDray('dragHReady')"
        @mousedown="onMouseHDown"
      />
      <div
        class="drag-line-common drag-line-bottom"
        v-else-if="
          direction === 'row' &&
          downMode !== DragCrossMode.disabled &&
          upMode !== DragCrossMode.disabled
        "
        :class="{ resize: dragVReady || resizeVAble }"
        @mouseenter="onReadyDray('dragVReady')"
        @mouseleave="onCancelReadyDray('dragVReady')"
        @mousedown="onMouseVDown"
      />
    </div>
    <div v-else style="width: 0; height: 0" class="drag-block">
      <slot name="right-top" />
    </div>
    <div v-if="threeShow" class="drag-block drag-three" :style="threeStyle">
      <slot name="left-bottom" />
      <div
        class="drag-line-common drag-line-right"
        v-if="direction === 'row' && downMode === DragCrossMode.enabled"
        :class="{ resize: dragHReady || resizeHAble }"
        @mouseenter="onReadyDray('dragHReady')"
        @mouseleave="onCancelReadyDray('dragHReady')"
        @mousedown="onMouseHDown"
      />
      <div
        class="drag-line-common drag-line-bottom"
        v-else-if="direction === 'column' && downMode === DragCrossMode.enabled"
        :class="{ resize: dragVReady || resizeVAble }"
        @mouseenter="onReadyDray('dragVReady')"
        @mouseleave="onCancelReadyDray('dragVReady')"
        @mousedown="onMouseVDown"
      />
    </div>
    <div
      v-else-if="downMode !== DragCrossMode.disabled"
      :style="{ width: width + '%', height: 0 }"
      class="drag-block"
    >
      <slot name="left-bottom" />
    </div>
    <div v-if="fourShow" class="drag-block drag-four" :style="fourStyle">
      <slot name="right-bottom" />
    </div>
    <div
      v-else-if="downMode !== DragCrossMode.disabled"
      :style="{
        width: downMode === DragCrossMode.rightDisabled ? 0 : 100 - width + '%',
        height: 0,
      }"
      class="drag-block"
    >
      <slot name="right-bottom" />
    </div>
    <slot />
  </div>
</template>

<script>
import { DragCrossMode } from "../../../../utils/constaints";

export default {
  name: "DragCross",
  props: {
    direction: { type: String, default: "row" },
    hor: { type: [Number, String], default: 0 },
    ver: { type: [Number, String], default: 0 },
    horEnabled: { type: Boolean, default: true },
    verEnabled: { type: Boolean, default: true },
    initWidth: { type: [Number, String], default: "60%" },
    initHeight: { type: [Number, String], default: "50%" },
    upMode: { type: [Number, String], default: DragCrossMode.leftDisabled },
    downMode: { type: [Number, String], default: DragCrossMode.disabled },
    dragEnabled: { type: Boolean, default: true },
  },
  data() {
    return {
      DragCrossMode,
      dragHReady: false,
      dragVReady: false,
      resizeHAble: false,
      resizeVAble: false,
      width: 0,
      height: 0,
    };
  },
  computed: {
    oneShow: {
      get() {
        return (
          this.upMode !== DragCrossMode.disabled &&
          this.upMode !== DragCrossMode.leftDisabled
        );
      },
    },
    twoShow: {
      get() {
        return (
          this.upMode !== DragCrossMode.disabled &&
          this.upMode !== DragCrossMode.rightDisabled
        );
      },
    },
    threeShow: {
      get() {
        return (
          this.downMode !== DragCrossMode.disabled &&
          this.downMode !== DragCrossMode.leftDisabled
        );
      },
    },
    fourShow: {
      get() {
        return (
          this.downMode !== DragCrossMode.disabled &&
          this.downMode !== DragCrossMode.rightDisabled
        );
      },
    },
    oneStyle: {
      get() {
        if (this.direction === "row") {
          return {
            width:
              this.upMode === DragCrossMode.enabled ? this.width + "%" : "100%",
            height:
              this.downMode !== DragCrossMode.disabled
                ? this.height + "%"
                : "100%",
          };
        } else {
          return {
            width:
              this.downMode !== DragCrossMode.disabled
                ? this.width + "%"
                : "100%",
            height:
              this.upMode === DragCrossMode.enabled
                ? this.height + "%"
                : "100%",
          };
        }
      },
    },
    twoStyle: {
      get() {
        if (this.direction === "row") {
          return {
            width:
              this.upMode === DragCrossMode.enabled
                ? 100 - this.width + "%"
                : "100%",
            height:
              this.downMode !== DragCrossMode.disabled
                ? this.height + "%"
                : "100%",
          };
        } else {
          return {
            width:
              this.downMode !== DragCrossMode.disabled
                ? this.width + "%"
                : "100%",
            height:
              this.upMode === DragCrossMode.enabled
                ? 100 - this.height + "%"
                : "100%",
          };
        }
      },
    },
    threeStyle: {
      get() {
        if (this.direction === "row") {
          return {
            width:
              this.downMode === DragCrossMode.enabled
                ? this.width + "%"
                : "100%",
            height:
              this.upMode !== DragCrossMode.disabled
                ? 100 - this.height + "%"
                : "100%",
          };
        } else {
          return {
            width:
              this.upMode !== DragCrossMode.disabled
                ? 100 - this.width + "%"
                : "100%",
            height:
              this.downMode === DragCrossMode.enabled
                ? this.height + "%"
                : "100%",
          };
        }
      },
    },
    fourStyle: {
      get() {
        if (this.direction === "row") {
          return {
            width:
              this.downMode === DragCrossMode.enabled
                ? 100 - this.width + "%"
                : "100%",
            height:
              this.upMode !== DragCrossMode.disabled
                ? 100 - this.height + "%"
                : "100%",
          };
        } else {
          return {
            width:
              this.upMode !== DragCrossMode.disabled
                ? 100 - this.width + "%"
                : "100%",
            height:
              this.downMode === DragCrossMode.enabled
                ? 100 - this.height + "%"
                : "100%",
          };
        }
      },
    },
  },
  watch: {
    showMode(val, old) {
      if (val !== old) {
        this.resetSize();
      }
    },
    direction(val, old) {
      if (val !== old) {
        this.heightRatio = 0;
        this.widthRatio = 0;
        this.resetSize(true);
      }
    },
  },
  mounted() {
    this.resetSize();
  },
  methods: {
    onReadyDray(key) {
      this[key] = true;
    },
    onCancelReadyDray(key) {
      this[key] = false;
    },
    changeMode(mode) {
      this.$emit("update:showMode", mode);
    },
    getH(v) {
      if (!v) {
        return this.$refs.content.parentNode.offsetHeight;
      }
      if (typeof v === "string" && v.endsWith("%")) {
        return parseInt(
          (this.$refs.content.parentNode.offsetHeight * parseInt(v)) / 100
        );
      }
      return parseInt(v);
    },
    getW(w) {
      if (!w) {
        return this.$refs.content.parentNode.offsetWidth;
      }
      if (typeof w === "string" && w.endsWith("%")) {
        return parseInt(
          (
            (this.$refs.content.parentNode.offsetWidth * parseInt(w)) /
            100
          ).toString()
        );
      }
      return parseInt(w);
    },
    toRatio(v, total) {
      return ((v / total) * 100).toFixed(2);
    },
    resetSize(isNotify = false) {
      if (this.direction === "column") {
        let initH = this.heightRatio
            ? this.heightRatio
            : this.toRatio(
                this.getH(this.initHeight),
                this.$refs.content.offsetHeight
              ),
          initW = this.widthRatio
            ? this.widthRatio
            : this.toRatio(
                this.getW(this.initWidth),
                this.$refs.content.offsetWidth
              );
        this.width = initH;
        this.height = initW;
        this.widthRatio = this.width;
        this.heightRatio = this.height;
      } else {
        let initH = this.heightRatio
            ? this.heightRatio
            : this.toRatio(
                this.getH(this.initHeight),
                this.$refs.content.offsetHeight
              ),
          initW = this.widthRatio
            ? this.widthRatio
            : this.toRatio(
                this.getW(this.initWidth),
                this.$refs.content.offsetWidth
              );
        this.width = initW;
        this.height = initH;
        this.widthRatio = this.width;
        this.heightRatio = this.height;
      }
      if (isNotify) {
        this.$emit("change-direction-finished");
      }
    },
    onMouseHDown(e) {
      if (!this.dragEnabled) return;
      e.preventDefault();
      this.resizeHAble = true;
      this.clientX = e.clientX;
      this.tempWidth = e.target.parentNode.offsetWidth;
      window.document.body.style.cursor = "col-resize";
      window.addEventListener("mousemove", this.onMouseHMove);
      window.addEventListener("mouseup", this.onMouseHUp, { once: true });
    },
    onMouseVDown(e) {
      if (!this.dragEnabled) return;
      e.preventDefault();
      this.resizeVAble = true;
      this.clientY = e.clientY;
      this.tempHeight = e.target.parentNode.offsetHeight;
      window.document.body.style.cursor = "row-resize";
      window.addEventListener("mousemove", this.onMouseVMove);
      window.addEventListener("mouseup", this.onMouseVUp, { once: true });
    },
    onMouseHMove(e) {
      if (!this.resizeHAble && e.clientX !== this.clientX) return;
      let width = this.tempWidth + e.clientX - this.clientX;
      width = width <= 0 ? 0 : width;
      width =
        width > this.$refs.content.offsetWidth
          ? this.$refs.content.offsetWidth
          : width;
      this.width = this.toRatio(width, this.$refs.content.offsetWidth);
    },
    onMouseVMove(e) {
      if (!this.resizeVAble) return;
      let height = this.tempHeight + e.clientY - this.clientY;
      height = height <= 0 ? 0 : height;
      height =
        height > this.$refs.content.offsetHeight
          ? this.$refs.content.offsetHeight
          : height;
      this.height = this.toRatio(height, this.$refs.content.offsetHeight);
    },
    onMouseHUp() {
      this.resizeHAble = false;
      window.document.body.style.cursor = "initial";
      window.removeEventListener("mousemove", this.onMouseHMove);
    },
    onMouseVUp() {
      this.resizeVAble = false;
      window.document.body.style.cursor = "initial";
      window.removeEventListener("mousemove", this.onMouseVMove);
    },
  },
};
</script>

<style scoped>
</style>
