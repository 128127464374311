<template>
  <i class="tree-dot icon-new-03 d-center tree-arrow"></i>
</template>
<style>
.el-tree-node__expand-icon.is-leaf .tree-arrow {
  display: none;
}
s.tree-dot {
  width: 10px;
}
</style>
