<template>
  <el-input
    ref="input"
    v-model="value"
    @blur="onBlur"
    @keydown="onKeydown"
    @focus="onFocus"
  />
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
  },
  computed: {
    value: {
      get() {
        let title = this.title;
        if (title === "新建模型") {
          title = "";
        }
        return title;
      },
      set(val) {
        this.$emit("update:title", val);
      },
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onKeydown(e) {
      clearTimeout(this.timeout);
      if (e.key === "Enter") {
        setTimeout(() => {
          this.$parent.editor.events.focus();
        }, 100);
      } else {
        this.timeout = setTimeout(() => {
          this.$emit("save");
        }, 500);
      }
    },
    onFocus() {
      this.$emit("focus");
      this.tempTitle = this.title;
    },
    onBlur() {
      if (this.tempTitle !== this.title) {
        this.$emit("save");
      }
    },
  },
};
</script>
