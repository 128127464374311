<template>
  <div
    :class="['box', $root.config.theme, $root.config.themeBody]"
    :style="[{ '--left-max-width': leftMaxWidth + 'px' }]"
    v-if="$root.loginState === 1 && !showHistory"
  >
    <div
      :class="[
        'container',
        { 'container-frame': $root.config.frame === 'true' },
        { 'mobile-container': isMobile },
      ]"
    >
      <drag-right
        ref="altLeftRef"
        :class="['atl-left dark-7', $root.config.theme, $root.config.themeBody]"
        :min-width="200"
        :max-width="900"
        :init-width="300"
        :show-mode="treeBoxShow ? 0 : 2"
      >
        <!--      <el-button class="tree-fold" size="small" @click="treeFlex">-->
        <!--        <svg-icon icon-class="new-right"/>-->
        <!--      </el-button>-->
        <div :class="['atl-inner dark-7', $root.config.theme, $root.config.themeBody]">
          <div class="document-container d-column">
            <div class="d-flex tree-top" v-if="!isElectron" @click="goIndex">
              <svg-icon
                v-if="$root.config.theme === 'light3'"
                icon-class="new-logo-black"
                class-name="icon-logo"
              />
              <svg-icon v-else icon-class="new-logo" class-name="icon-logo" />
            </div>
            <tips class="d-flex tree-top" v-else>
              <!-- 隐藏功能 -->
              <svg-icon icon-class="new-logo" class-name="icon-logo" />
              <div class="tree-type d-center">
                {{ treeType === 0 ? "云端" : "本地" }}
              </div>
              <i class="el-icon icon-down">
                <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M384 192v640l384-320.064z"></path>
                </svg>
              </i>
              <template v-slot:float>
                <div
                  class="select-item"
                  v-if="treeType === 1"
                  @click="
                    () => {
                      this.treeType = 0;
                    }
                  "
                >
                  云端
                </div>
                <div
                  class="select-item"
                  v-if="treeType === 0"
                  @click="
                    () => {
                      this.treeType = 1;
                    }
                  "
                >
                  本地
                </div>
                <div class="tree-di">知识空间</div>
              </template>
            </tips>
            <div class="search-box" v-if="showLeft">
              <search-index @search-change="onSearchChange"></search-index>
            </div>
            <cloud-tree
              ref="cloudTreeRef"
              :class="{ active: treeType === 0 }"
              v-model:mid-type="midType"
              v-model:show-mindMap="showMindMap"
              v-model:select-item="$root.activeItem"
              :change-title-api="changeTitleApi"
              :check-samp-map="checkSampMap"
              :delete-api="
                async (id) => {
                  await deleteApi(id, true);
                }
              "
              @change-title="onChangeTitle"
              @mind-map="menuOpenMindMap"
              @share="menuShare"
              @move="onMoveEvt($event, false)"
              @clone="onCloneEvt($event, false)"
              @same-map="onSameMap($event, false)"
              @close-mindMap="closeMindMap"
              @add="onAdd"
            />
            <!-- @paragraph-history="menuParagraphHistory" -->
            <local-tree
              :class="{ active: treeType === 1 }"
              v-model:mid-type="midType"
              v-model:select-item="$root.activeItem"
              :change-title-api="changeTitleApi"
              :delete-api="
                async (id) => {
                  await deleteApi(id, true);
                }
              "
              @change-title="onChangeTitle"
              @mind-map="menuOpenMindMap"
              @share="menuShare"
              @move="onMoveEvt($event, true)"
              @clone="onCloneEvt($event, true)"
            />
            <!-- @paragraph-history="menuParagraphHistory" -->
          </div>
          <div
            v-if="showLeft && $root.config.frame === 'true' && user && user.nickName"
            class="tree-bottom-box"
          >
            <div class="tree-bottom-avatar">
              <el-avatar
                shape="square"
                :size="32"
                v-if="$store.state.user && $store.state.user.headImage"
                :src="$store.state.user.headImage"
              />
            </div>
            <div class="tree-bottom-info">
              <div class="tree-bottom-i">
                <div class="tbi-name">{{ $store.state.user.nickName }}</div>
                <!-- <div class="tbi-id">TreeID：{{ user.accountId }}</div> -->
              </div>
              <div class="tree-bottom-btn">
                <el-button
                  class="button-001"
                  link
                  size="small"
                  @click="openRecycleBin(null)"
                >
                  <svg-icon icon-class="new-70" class="d-center size-1"></svg-icon>
                </el-button>
                <el-button class="button-002" link size="small" @click="openConfig()">
                  <svg-icon icon-class="new-17" class="d-center size-1"></svg-icon>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </drag-right>
      <div
        class="article-content pos-relative"
        :class="[
          { 'hide-editor': hideBottomEditor },
          'article-zindex-' + articleContentIndex,
        ]"
      >
        <div
          class="alt-left-close d-center"
          :class="{
            'alt-closed': altLeft > 0,
            'alt-close-hover': directionStep === 0 || directionStep === 1,
          }"
          @click="onAltLeftClose"
        >
          <div class="alt-close-inner d-cloumn">
            <el-tooltip
              effect="dark"
              :content="
                (altLeft > 0 ? '打开' : '关闭') + '边栏 ' + (isWin ? 'Ctrl + L' : '⌘ + L')
              "
              placement="right"
            >
              <div>
                <div class="rounded-full"></div>
                <div class="rounded-full"></div>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div
          v-if="isShowMindMap()"
          class="alt-left-close d-center alt-right-close"
          :class="{
            'alt-closed alt-closed-right': altRight > 0,
            'alt-close-hover': directionStepRight === 0 || directionStepRight === 1,
          }"
          @click="changeMDEditor"
        >
          <div class="alt-close-inner d-cloumn">
            <el-tooltip
              effect="dark"
              :content="(altRight > 0 ? '打开' : '关闭') + '文档'"
              placement="left"
            >
              <div>
                <div class="rounded-full"></div>
                <div class="rounded-full"></div>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="article-block">
          <nav-header
            :midType="midType"
            :state-text="saveStatusText"
            :show-share="
              this.$root.activeItem &&
              !this.$root.activeItem.id.startsWith('local-') &&
              (midType === 'vditor' || midType === 'paragraphHistory')
            "
            :show-chat-gpt="
              !chatgpt.visible &&
              $root.config.shortcutMenu &&
              $root.config.shortcutMenu !== 'false'
            "
            :show-map="showMindMap"
            :show-back="mindMapHistory.length > 1"
            :show-more="
              !!$root.activeItem && (midType === 'mindmap' || midType === 'vditor')
            "
            :navbarButtons="$root.config.navbarButtons"
            v-model:show-left="showLeft"
            v-model:cross-direction="crossDirection"
            :show-document="showMDEditor"
            @share="menuShare"
            @full-screen="fullScreen"
            @history="openHistory"
            @map="openCurMindMap"
            @back="onBack"
            @chat-gpt="changeChatNav"
            @save-editor="onSaveEditor"
            @mind-show-edges="onMindShowEdges"
          />
          <div
            class="article-inner"
            v-if="midType === 'mindmap' || midType === 'vditor'"
            style="display: flex; flex-direction: row; align-items: stretch"
          >
            <mind-map-editor
              ref="mainMap"
              v-model="$root.activeItem"
              :is-main-view="true"
              :show-editor-to-right="showEditorToRight"
              v-model:show-mind-map="showMindMap"
              :is-mind-local-storage="isMindLocalStorage"
              v-model:show-m-d-editor="showMDEditor"
              v-model:mind-root-item="mindRootItem"
              :mind-select="mindSelect"
              :change-title-api="changeTitleApi"
              :delete-api="deleteApi"
              :save-status="saveStatus"
              :editor-refresh="editorRefresh"
              :open-config="openConfig"
              :query-chatgpt="queryChatgpt"
              :open-mind-map="menuOpenMindMap"
              v-model:cross-direction="crossDirection"
              class="mind-map-editor"
            />
          </div>
          <recycle-bin
            v-else-if="midType === 'recyclebin'"
            ref="recycle"
            :tree-type="treeType"
            :key="recyclebinKey"
            v-model:show-left="treeBoxShow"
            :openRecycleBin="openRecycleBin"
          >
          </recycle-bin>
          <div
            class="column"
            v-else-if="midType === 'paragraphHistory' && $root.activeItem"
          >
            <paragraph-history
              :articleId="$root.activeItem.id"
              :title="$root.activeItem.title"
            />
          </div>
        </div>
      </div>

      <drag-view
        class="document-drag-right"
        :init-width="editorWidth"
        :min-width="0"
        direction="left"
        :no-fixed="true"
        v-if="showMDEditor && $root.activeItem && showMindMap && showEditorToRight"
        v-model:view-width="editorWidth"
        :class="hideBottomEditor ? 'hide-editor' : ''"
      >
        <!--      v-if="-->
        <!--      $root.activeItem && showMindMap && showEditorToRight && showMDEditor-->
        <!--      "-->
        <mxs-editor
          ref="mxsEditor"
          v-if="isTest"
          :article-id="$root.activeItem.id"
          v-model:title="$root.activeItem.title"
          :exts="$root.activeItem.exts"
          @changeTitle="changeTitleApi"
          @update:title="onMDUpdateTitle"
          v-model:showCloseButton="showMDEditor"
        />
        <froala-editor
          v-else
          class="document-right"
          ref="froalaEditorTop"
          :midType="showMindMap ? '' : 'vditor'"
          :articleId="$root.activeItem.id"
          v-model:title="$root.activeItem.title"
          @onSaveTitle="changeTitleApi"
          :saveStatus="saveStatus"
          :editorRefresh="editorRefresh"
          :openConfig="openConfig"
          :mindVditor="false"
          :showClose="true"
          :topArticleId="topArticleId"
          :editorTipList="$root.fontList"
          :queryChatgpt="queryChatgpt"
          @close="
            () => {
              this.showMDEditor = false;
            }
          "
          @update:title="onMDUpdateTitle($event)"
        />
        <link-tree v-if="false" />
      </drag-view>
      <div
        v-if="backgroundEnabled"
        v-show="chatgpt.visible"
        class="drag-win-shadow"
      ></div>
      <drag-view
        ref="chaggpt"
        :show="chatgpt.visible"
        class="el-drawer rtl open chat-drawer chat-box"
        :init-width="chatgpt.width"
        :init-right="chatgpt.right"
        :init-top="chatgpt.top"
        :init-bottom="chatgpt.bottom"
        :no-fixed="noFixed"
        v-model:view-width="viewWidth"
      >
        <template #header>
          <chat-nav-header
            v-model:no-fixed="noFixed"
            v-model:chat-nav="chatNav"
            @close="closeChatgpt"
            @change-size="changeChatGptSize"
            @change-nav="changeNav"
          />
        </template>
        <flash-thought v-if="chatNav === 'flashThought'" ref="flashThoughtRef" />
        <chat-gpt
          v-else-if="chatNav === 'ai'"
          ref="chatgptRef"
          :insertHtml="insertHtml"
          :theme="$root.config.theme"
        />
        <hotkey-drawer v-else-if="chatNav === 'hotkeyDrawer'" ref="hotkeyDrawerRef" />
        <transfer
          v-else
          ref="transferRef"
          v-model:view-width="viewWidth"
          v-model:ff-type="ffType"
          @close="closeChatgpt"
        />
      </drag-view>
      <div
        class="help-box"
        v-if="midType !== 'recyclebin' && showShortcutMenu && showHelpCenter"
      >
        <!-- style="display: none" -->
        <el-dropdown
          :popper-class="[
            $root.config.theme,
            $root.config.themeBod,
            { 'right-menu-frame': $root.config.frame === 'true' },
          ]"
          trigger="contextmenu"
        >
          <el-button type="link" circle class="help-btn" @click="changeChatNav(chatNav)">
            <svg-icon
              v-if="chatNav === 'flashThought'"
              icon-class="010"
              class-name="svg-small"
            />
            <svg-icon
              v-else-if="chatNav === 'ai'"
              icon-class="007"
              class-name="svg-small"
            />
            <svg-icon
              v-else
              :icon-class="$root.config.theme.includes('dark') ? '019_b' : '019'"
              class-name="svg-small"
            />
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click="changeChatNav('flashThought')"
                v-if="
                  $root.config.shortcutMenu &&
                  $root.config.shortcutMenu.flashThought === 'true'
                "
              >
                <svg-icon icon-class="010" class-name="svg-small help-btn-l" />
                闪念树
              </el-dropdown-item>
              <el-dropdown-item
                @click="changeChatNav('ai')"
                v-if="
                  $root.config.shortcutMenu &&
                  $root.config.shortcutMenu.chatgpt === 'true'
                "
              >
                <svg-icon icon-class="007" class-name="svg-small help-btn-l" />
                AI助手
              </el-dropdown-item>
              <el-dropdown-item @click="changeChatNav('transfer')">
                <svg-icon
                  :icon-class="$root.config.theme.includes('dark') ? '019_b' : '019'"
                  class-name="svg-small help-btn-l"
                />
                中转站
              </el-dropdown-item>
              <el-dropdown-item @click="changeChatNav('hotkeyDrawer')">
                <svg-icon icon-class="new-68" class="svg-small help-btn-l"></svg-icon>
                快捷键
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
  <document-share
    v-model:article-share="articleShare"
    v-model:paragraph-share="paragraphShare"
    :refresh-url="refreshUrl"
  />

  <div class="drag-win-shadow" v-if="showMapDialog">
    <map-dialog :model-value="dialogData" @close="onCloseDialog" />
  </div>
  <!--  <article-history-->
  <!--    v-if="showHistory && isTest"-->
  <!--    :article-id="$root.activeItem.id"-->
  <!--    @close="onCloseHistory"-->
  <!--    @recover="onHistoryRecover"-->
  <!--  />-->

  <mxs-history
    v-if="showHistory && isTest"
    :article-id="$root.activeItem.id"
    @close="onCloseHistory"
  ></mxs-history>
</template>

<script>
import recycleBin from "@/views/pc/article/recycle-bin";
import froalaEditor from "@/views/pc/article/froala-editor";
import setConfig from "@/views/pc/article/set-config";
import paragraphHistory from "@/views/pc/article/history/paragraph";
import chatGpt from "@/views/pc/article/drag-view/chatGpt";
import flashThought from "@/views/pc/article/drag-view/flashThought";
import transfer from "@/views/pc/article/drag-view/transfer";
import hotkeyDrawer from "@/views/pc/article/drag-view/hotkeyDrawer";

import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import screenfull from "screenfull";
import TreeList from "./tree/TreeList";
import TreeItem from "./tree/TreeItem";
import DocumentItem from "./tree/DocumentItem";
import TreeNav from "./tree/TreeNav";
import TreeMenus from "./tree/TreeMenus";
import LocalTree from "./document/LocalTree";
import CloudTree from "./document/CloudTree";
import DocumentShare from "./document/DocumentShare";
import DragRight from "./document/DragRight";
import Tips from "./widget/Tips";
import MindMapEditor from "./MindMapEditor";
import DragCross from "./widget/DragCross";
import DragView from "./widget/DragView";
import NavHeader from "./widget/NavHeader";
import ChatNavHeader from "./widget/ChatNavHeader";
import SvgIcon from "../../../svgIcon/index.vue";
import SearchIndex from "./search";
import { defaultTheme } from "../../../utils/constaints";
import $ from "jquery";
import MxsEditor from "../../../components/mxs-editor";
import MapDialog from "./mind-map/MapDialog";
import ArticleHistory from "./history/ArticleHistory";

import { isWin } from "../../../components/antv-mindmap/mxs-mindmap.es";

import MxsHistory from "./history/MxsHistory";
import LinkTree from "./tree/LinkTree";
import { Platform } from "../../../utils/utils";

const isElectron = process.env.VUE_APP_PLATFORM === "electron";
// console.log(`>>>>process.env.VUE_APP_PLATFORM`, process.env.VUE_APP_PLATFORM);
const directionStep = localStorage.getItem("--direction-step");
const directionStepRight = localStorage.getItem("--direction-step-right");

export default {
  components: {
    LinkTree,
    MxsHistory,
    ArticleHistory,
    MapDialog,
    MxsEditor,
    SearchIndex,
    SvgIcon,
    ChatNavHeader,
    NavHeader,
    DragView,
    DragCross,
    MindMapEditor,
    Tips,
    DragRight,
    DocumentShare,
    CloudTree,
    LocalTree,
    TreeMenus,
    TreeNav,
    DocumentItem,
    TreeItem,
    TreeList,
    recycleBin,
    froalaEditor,
    setConfig,
    paragraphHistory,
    chatGpt,
    flashThought,
    transfer,
    hotkeyDrawer,
  },
  provide() {
    return {
      passTochatNav: this.passTochatNav,
      mindPasteItem: this.mindPasteItem,
      transferNewTreeItem: this.transferNewTreeItem,
      onBack: this.onBack,
      openCurMindMap: this.openCurMindMap,
      menuIconType: this.menuIconType,
      changeMarkIcon: this.changeMarkIcon,
      openEditor: this.openEditor,
      openMapDialog: this.openMapDialog,
      isShowMDEditor: this.isShowMDEditor,
      isShowMindMap: this.isShowMindMap,
      onDragTreeNode: this.onDragTreeNode,
      saveStatus: this.saveStatus,
      saveTip: this.saveTip,
      cloneArticle: this.cloneArticle,
      moveArticle: this.moveArticle,
      closeMindMap: this.closeMindMap,
      closeEditor: this.closeEditor,
      insertHtml: this.insertHtml,
      getAiAnswer: this.getAiAnswer,
      mindImgToEditor: this.mindImgToEditor,
      isDoubleMap: this.isDoubleMap,
      menuOpenMindMap: this.menuOpenMindMap,
      cloneTreeItem: this.cloneTreeItem,
      changeZIndex: this.changeZIndex,
    };
  },
  data() {
    return {
      articleContentIndex: 1,
      directionStep: directionStep ? parseInt(directionStep) : 2,
      directionStepRight: directionStepRight ? parseInt(directionStepRight) : 2,
      showHistory: false,
      hideBottomEditor: false,
      dialogData: [],
      showMapDialog: false,
      // isTest: localStorage.getItem("__test") === "1",
      isTest: true,
      showEditorToRight: true,
      backgroundEnabled: false,
      mindMapHistory: [],
      noFixed: true,
      crossDirection: "row",
      edges: [],
      isElectron,
      treeType: isElectron ? 1 : 0, //0 cloud 1 local
      chatGptFull: false,
      // TODO--从新定义
      navList: [],
      menuSelectItem: null,
      treeDialogItem: null,
      treeDialogType: 0,
      mindShowEditor: true,

      showMindMap: false,
      mindRootItem: undefined,
      isMindLocalStorage: false,
      showMDEditor: true, //localStorage.getItem('__show_editor') === '1',

      isCollapse: true,
      renameId: "",
      isLogin: false,
      tooLong: false,
      phone: "",
      // 是否打开思维导图
      midType: "vditor",
      // 思维导图是否可编辑
      mindEdit: true,
      mindHeight: "100%",
      // 思维导图key
      mindKey: 0,
      editorKey: 0,
      recyclebinKey: 0,
      // 树搜索
      filterText: "",
      // 菜单
      currColumn: 0,
      // columns: [
      //   { icon: 'el-icon-s-promotion', label: '快速访问' },
      //   { icon: 'el-icon-s-order', label: '最近编辑' },
      //   { icon: 'el-icon-delete-solid', label: '回收站' },
      // ],

      // 树结构第一层node
      firstNode: null,
      // 是否懒加载
      isLazy: true,
      first: true,
      // 路由带节点ID
      treeList: [],
      articleId: "",
      content: "",
      count: 0,
      saveStatusText: "已保存到云端",
      changeContent: false,
      refreshColor: false,
      moveDialog: {
        visible: false,
        type: null,
        title: "",
        value: "",
        moveId: "",
        selectedId: "",
      },
      configVisible: false,
      configActive: "config",
      loading: true,
      treeLoading: false,
      mindCollapse: 3,

      //search
      searching: false,
      searchKeyword: "",
      searchList: [],

      timeSearchArticle: null,
      timeMindmapMouse: null,
      leftMaxWidth: 1000,
      articleShare: {
        Visible: false,
        shareTypeVisible: false,
        shareType: 0,
        childCascadeVisible: false,
        childCascade: true,
        allowMultiVisible: false,
        allowMulti: true,
        url: "",
      },
      paragraphShare: {
        visible: false,
        url: "",
      },
      paragraphArticleId: null,
      paragraphTitle: null,
      chatgpt: {
        visible: false,
        width: "280",
        right: "20",
        top: "20",
        bottom: "20",
      },
      showHelpCenter: true,
      treeBoxShow: true,
      showLeft: true,
      isMobileAccount: "false",
      isWechatAccount: "false",
      chatNav: "transfer",
      viewWidth: 280,
      ffType: "right",
      editorWidth: "40%",
      altLeft: 0,
      altRight: 0,
      isWin: false,
      user: null,
      isMobile: Platform.isMobile2,
    };
  },
  computed: {
    topArticleId: {
      get() {
        let topId = "";
        let item = this.$root.activeItem;
        while (item) {
          topId = item.id;
          item = item.parent;
        }
        return topId;
      },
    },
    showShortcutMenu: {
      get() {
        if (this.$root.config.shortcutMenu) {
          if (this.$root.config.shortcutMenu.location === "bottom") {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
    },
  },
  inject: ["reload"],
  created() {
    // 判断路由是否包含节点ID
    if (localStorage.getItem("isMobileAccount")) {
      this.isMobileAccount = localStorage.getItem("isMobileAccount");
    }
    if (localStorage.getItem("isWechatAccount")) {
      this.isWechatAccount = localStorage.getItem("isWechatAccount");
    }
    if (
      localStorage.getItem("editor_width") &&
      localStorage.getItem("editor_width") !== ""
    ) {
      this.editorWidth = localStorage.getItem("editor_width");
    }
    this.changeConfig();
  },
  mounted() {
    this.$proxy.on("event-article", (item) => {
      switch (item.key) {
        case "open-left":
          this.treeBoxShow = !this.treeBoxShow;
          this.showLeft = !this.showLeft;
          break;
        case "open-mind":
          this.openCurMindMap(true);
          break;
        case "open-document":
          this.showMDEditor = !this.showMDEditor;
          break;
        case "open-ai":
          if (this.chatgpt.visible) {
            this.closeChatgpt();
          } else {
            this.changeChatNav("ai");
          }
          break;
        case "doc-mind":
          if (this.showMDEditor) {
            if (!this.showMindMap) {
              this.openCurMindMap();
            }
            this.showMDEditor = false;
          } else {
            if (this.showMindMap) {
              this.closeMindMap();
            }
            this.showMDEditor = true;
          }
          break;
        case "new-item":
          this.transferNewTreeItem(this.$root.activeItem, "新建模型");
          break;
        case "del-item":
          this.$root.mindDeleteItem(this.$root.activeItem.id);
          break;
        case "move-item":
          this.$refs.cloudTreeRef.treeDialogItem = this.$root.activeItem;
          this.$refs.cloudTreeRef.treeDialogType = 1;
          break;
        case "copy-item":
          this.$refs.cloudTreeRef.treeDialogItem = this.$root.activeItem;
          this.$refs.cloudTreeRef.treeDialogType = 2;
          break;
      }
    });
    this.isLogin = localStorage.getItem("isLogin");
    if (localStorage.getItem("chatNav")) {
      this.chatNav = localStorage.getItem("chatNav");
    } else {
      localStorage.setItem("chatNav", "transfer");
    }
    window.addEventListener("message", this.handleMessage);

    if (this.isMobileAccount === "false" && this.isWechatAccount === "true") {
      setTimeout(() => {
        this.$router.push("/login");
        this.$root.tips("warning", "请绑定手机");
      }, 2000);
    }

    this.$proxy.on("notify_tree_list_all", this.updateAll);
    if (this.$route.params.transfer) {
      this.$nextTick(() => {
        this.chatNav = "transfer";
        this.chatgpt.visible = true;
        this.showHelpCenter = false;
        this.newTab();
      });
    }
    this.isWin = isWin;
    this.$nextTick(async () => {
      let res = await this.$api.account.getUserInfo();
      this.$store.commit("setUser", res.data.data);
      this.user = JSON.parse(localStorage.getItem("__user"));
      if (this.user) {
        if (!this.user.headImage || this.user.headImage === "") {
          this.user.headImage =
            "https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/d6ccddf0bd034dcf8396e742d094a779.jpg";
        }
      }
      // var data = {
      //   appId: "Vq7Do2MQ", //Bcur0FgD,Vq7Do2MQ
      //   version: "1.0.49",
      //   name: "1.0.49",
      //   desc: "1.0.49",
      //   fileUrl:
      //     "https://moxingshu.oss-cn-shanghai.aliyuncs.com/app/next-1.0.49.zip",
      // };
      // this.$api.support.uploadAppFile(data).then((res) => {
      //   console.log(res);
      // });
      // this.$api.support.latest("Bcur0FgD").then((res) => {
      //   console.log(res);
      // });
    });
  },
  unmounted() {
    this.$proxy.off("event-article");
  },
  watch: {
    directionStep(val) {
      localStorage.setItem("--direction-step", val);
    },
    directionStepRight(val) {
      localStorage.setItem("--direction-step-right", val);
    },
    showMDEditor(val, old) {
      if (val !== old && this.showMindMap) {
        localStorage.setItem("__show_editor", val ? "1" : "0");
      }
    },
    mindRootItem(val, old) {
      if (val !== old) {
        this.recordHistory();
      }
      if (val) {
        localStorage.setItem("__show_mind_map", val.id);
      } else {
        localStorage.removeItem("__show_mind_map");
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "$root.activeItem": {
      handler(val, old) {
        if (val && (!old || old.id !== val.id)) {
          this.isMindLocalStorage = val && val.id?.startsWith("local-");
          this.treeType = val && val.id.startsWith("local-") ? 1 : 0;
        }
        if (val !== old) {
          this.recordHistory();
        }
        if (!this.isInit) {
          this.isInit = true;
          const defaultId = localStorage.getItem("__show_mind_map");
          const node = this.$root.findItemById(defaultId);
          if (node) {
            this.midType = "mindmap";
            this.mindRootItem = node;
            this.showMDEditor = localStorage.getItem("__show_editor") === "1";
            this.showMindMap = true;
          }
        }
      },
    },
    midType(val, old) {
      if (val !== old) {
        this.recordHistory();
      }
    },
    "$root.config": {
      handler(val, old) {
        if (val !== old) {
          this.changeConfig();
          if (val.theme !== old.theme && window.mindTree) {
            this.mindKey++;
          }
        }
      },
    },
    chatNav(val, old) {
      if (val === "transfer") {
        setTimeout(() => {
          this.$refs.transferRef.isWide = true;
        }, 10);
      }
    },
    editorWidth(val, old) {
      localStorage.setItem("editor_width", val);
    },
    // mindList: {
    //   handler(newVal, oldVal) {
    //     this.mindListchange += 1;
    //     this.mindListChange(newVal, oldVal);
    //   },
    //   deep: true,
    // },
  },
  beforeUnmount() {
    // document.getElementById("scriptWx").remove();
    window.removeEventListener("message", this.handleMessage);
    this.$proxy.on("notify_tree_list_all", this.updateAll);
  },
  methods: {
    changeZIndex(index) {
      this.articleContentIndex = index;
    },
    onCloseHistory() {
      this.hideBottomEditor = false;
      this.showHistory = false;
    },
    getMxsEditor() {
      return this.$refs.mxsEditor || this.$refs.mainMap.$refs.mxsEditor;
    },
    onHistoryRecover(content) {
      const mxsEditor = this.getMxsEditor();
      if (mxsEditor) {
        mxsEditor.recover(content);
      }
    },
    openMapDialog(data) {
      this.dialogData = data;
      this.showMapDialog = true;
    },
    onCloseDialog() {
      this.showMapDialog = false;
      this.dialogData = [];
    },
    onDragTreeNode(node) {
      this.$refs.mainMap?.$refs.mindmap?.graph.dragExternal({
        id: node.id,
      });
      this.$refs.mainMap?.$refs.subMindmap?.graph.dragExternal({ id: node.id });
    },
    async moveArticle(options) {
      if (!options.external) return;
      const dragNode = this.$root.findItemById(options.external.id);
      if (!dragNode) return;

      const list = dragNode.parent ? dragNode.parent.children : this.$root.cloudTreeList;
      if (
        (dragNode.parent && dragNode.parent.id === options.parentId) ||
        (!dragNode.parent && !options.parentId)
      ) {
        const idx = list.findIndex((item) => item.id === dragNode.id);
        if (idx === options.sort) return;
        if (idx < options.sort) {
          options.sort += 1;
        }
      }
      const parentList = options.parentId
        ? this.$root.findItemById(options.parentId).children
        : this.$root.cloudTreeList;
      let dragEndNode;
      if (parentList && parentList[options.sort]) {
        dragEndNode = parentList[options.sort];
        dragEndNode.ready = "ready-top";
      } else if (options.parentId && (!parentList || parentList.length === 0)) {
        dragEndNode = this.$root.findItemById(options.parentId);
        dragEndNode.ready = "ready-box";
      } else if (parentList) {
        dragEndNode = parentList[parentList.length - 1];
        dragEndNode.ready = "ready-bottom";
      }
      if (dragEndNode) {
        this.$refs.cloudTreeRef?.moveNode(dragNode, dragEndNode);
        dragEndNode.ready = undefined;
      }
    },
    saveTip() {
      clearTimeout(this.saveTimer);
      this.saveStatus("保存中");
      this.saveTimer = setTimeout(() => {
        this.saveStatus("已保存到云端");
      }, 500);
    },
    async cloneArticle(options) {
      if (!options.external) return;
      const item = this.$root.findItemById(options.external.id);
      const parent = this.$root.findItemById(options.parentId);
      if (!item || !parent) return;
      this.saveStatus("保存中");
      const s = await this.$root.cloneArticle(item, parent, options.sort);
      this.$refs.mainMap?.$refs.mindmap?.graph?.cloneTo(s, options.sort);
      this.saveStatus("已保存到云端");
    },
    onMDUpdateTitle(title) {
      this.$refs.mainMap?.$refs.mindmap?.graph.changeNodeTitle({
        id: this.$root.activeItem.id,
        title,
      });
      this.$refs.mainMap?.$refs.subMindmap?.graph.changeNodeTitle({
        id: this.$root.activeItem.id,
        title,
      });
    },
    toArticle(articleId) {
      if (this.$route.params.articleId === articleId) return;
      this.$router.push({
        path: `/article${articleId ? "/" + articleId : ""}`,
      });
      this.$nextTick(() => {
        this.checkTreeScroll(this.$refs.cloudTreeRef?.$refs.treeList, articleId);
      });
    },
    checkTreeScroll(scrollEl, id) {
      if (!scrollEl || !id) return;
      const el = $(scrollEl.$el);
      const target = el.find(`[data-node-id="${id}"]`);
      if (!target || target.length === 0) return;
      const offset = target.offset();
      const pOffset = el.offset();
      const pHeight = el.height();
      const top = offset.top - pOffset.top;
      if (top < 50 || top - 50 > pHeight) {
        const scrollTop = scrollEl.$el.scrollTop;
        scrollEl.$el.scrollTo({
          top: scrollTop + top - (top < 50 ? 50 : pHeight - 50),
          behavior: "smooth",
        });
      }
    },
    updateAll(data) {
      this.$root.mergeData(data.list, undefined, data.local);
    },
    recordHistory() {
      const last = this.mindMapHistory[this.mindMapHistory.length - 1];
      const newHistory = {
        type: this.showMindMap ? "map" : "",
        midType: this.midType,
        active: this.$root.activeItem?.id,
        root: this.mindRootItem?.id,
        local: this.isMindLocalStorage,
      };

      if (
        last &&
        last.type === newHistory.type &&
        last.active === newHistory.active &&
        last.root === newHistory.root &&
        last.midType === newHistory.midType
      ) {
        return;
      }
      this.mindMapHistory.push(newHistory);
    },
    onBack() {
      if (this.mindMapHistory.length > 0) {
        this.mindMapHistory.pop();
        const last = this.mindMapHistory[this.mindMapHistory.length - 1];
        if (!last) return;
        let activeItem = undefined;
        if (last.active) {
          activeItem = this.$root.getNodeById(last.active);
          if (!activeItem) {
            this.onBack();
            return;
          }
        }
        let mindRootItem = undefined;
        if (last.root) {
          mindRootItem = this.$root.getNodeById(last.root);
          if (!mindRootItem) {
            this.onBack();
            return;
          }
        }
        if (last) {
          this.midType = last.midType;
          this.$root.activeItem = activeItem;
          this.isMindLocalStorage = last.local;
          this.mindRootItem = mindRootItem;
          this.showMindMap = last.type === "map";
        }
      }
    },
    screeChange(e) {
      if (this.chatGptFull) {
        this.chatGptFull = false;
        document.addEventListener("fullscreenchange", this.screeChange, true);
      }
    },
    changeChatGptSize(type) {
      if (this.ffType === type) return;
      this.ffType = type;
      switch (type) {
        case "right":
          this.backgroundEnabled = false;
          this.noFixed = true;
          this.$refs.chaggpt.setWidth(280);
          break;
        case "fixed":
          // this.$refs.chaggpt.autoSize();
          // this.backgroundEnabled = false;
          // this.noFixed = false;
          // this.$refs.chaggpt.setRight(24);
          // this.$refs.chaggpt.setLeft(window.innerWidth - 280 - 24);
          // this.$refs.chaggpt.setTop(85);
          // this.$refs.chaggpt.setBottom(49);
          this.$refs.chaggpt.autoSize();
          this.backgroundEnabled = true;
          this.noFixed = false;
          this.$refs.chaggpt.setLeft(200);
          this.$refs.chaggpt.setRight(
            window.innerWidth - 200 * 2 >= 280 ? 200 : window.innerWidth - 200 - 280
          );
          this.$refs.chaggpt.setTop(150);
          this.$refs.chaggpt.setBottom(150);
          break;
        case "full":
          this.$refs.chaggpt.autoSize();
          this.backgroundEnabled = true;
          this.noFixed = false;
          this.$refs.chaggpt.setLeft(200);
          this.$refs.chaggpt.setRight(
            window.innerWidth - 200 * 2 >= 280 ? 200 : window.innerWidth - 200 - 280
          );
          this.$refs.chaggpt.setTop(150);
          this.$refs.chaggpt.setBottom(150);
          break;
        case "newTab":
          this.$refs.chaggpt.autoSize();
          this.backgroundEnabled = true;
          this.noFixed = false;
          this.$refs.chaggpt.setLeft(20);
          this.$refs.chaggpt.setRight(20);
          this.$refs.chaggpt.setTop(20);
          this.$refs.chaggpt.setBottom(20);
          break;
        default:
          break;
      }
    },
    onChangeChatFloat() {
      this.noFixed = !this.noFixed;
    },
    onChangeF() {
      this.crossDirection = this.crossDirection === "row" ? "column" : "row";
    },
    onMoveEvt(data, isLocal) {
      if (this.showMindMap && this.$refs.mainMap) {
        this.$refs.mainMap.onTreeMove(data, isLocal);
      }
    },
    async cloneTreeItem(item, parent, group) {
      const copyTimer = setTimeout(() => {
        this.$root.tips(
          "info",
          "节点内容较多，正在后台拼命复制，复制完成会有系统提醒，您可以继续使用模型树！"
        );
      }, 1000);
      this.$root
        .cloneArticle(item, parent)
        .then((s) => {
          clearTimeout(copyTimer);
          if (!s) return;
          this.$root.tips("success", "复制模型成功");
          this.onCloneEvt({ newItem: s, itemId: item.id, parentId: parent.id });
          if (group) {
            this.$root.addGroupItem(group.id, s.id);
          }
        })
        .catch((e) => {});
    },
    onCloneEvt(data, isLocal) {
      if (this.showMindMap && this.$refs.mainMap && data && data.newItem) {
        this.$refs.mainMap.onTreeClone(data, isLocal);
      }
    },
    getMindId(map, docId) {
      if (map.rawData.id === docId) {
        return map.id;
      }
      if (map.children) {
        for (let i = 0; i < map.children.length; ++i) {
          let mId = this.getMindId(map.children[i], docId);
          if (mId) return mId;
        }
      }
      return undefined;
    },
    onChangeTitle(item) {
      if (this.showMindMap) {
        if (this.$refs.mainMap) {
          this.$refs.mainMap.changeTitle(item.id, item.title);
        }
      }
    },
    checkSampMap(id) {
      if (this.$refs.mainMap) {
        return this.$refs.mainMap.checkNodeExist(id);
      }
      return false;
    },
    async changeTitleApi(item) {
      if (!item.title) {
        this.$root.tips("error", "请输入名称");
        return;
      }
      try {
        this.saveStatus("保存中");
        await this.$api.article.renameArticle({
          articleId: item.id,
          title: item.title || "",
        });
        this.saveStatus("已保存到云端");
      } catch (e) {
        this.$root.tips(
          "error",
          "标题保存失败，请确保网络是否正常，再次编辑或刷新网页重新编辑！"
        );
      }
    },
    async deleteApi(docId, isCheck = false) {
      if (isCheck) {
        const p = await this.$api.edges.getNodes(
          { id: docId },
          docId.startsWith("local-")
        );
        await this.$refs.mainMap?.onMindDeleteApi(
          {
            id: docId,
            deleteIds: p.list.map((r) => r.id),
            deleteEdges: p.edges,
          },
          3
        );
      }
      let data = await this.$root.removeArticle(docId);
      if (data && this.midType === "recyclebin") {
        this.$refs.recycle.appendItems(data);
      }
      return true;
    },

    // 打开文档段落记录
    menuParagraphHistory(node) {
      this.midType = "paragraphHistory";
      this.onSelectTreeItem(node.item, false);
    },
    async menuIconType(node) {
      await this.$root.updateMarkIcon(node.item.id);
      const item = this.$root.findItemById(node.item.id);
      if (item) {
        this.changeMarkIcon(item.id, item.iconDetail);
      }
    },
    isValidMindMap() {
      return (
        this.$refs.mainMap &&
        this.$refs.mainMap.$refs.mindmap &&
        this.$refs.mainMap.$refs.mindmap.$refs.map
      );
    },
    changeMarkIcon(id, iconDetail) {
      if (this.isValidMindMap()) {
        this.$refs.mainMap.$refs.mindmap.$refs.map.mindChangeIconType(id, iconDetail);
      }
    },
    isLocalStorage(id) {
      return id.startsWith("local-") || id.startsWith("cloud-");
    },
    onAdd(node) {
      setTimeout(() => {
        const mxsEditor = this.getMxsEditor();
        if (mxsEditor) {
          mxsEditor.focus();
        }
      }, 500);
      if (this.$refs.mainMap) {
        return this.$refs.mainMap.onAdd({
          id: node.parentId || "root",
          item: node,
        });
      }
    },
    onSameMap(node) {
      if (this.$refs.mainMap) {
        return this.$refs.mainMap.openSameMap(node.item);
      }
    },
    isDoubleMap(articleId) {
      if (this.$refs.mainMap) {
        return this.$refs.mainMap.isDoubleMap(articleId);
      }
    },
    async menuShare(node) {
      let item = node ? node.item : this.$root.activeItem;
      if (this.isLocalStorage(item.id)) return;
      let articleId = item.id;
      this.shareArticleId = item.id;
      if (this.midType === "paragraphHistory") {
        this.articleShare.Visible = false;
        this.paragraphShare.visible = true;
        this.paragraphShare.url =
          window.location.origin + "/share/paragraph/" + articleId;
      } else {
        this.paragraphShare.visible = false;
        this.articleShare.Visible = true;
        this.$api.share
          .share({
            articleId: articleId,
            shareType: this.articleShare.shareType,
            childCascade: this.articleShare.childCascade,
            allowMulti: this.articleShare.allowMulti,
          })
          .then((res) => {
            if (res.data.isSuccess === true && res.data.data) {
              this.articleShare.url =
                window.location.origin + "/share/article/" + res.data.data;
            } else {
              this.articleShare.url = "";
              this.$root.tips("error", res.data.message);
            }
          });
      }
    },
    async menuDelete(node) {
      await this.$refs.treeList.deleteItem(node.item);
    },
    menuOpen(e, node) {
      this.menuSelectItem = node;
      this.$nextTick(() => {
        let menu = this.$refs.menus.$el;
        // console.log(event, window.innerHeight, menu.offsetHeight);
        menu.style.left = e.clientX + 20 + "px";
        let top = e.clientY;
        if (e.clientY + menu.offsetHeight > window.innerHeight) {
          top = window.innerHeight - menu.offsetHeight;
        }
        menu.style.top = top - 5 + "px";
      });
    },
    async menuNewTreeItem(node, type = "left", iconType = 0) {
      await this.$refs.treeList.appendItem(node && node.item, iconType);
    },
    // 打开思维导图
    async openCurMindMap(isHoldEditor = false) {
      if (!this.showMindMap) {
        if (isHoldEditor) {
          this.showMDEditor = localStorage.getItem("__show_editor") === "1";
        }
        await this.menuOpenMindMap(
          this.$root.activeItem ? { item: this.$root.activeItem } : undefined,
          this.$root.activeItem?.id.startsWith("local-") || this.treeType === 1
        );
      } else {
        this.midType = "vditor";
        // this.showMindMap = false;
        // this.mindRootItem = undefined;
        this.showMDEditor = true;
      }
      this.altRight = this.showMDEditor ? 0 : 1;
    },
    closeEditor() {
      this.showMDEditor = false;
    },
    changeMDEditor() {
      if (this.directionStepRight < 2) {
        this.directionStepRight++;
      }
      if (!this.isShowMDEditor()) {
        this.openCurMindMap(true);
        this.altRight = 0;
      } else {
        this.showMDEditor = false;
        this.altRight = 1;
      }
    },
    closeMindMap() {
      this.midType = "vditor";
      this.showMindMap = false;
      this.mindRootItem = undefined;
    },
    async menuOpenMindMap(node, isLocal = false) {
      this.midType = "mindmap";
      this.isMindLocalStorage = isLocal;
      this.$root.activeItem = node ? node.item : undefined;
      if (this.$root.activeItem) {
        this.$root.activeItem.active = true;
      }
      this.mindRootItem = this.$root.activeItem;
      this.showMindMap = true;
      this.toArticle(node.item.id);
    },
    onSelectTreeItem(item, checkMindType = true) {
      if (!item) {
        this.$root.activeItem = undefined;
        this.toArticle();
        return;
      }
      if (this.$root.activeItem && this.$root.activeItem.id === item.id) {
        if (this.midType !== "vditor" && checkMindType) {
          // this.midType = 'vditor';
          return;
        }
        return;
      }

      if (this.$root.activeItem) this.$root.activeItem.active = false;
      this.$root.activeItem = item;
      this.$root.activeItem.active = true;
      if (checkMindType) {
        // this.midType = 'vditor';
      }
      if (this.$route.params.articleId !== item.id) {
        this.toArticle(item.id);
      }
    },

    refresh() {
      this.reload();
    },
    changeConfig() {
      if (this.$root.config.theme) {
        localStorage.setItem("theme", this.$root.config.theme);
      } else {
        localStorage.setItem("theme", defaultTheme);
      }
      if (this.$root.config.mindCollapse) {
        this.mindCollapse = this.$root.config.mindCollapse;
      }
    },
    // 获取子目录
    async getChildrenList(articleId = "") {
      var data = [];
      var res = await this.$api.article.childrenList(articleId);
      if (res.data.data) {
        data = res.data.data;
      }
      return data;
    },
    // 额外模型树数据处理
    dataHandling(data) {
      //更换键名
      data = this.changekeyName(data);
      if (this.moveDialog.visible == true && this.moveDialog.moveId != "") {
        data = data.filter((item) => item.id != this.moveDialog.moveId);
      }
      for (let i = 0; i < data.length; i++) {
        // 修改思维导图线条颜色
        // data[i].lineColor = "#1380FF";
        // 树节点判断是否有子节点
        data[i].hasChildren = !data[i].hasChildren;
      }
      return data;
    },

    // 遍历文档树提取所需数据
    readNodes(nodes = [], arr = []) {
      for (let item of nodes) {
        item.info.expand = item.expand;
        if (item.info.parentId == "") {
          item.info.parentId = null;
        }
        arr.push(item.info);
        if (item.children && item.children.length) {
          this.readNodes(item.children, arr);
        }
      }
      return arr;
    },

    //更换键名 data为数组，keyName为修改前的键名，name为修改后的键名
    changekeyName(data) {
      // JSON.parse(JSON.stringify(data).replace(/keyName/g, 'name'))
      var newData = JSON.parse(JSON.stringify(data).replace(/title/g, "name"));
      return newData;
    },
    // 树搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 新建树
    insertAfter() {
      this.menuNewTreeItem(null, "left", 99);
    },

    mindmapMouseDown() {
      // console.log("down");
      // mindTree.changeSize(495, 656);
    },
    mindmapMouseUp() {
      // console.log("up");
      // mindTree.changeSize(495, 656);
      if (this.timeMindmapMouse) {
        window.clearTimeout(this.timeMindmapMouse);
      }
    },
    // 登录
    toLogin() {
      if (localStorage.getItem("isLogin")) {
        // this.$message.success("您已登录");
      } else {
        this.$router.push("/login");
      }
    },
    outLogin() {
      this.$store.commit("delToken");
      this.isLogin = false;
      this.$router.push("/login");
    },

    // 文档保存状态
    saveStatus(text) {
      if (text === "保存中") {
        this.refreshColor = true;
      } else if (text === "已保存到云端") {
        this.refreshColor = false;
        this.changeContent = false;
      } else {
        this.refreshColor = false;
      }
      this.saveStatusText = text;
    },
    // 打开回收站
    openRecycleBin(articleId = null, list = undefined) {
      if (this.midType === "recyclebin") {
        if (articleId && list) {
          this.$root.appendTree(list, this.treeType === 1);
        } else {
          this.midType = "vditor";
          if (articleId != null) {
            this.toArticle(articleId);
            setTimeout(() => {
              this.reload();
            }, 300);
          }
        }
      } else {
        this.recyclebinKey += 1;
        this.midType = "recyclebin";
        document.querySelector(".tree-item.active")?.classList?.remove("active");
      }
    },
    mindChangeEditor() {
      this.mindShowEditor = !this.mindShowEditor;
    },
    mindSelect(item) {
      if (!item) {
        this.toArticle();
        this.$root.activeItem = undefined;
        return;
      }
      if (item && (!this.$root.activeItem || this.$root.activeItem.id !== item.id)) {
        if (this.$root.activeItem) this.$root.activeItem.active = false;
        this.$root.activeItem = item;
        if (this.$root.activeItem) this.$root.activeItem.active = true;
        if (
          this.$root.activeItem &&
          this.$route.params.articleId !== this.$root.activeItem.id
        ) {
          this.toArticle(this.$root.activeItem.id);
        } else if (!this.$root.activeItem) {
          this.toArticle();
        }
        if (this.$root.config.alwaysEditor === "true") {
          // this.showMDEditor = true;
        }
      }
    },
    openEditor() {
      this.showMDEditor = true;
    },
    isShowMDEditor() {
      return this.showMDEditor;
    },
    isShowMindMap() {
      return this.showMindMap;
    },
    // 打开历史记录
    openHistory() {
      if (this.isTest) {
        if (this.$root.activeItem) {
          this.showHistory = true;
          setTimeout(() => {
            this.hideBottomEditor = true;
          }, 250);
        }
      } else {
        this.showMDEditor = true;
        this.$nextTick(() => {
          this.toHistory();
        });
      }
    },
    toHistory() {
      if (
        this.$root.activeItem &&
        this.showMindMap &&
        this.showEditorToRight &&
        this.showMDEditor
      ) {
        this.$refs.froalaEditorTop.history();
      } else {
        this.$refs.mainMap.history();
      }
    },
    // 编辑器刷新
    editorRefresh() {
      this.editorKey += 1;
    },

    // 打开设置页面
    openConfig(configActive = "bindAccount") {
      this.$root.configActive = configActive;
      this.$root.configVisible = true;
    },
    getPdf(domId) {
      var title = this.$refs.tree.getNode(this.articleId).data.name;
      html2Canvas(document.querySelector(domId), {
        allowTaint: true,
      }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new JsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });
    },

    fullScreen() {
      screenfull.toggle();
    },
    onSearchChange(item) {
      if (this.$root.activeItem) this.$root.activeItem.active = false;
      this.$root.activeItem = item;
      this.$root.activeItem.active = true;
      if (
        this.$root.activeItem &&
        this.$route.params.articleId !== this.$root.activeItem.id
      ) {
        if (this.$root.curGroup) {
          if (
            !this.$root.curGroup.itemList ||
            !this.$root.curGroup.itemList.includes(item.id)
          ) {
            this.$root.curGroup = null;
          }
        }
        this.toArticle(this.$root.activeItem.id);
      }
    },
    handleMessage(e) {
      // console.log(e.data);
      var data = e.data;
      switch (data.cmd) {
        case "returnInfo":
          if (data.params.code) {
            // console.log("shoudao", data.params.code);
            // this.isWxLogin = false;
            this.wxBind(data.params.code);
            // var container = document.getElementsByClassName("container-fluid");
            // container[0].style.cssText =
            //   "background: #eeeef3 url(/pc/bg.png) no-repeat;";
          }
          break;
      }
    },
    wxBind(code) {
      // console.log("1");
      // if (this.timeWxBind) {
      //   window.clearTimeout(this.timeWxBind);
      // }
      // this.timeWxBind = window.setTimeout(() => {
      this.$api.account.bindWebWechat({ code: code }).then((res) => {
        if (res.data.isSuccess == true) {
          this.$store.commit("setWechatAccount", true);
          this.$root.tips("success", "绑定成功");
          this.$refs.setConfig.updateBindWechat();
        } else {
          if (res.data.message != "系统繁忙，请稍后再试。") {
            this.$root.tips("error", res.data.message);
          }
        }
      });
      // }, 300);
    },
    resolveTo(val) {
      let routeData = null;
      let theme = "";
      // if (this.theme.includes("dark")) {
      //   theme = "/dark";
      // }
      switch (val) {
        case "version":
          routeData = this.$router.resolve({
            path: `/version${theme}`,
          });
          window.open(routeData.href, "_blank");
          break;
        case "help":
          routeData = this.$router.resolve({
            path: `/help${theme}`,
          });
          window.open(routeData.href, "_blank");
          break;
        default:
          break;
      }
    },
    refreshUrl() {
      this.$api.share
        .share({
          articleId: this.shareArticleId,
          shareType: this.articleShare.shareType,
          childCascade: this.articleShare.childCascade,
          allowMulti: this.articleShare.allowMulti,
        })
        .then((res) => {
          if (res.data.isSuccess && res.data.data) {
            this.articleShare.url =
              window.location.origin + "/share/article/" + res.data.data;
            this.$root.tips("success", "重置成功");
          } else {
            this.articleShare.url = "";
            this.$root.tips("error", res.data.message);
          }
        });
    },
    insertHtml(text) {
      this.$refs.froalaEditorTop.insertHtml(text);
    },
    onAltLeftClose() {
      if (this.directionStep < 2) {
        this.directionStep++;
      }
      this.treeShowLeft();
      if (this.altLeft) {
        this.$refs.altLeftRef.$el.style.transition = "all .25s";
        this.$refs.altLeftRef.$el.style.display = "block";

        setTimeout(() => {
          this.$refs.altLeftRef.$el.style.width = this.altLeft + "px";
          setTimeout(() => {
            this.$refs.altLeftRef.$el.style.transition = "none";
          }, 250);
          this.altLeft = 0;
        }, 20);
      } else {
        this.altLeft = this.$refs.altLeftRef.$el.offsetWidth;
        this.$refs.altLeftRef.$el.style.transition = "all .25s";
        setTimeout(() => {
          this.$refs.altLeftRef.$el.style.width = "0";
          setTimeout(() => {
            this.$refs.altLeftRef.$el.style.transition = "none";
            this.$refs.altLeftRef.$el.style.display = "none";
          }, 250);
        }, 20);
      }
    },
    treeFlex() {
      this.treeBoxShow = !this.treeBoxShow;
    },
    treeShowLeft() {
      this.showLeft = !this.showLeft;
    },
    mindDownload() {
      var backgroundColor = "#FFF";
      if (this.$root.config.theme.includes("dark")) {
        backgroundColor = "#262626";
      }
      var data = mindTree.findDataById("0");
      mindTree.downloadFullImage(data.name, "image/png", {
        backgroundColor: backgroundColor,
        padding: 30,
      });
      // mindTree.downloadImage(data.name + "_", "image/png", backgroundColor);
    },
    queryChatgpt(type, data) {
      if (data != "") {
        this.chatgpt.visible = true;
        this.changeNav("ai");
        setTimeout(() => {
          this.$refs.chatgptRef.quickChatgpt(type, data);
        }, 500);
      }
    },
    closeChatgpt() {
      this.chatgpt.visible = false;
      this.showHelpCenter = true;
    },
    changeChatNav(type) {
      if (this.$root.config.shortcutMenu && this.$root.config.shortcutMenu.viewLocation) {
        this.changeChatGptSize(this.$root.config.shortcutMenu.viewLocation);
        this.ffType = this.$root.config.shortcutMenu.viewLocation;
      }
      if (this.ffType === "fixed") {
        this.ffType = "full";
      }
      this.chatNav = type;
      this.chatgpt.visible = true;
      this.showHelpCenter = false;
      if (type === "hotkeyDrawer") {
        this.$refs.chaggpt.autoSize();
        this.changeChatGptSize("right");
      }
      if (this.chatNav === "transfer" && this.ffType === "full") {
        setTimeout(() => {
          this.$refs.transferRef.isWide = true;
        }, 10);
      }
      localStorage.setItem("chatNav", type);
    },
    changeNav(type) {
      this.chatNav = type;
    },
    passTochatNav(type = "transfer", data) {
      this.changeChatNav(type);
      setTimeout(() => {
        this.$refs[type + "Ref"].changeInput(data);
      }, 500);
    },
    mindPasteItem(options) {
      if (!options.nodes.type) {
        return;
      }
      const type = options.nodes.type;
      const itemModel = options.nodes.nodes[0].getModel();
      const parentModel = options.parent.getModel();
      if (!itemModel || !parentModel) {
        return;
      }
      const itemNode = this.$root.findItemById(itemModel.id);
      const parentNode = this.$root.findItemById(parentModel.id);
      if (itemModel.sortId === parentModel.sortId.slice(0, itemModel.sortId.length)) {
        this.$root.tips(
          "error",
          "无法" + (type === "cut" ? "移动" : "复制") + "自己或到子模型中！"
        );
        return;
      }
      if (type === "cut") {
        this.$refs.cloudTreeRef.$refs.treeList.moveToInner(itemNode, parentNode, true);
        this.$root.tips("success", "移动模型成功");
      } else if (type === "copy") {
        this.$refs.cloudTreeRef.$refs.treeList.cloneItem(itemNode, parentNode);
        this.$root.tips("success", "复制模型成功");
      }
    },
    async transferNewTreeItem(item, title) {
      return await this.$refs.cloudTreeRef.$refs.treeList.appendItem(
        item,
        0,
        true,
        title
      );
    },
    onSaveEditor() {
      if (this.$refs.froalaEditorTop) {
        this.$refs.froalaEditorTop?.save();
      } else if (this.$refs.mainMap?.$refs.froalaEditorTop) {
        this.$refs.mainMap?.$refs.froalaEditorTop.save();
      }
    },
    onMindShowEdges() {
      if (this.isValidMindMap()) {
        this.$refs.mainMap.$refs.mindmap.$refs.map.showEdges();
      }
    },
    newTab() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.changeChatGptSize("newTab");
        }, 500);
        setTimeout(() => {
          this.$refs.transferRef.isWide = true;
        }, 520);
      });
    },
    async getAiAnswer({ node, sort }, type = "") {
      var messages = [];
      if (type === "treeMenuAiTranslate") {
        // messages[0] = {
        //   role: "user",
        //   content:
        //     "翻译以下内容，如果是中文，翻译成英文；如果是中文以外的语言则翻译成中文，只需要输出翻译结果，其他内容不需要：" +
        //     node.item.title +
        //     "",
        // };
        messages = {
          content:
            "翻译以下内容，如果是中文，翻译成英文；如果是中文以外的语言则翻译成中文，只需要输出翻译结果，其他内容不需要：" +
            node.item.title +
            "",
        };
      } else {
        var model = node.getModel();
        // messages[0] = {
        //   role: "user",
        //   content:
        //     "Based on the '" +
        //     model.title +
        //     "', identify whether this word belongs to 'identity, place, event, or object'. If it belongs to identity, please output actions, emotions, tools, attitudes, events, adjectives, and adverbs related to this identity, and derivatives if any; if the input word belongs to a place, please output professions, objects, facilities, adjectives, and adverbs related to this place, and derivatives if any; if the input word belongs to an event, please output actions, objects, tools, adjectives, and adverbs related to this event, and derivatives if any; if the input word belongs to an object, please output actions, adjectives, and adverbs related to this object, and derivatives if any. Output 0 to 5 English words each time for each type. If the input word does not belong to any of the above categories, please output 8 words that are often associated with or occur with it. The output content should be in English without any prompts or explanations, in the format [apple,banana,car,...].",
        // };
        messages = {
          content:
            "Based on the '" +
            model.title +
            "', identify whether this word belongs to 'identity, place, event, or object'. If it belongs to identity, please output actions, emotions, tools, attitudes, events, adjectives, and adverbs related to this identity, and derivatives if any; if the input word belongs to a place, please output professions, objects, facilities, adjectives, and adverbs related to this place, and derivatives if any; if the input word belongs to an event, please output actions, objects, tools, adjectives, and adverbs related to this event, and derivatives if any; if the input word belongs to an object, please output actions, adjectives, and adverbs related to this object, and derivatives if any. Output 0 to 5 English words each time for each type. If the input word does not belong to any of the above categories, please output 8 words that are often associated with or occur with it. The output content should be in English without any prompts or explanations, in the format [apple,banana,car,...].",
        };
        if (type === "aiExSentence") {
          this.openEditor();
          // messages[0] = {
          //   role: "user",
          //   content:
          //     "Please provide 3 example sentences using the word '" +
          //     model.title +
          //     "' that are simple and easy to understand, in the following format: [a, b, c,...]",
          // };
          messages = {
            content:
              "Please provide 3 example sentences using the word '" +
              model.title +
              "' that are simple and easy to understand, Separate example sentences with /n",
          };
        } else if (type === "aiTranslate") {
          // messages[0] = {
          //   role: "user",
          //   content:
          //     "翻译以下内容，如果是中文，翻译成英文；如果是中文以外的语言则翻译成中文，只需要输出翻译结果，其他内容不需要：" +
          //     model.title +
          //     "",
          // };
          messages = {
            content:
              "翻译以下内容，如果是中文，翻译成英文；如果是中文以外的语言则翻译成中文，只需要输出翻译结果，其他内容不需要：" +
              model.title +
              "",
          };
        }
      }
      // this.getChatgpt(messages, { node, sort }, type);
      this.getChatgptV2(messages, { node, sort }, type);
    },
    getChatgptV2(messages, { node, sort }, type) {
      var chatText = "";
      let that = this;
      fetch("https://chat-gpt.moxingshu.com.cn/v2/completionBootStream", {
        method: "POST",
        body: JSON.stringify(messages), // 将数据作为 JSON 字符串发送
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          that.$root.tips("success", "正在生成中，请稍等片刻");
          const reader = response.body.getReader(); // 获取可读取流式数据的 Reader 对象
          return new ReadableStream({
            start(controller) {
              function pump() {
                reader.read().then(({ done, value }) => {
                  if (done) {
                    setTimeout(() => {
                      // var data = JSON.parse(e.data);
                      if (type === "aiExSentence") {
                        that.aiExSentenceToContent({ node, sort }, chatText);
                      } else if (
                        type === "aiTranslate" ||
                        type === "treeMenuAiTranslate"
                      ) {
                        that.getAiTranslate({ node, sort }, chatText, type);
                      } else {
                        that.aiRelatedWordsToNewItem({ node, sort }, chatText);
                      }
                    }, 100);
                    return;
                  }
                  const decoder = new TextDecoder();
                  const stringValue = decoder.decode(value); // 将 Uint8Array 解码为字符串
                  chatText = chatText + stringValue;
                  // 处理流式数
                  controller.enqueue(value);
                  pump(); // 继续读取下一部分数据
                });
              }

              pump(); // 开始读取数据
            },
          });
        })
        .catch((error) => {
          that.$root.tips("error", error);
        });
    },
    getChatgpt(messages, { node, sort }, type) {
      var that = this;
      var first = false;
      var chatText = "";
      if (typeof EventSource !== "undefined") {
        let aiType = "";
        let model = "gpt-3.5-turbo";
        if (that.chatModel === "G4") {
          aiType = "?type=gpt4";
          model = "gpt-4o";
        }
        const eventSource = new EventSource(
          "https://chat-gpt.moxingshu.com.cn/v2/completionStream" + aiType
        );
        eventSource.onopen = function (e) {
          if (first == false) {
            first = true;
            that.$api.other
              .chatgptV2({
                model: model,
                // model: "gpt-3.5-turbo",
                // prompt: prompt, //必填 输入文本
                // temperature: 0.9, //可选
                // max_tokens: 150, //可选 响应文本长度
                // top_p: 1, //可选
                // frequency_penalty: 0.0, //可选
                // presence_penalty: 0.6, //可选
                // stop: [" Human:", " AI:"], //可选
                messages: messages,
              })
              .then((res) => {
                if (res.data.msg == "成功") {
                  // console.log(messages);
                  that.$root.tips("success", "正在生成中，请稍等片刻");
                } else {
                  that.$root.tips("error", res.data.msg);
                  eventSource.close();
                }
              });
          }
        };
        eventSource.onmessage = function (e) {
          // console.log(e.data);
          if (!e.data.includes("[done]")) {
            var data = JSON.parse(e.data);
            chatText = chatText + data[0].delta.content;
            if (data[0].finish_reason == "stop") {
              eventSource.close();
              if (type === "aiExSentence") {
                that.aiExSentenceToContent({ node, sort }, chatText);
              } else if (type === "aiTranslate" || type === "treeMenuAiTranslate") {
                that.getAiTranslate({ node, sort }, chatText, type);
              } else {
                that.aiRelatedWordsToNewItem({ node, sort }, chatText);
              }
            }
          }
        };
        eventSource.onerror = function (e) {
          // console.log(e);
          // console.log("错误断开");
          // eventSource.close();
        };
      } else {
        that.$root.tips("error", "当前浏览器不支持");
      }
    },
    mindImgToEditor({ dataURL }) {
      this.openEditor();
      setTimeout(() => {
        this.$refs.froalaEditorTop.insertMindImg(dataURL);
      }, 500);
    },
    async aiRelatedWordsToNewItem({ node, sort }, text) {
      text = text
        .replace(/[\t\r\f\n\s]*/g, "")
        .replace(/\[|]/g, "")
        .replace(/\["'“”‘’]$/g, "");
      var data = text.split(",");
      var model = node.getModel();
      var item = this.$root.findItemById(model.id);
      for (let i in data) {
        await this.$refs.mainMap?.$refs.mindmap?.onAdd({ node, sort }, data[i]);
      }
      this.$root.tips("success", "生成成功");
      setTimeout(async () => {
        if (this.showMindMap && this.$refs.mainMap) {
          this.$refs.mainMap.$refs.mindmap.key += 1;
        }
        await this.$root.setSelectItem(item);
      }, 500);
    },
    aiExSentenceToContent({ node, sort }, text) {
      // text = text.replace(/\n/g, "<br>");
      // this.insertHtml("<p>" + text + "</p>");
      console.log(text);
      // this.$refs.mxsEditor.focus();
      setTimeout(() => {
        this.$refs.mxsEditor.insertText(text);
      }, 100);
    },
    async getAiTranslate({ node, sort }, text, type) {
      if (!text) return;
      let p = null;
      if (type === "treeMenuAiTranslate") {
        p = this.$root.findItemById(node.item.id);
      } else {
        var model = node.getModel();
        p = this.$root.findItemById(model.id);
      }
      if (p) {
        p.title = text;
        this.changeTitleApi && (await this.changeTitleApi(p));
        if (this.showMindMap && this.$refs.mainMap) {
          this.$refs.mainMap.$refs.mindmap.key += 1;
        }
      }
    },
    changeHotkeyDrawer() {
      if (!this.hotkeyDrawer) {
        this.hotkeyDrawer = true;
        setTimeout(() => {
          this.$refs.hotkeyDrawer.style = "width:300px";
        }, 0);
      } else {
        this.$refs.hotkeyDrawer.style = "width:0px";
        setTimeout(() => {
          this.hotkeyDrawer = false;
        }, 200);
      }
    },
    goIndex() {
      this.$router.push("/index");
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body,
div.box {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

div {
  box-sizing: border-box;
}

div.box {
  display: flex;
}

.bar-border {
  position: absolute;
  width: 5px;
  top: 0;
  right: 0;
  height: 100%;
  // border-right: 1px solid #e9e9e9;
  cursor: e-resize;
  z-index: 2;
}

/*因为拖动按钮属于滚动条系列，所以可通过改变滚动来间接改变拖动按钮的样式*/
div.bar::-webkit-scrollbar {
  width: 10px;
  height: 9999px;
}

.flex-s {
  flex-shrink: 0;
}

.flex-g {
  flex-grow: 1;
  min-width: 20%;
}

.pointer {
  cursor: pointer;
}

.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;

  .left {
    flex-shrink: 0;
    width: 260px;
    max-width: 260px;
    background: #f4f4f5;
    mix-blend-mode: normal;
  }

  .right {
    min-width: 50%;
    flex-grow: 1;
    height: 100%;
  }
}

.right-header {
  height: 64px;
}

.left-footer {
  padding: 0;
}

.user-container {
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 70px;
  padding: 5px 75px;

  .user-container-nick {
    @extend .flex-g;
    padding: 0 10px;
    color: #109adc;
    font-size: 20px;
  }

  .user-container-msgBtn {
    @extend .pointer;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 6px;

    &:hover {
      background-color: rgba(#000, 0.04);
    }
  }
}

.right-main {
  padding: 0;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px 5px 15px;
  border-bottom: 1px solid #eee;

  .search-bar-input {
    @extend .flex-g;
    height: 32px;
    font-size: 14px;
    padding: 10px;
    background-color: rgb(233, 233, 235);
    border: none;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .search-bar-btn {
    text-align: center;
    line-height: 40px;
  }
}

.item-bar {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0 15px 0px 15px;
  border-bottom: 1px solid #eee;

  .item-bar-btn {
    position: relative;
    display: inline-flex;
    width: auto;
    min-width: 0px;
    height: 26px;
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 6px;
    border: none;
    background-color: rgb(244, 244, 245);
    border-radius: 6px;
    color: rgb(117, 117, 125);
    cursor: pointer;
    font-size: 14px;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s,
      border 0.2s ease 0s;
    white-space: nowrap;
    margin-right: 10px;
  }
}

.custom-tree-node-svg {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 5px;
}

.document-list-elm {
  background-color: transparent;
  font-size: 9px;
  // color: #4d627a;
  margin: 0 10px;
  padding-bottom: 40px;

  .custom-tree-node-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    height: 36px;

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
      height: 36px;
    }

    .custom-tree-node-methods {
      display: none;
      line-height: 36px;
    }

    &:hover {
      // .custom-tree-node {
      //   color: #1380ff;
      // }
      // background: #f2f2f2;
      border-radius: 4px;

      .custom-tree-node-methods {
        display: block;
      }
    }
  }
}

:deep .el-tree__drop-indicator {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1380ff !important;
}

:deep .el-tree-node.is-drop-inner > .el-tree-node__content {
  box-sizing: border-box;
  border: 1px dashed #1380ff;
  border-radius: 4px;
}

:deep .el-tree-node__content > .el-tree-node__expand-icon {
  margin: 0;
  padding: 6px;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
}

.document-elm-menu {
  > *:nth-child(2) {
    display: inline-block;
    margin-left: 10px;
  }
}

.document-btn {
  font-size: 12px;
  padding-left: 4px;
  margin-left: 8px !important;
  padding-top: 6px;
}

.data-input {
  width: 80%;
}

.el-input__inner::placeholder {
  color: #333333;
}

.el-input__inner::-webkit-input-placeholder {
  color: #333333;
  text-align: center;
}

.el-input__inner:-moz-placeholder {
  color: #333333;
}

.el-icon-plus-blue {
  color: #1380ff;
}

:deep .el-breadcrumb__separator {
  margin: -1px 0 0 5px;
  font-weight: 500;
  font-size: 12px;
  color: #6c6c6c;
  display: inline-block;
  vertical-align: top;
}

.button-group {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 0px;
}

.right-breadcrumb {
  font-size: 12px;
  color: #1f2329;
  margin-left: 20px;
  margin-top: 16px;
  max-width: 500px;
  line-height: 16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-refresh {
  // color: #666666;
  font-size: 10px;
  margin-left: 20px;
  margin-top: 5px;
}

.refreshactive {
  color: #1380ff;
}

// :deep .el-divider--vertical {
//   margin: 0 8px 0 8px !important;
// }

#mindmap {
  height: 100%;
}

//.treeactive {
//  background: url("~@/assets/icon-tree-b.png") no-repeat center center;
//  background-size: 100%;
//}
//
//.bookactive {
//  background: url("~@/assets/icon-book-b.png") no-repeat center center;
//  background-size: 100%;
//}

.active {
  color: #1380ff;
}

.icon-color {
  color: #666666;
}

.mind-map-box {
  float: left;
  width: 100%;
}

.smaller {
  // width: calc(70% - 10px) !important;
  overflow: hidden;
}

.el-dropdown-menu__item--divided:before {
  margin: 0 !important;
}

.el-dropdown-menu__item--divided {
  margin: 0 !important;
}

.el-dropdown-menu {
  padding: 5px !important;
}

.scroll-box {
  transform-origin: left bottom;
  transform: translate(0px, 0px) scale(1);
  transition: all 0.5s ease 0.5s;
}

.dropdown {
  vertical-align: middle;
}

.move-dialog {
  :deep .el-dialog {
    border-radius: 12px;

    .el-dialog__body {
      padding: 0 20px;
    }
  }
}

.offer-dialog {
  :deep .el-dialog {
    border-radius: 12px;

    .el-dialog__body {
      padding: 0 20px 20px 20px;
    }
  }
}

.red-color {
  color: #de4e4e !important;
}

:deep .fr-box.fr-basic .fr-element {
  padding: 0;
  color: #000;
}

.resize-bar {
  width: var(--left-max-width);
  max-width: var(--left-max-width);
}

.resize-bar::-webkit-scrollbar {
  width: var(--left-max-width);
  height: inherit;
}

:deep .el-message .el-message__badge {
  display: none !important;
}

:deep .el-badge.el-message__badge {
  display: none !important;
}

:deep .mindmap-toolbar-bottom .mindmap-toolbar-list_item {
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

:deep .mindmap-toolbar-bottom .mindmap-toolbar {
  left: -35px;
  top: -5px;
}

:deep .mindmap-toolbar-bottom {
  right: 10px;
  bottom: 10px;
  width: 30px;
  height: 100px;
  box-shadow: 0px 0px 4px 2px rgb(153 153 153 / 16%);
  border-radius: 4px;
}

.mind-map-editor {
  flex: 1;
  transition: all 0.2s;
}
.mobile-container {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.article-zindex-9 {
  z-index: 9;
}
</style>
