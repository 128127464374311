<template>
  <div class="mxs-history">
    <HistoryComponent
      ref="editor"
      v-if="articleId"
      :articleId="articleId"
      :wss="'wss://' + wss"
      :token="accessToken"
      :theme="$root.config.theme.includes('dark') ? 'dark' : 'light'"
      :url="'https://' + wss + '/v1/history'"
      :onClose="onClose"
      :onGetOldHistory="getOldHistory"
      :onGetHistoryDetail="onGetHistoryDetail"
      :onRecover="onRecover"
    />
  </div>
</template>

<script>
import { HistoryComponent } from "../../../../components/mxs-editor/word-viewer";
import "../../../../components/mxs-editor/style.css";
import { applyPureReactInVue } from "veaury";
import base from "../../../../api/base";
import { htmlToJson } from "../../../../components/mxs-editor/html";

export default {
  name: "MxsHistory",
  props: {
    articleId: { type: String, default: "" },
  },
  data() {
    return {
      wss: base.yjs,
    };
  },
  computed: {
    accessToken() {
      return localStorage.getItem("mindmap_token");
    },
  },
  components: {
    HistoryComponent: applyPureReactInVue(HistoryComponent),
  },
  mounted() {
    // this.getOldHistory();
  },
  methods: {
    async onRecover(title) {
      this.$api.article.renameArticle({
        articleId: this.articleId,
        title: title,
      }).then(()=>{
        const item = this.$root.findItemById(this.articleId);
        if (item) {
          item.title = title;
        } else {
          console.log('node no exist');
        }
      })
    },
    async onGetHistoryDetail(item) {
      return this.$api.article.historyArticleDetail(item.id).then((res) => {
        if (res.data && res.data.content) {
          const list = htmlToJson({ html: res.data.content });
          return list;
        }
        return [{ children: [{ text: "" }] }];
      });
    },
    async getOldHistory() {
      return this.$api.article
        .historyArticleList(this.articleId)
        .then((res) => {
          if (res.isSuccess) {
            return res.data;
          }
          return [];
        })
        .catch(() => {
          return [];
        });
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
