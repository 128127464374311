<template>
  <div class="d-between link-tree-item" :class="{active: item.active}">
    <div class="d-flex">
      <div v-if="iconType" class="tree-icon d-center">
        <svg-icon :icon-class="iconType.icon"
                  class-name="size-3 icon-color-white"
                  :class="[{active: item.active && !isMarkEmphasis}, iconType.className||'']"/>
      </div>
      <span>{{ item.title }}</span>
    </div>
    <div class="d-flex link-icon" v-if="item.link1 || item.link2">
      <svg-icon v-if="item.link1" icon-class="link-double"></svg-icon>
      <svg-icon v-if="item.link2" icon-class="single2"></svg-icon>
    </div>
  </div>
</template>

<script>
import {iconMaps} from "../../../../utils/TreeConstants";

export default {
  name: "LinkTreeItem",
  props: {
    item: {type: Object, default: undefined},
  },
  computed: {
    iconType: {
      get() {
        return iconMaps[this.$getIconType(this.item)]
      }
    },
    isMarkEmphasis: {
      get() {
        return this.$isMarkEmphasis(this.item);
      }
    },
  }
}
</script>

<style scoped>

</style>
