<template>
  <drag-right v-if="list.length>0"
              class="link-tree"
              direction="top"
              :init-height="300"
              :show-mode="0"
  >
    <div class="link-tree-title">
      双链文档链接
    </div>
    <div class="link-tree-list">
      <link-tree-item v-for="node in list"
                      :key="node.id"
                      :item="node"
                      @click="onClick(node)"
      />
    </div>
  </drag-right>
</template>

<script>
import DocumentItem from "./DocumentItem";
import DragRight from "../document/DragRight";
import DragView from "../widget/DragView";
import LinkTreeItem from "./LinkTreeItem";

export default {
  name: "LinkTree",
  components: {LinkTreeItem, DragView, DragRight, DocumentItem},
  data() {
    return {
      list: []
    }
  },
  watch: {
    '$root.activeItem': {
      handler(val, old) {
        if ((old && val && val.id !== old.id) || val !== old) {
          this.initList();
        }
      }
    }
  },
  mounted() {
    this.initList();
  },
  methods: {
    onClick(item) {
      this.$root.activeItem = item;
    },
    initList() {
      const val = this.$root.activeItem;
      if (val) {
        const edges = this.$root.edges.filter(edge => edge.source === val.id || edge.target === val.id);
        if (edges.length > 0) {
          this.list = edges.map(edge => this.$root.findItemById(edge.source === val.id ? edge.target : edge.source));
          this.list.unshift(val);
        }
      } else {
        this.list = [];
      }
    }
  }
}
</script>

<style lang="scss" scoped>


</style>
