<template>
  <drag-view
    class="map-dialog d-column"
    init-width="90%"
    init-right="5%"
    init-top="5%"
    init-bottom="5%"
    :no-fixed="false"
  >
    <template #header>
      <div class="d-end">
        <div class="d-center mind-title">
          <div class="chat-s d-center">
            <div v-if="isTransfer" class="chat-0 d-center active">
              <svg-icon icon-class="020" class-name="svg-small" />
              自由导图
            </div>
            <div v-else class="chat-0 d-center active">
              <svg-icon icon-class="020" class-name="svg-small" />
              关联双链
            </div>
          </div>
        </div>
        <div
          v-if="isTransfer"
          class="btn btn-font-gray d-center btn-close import"
          @click="importTransfers"
        >
          一键导入
        </div>
        <div class="btn btn-font-gray d-center btn-close" @click="onClose">
          <CloseBold />
        </div>
      </div>
    </template>
    <div class="flex-1 pos-relative">
      <mind-graph
        ref="map"
        :mode="mode"
        theme="white"
        :model-value="modelValue"
        :isTransfer="true"
        :mindmap="true"
        :show-toolbar="true"
        :resize-center="true"
        :scale-ratio="1.3"
        :sharp-corner="true"
        :hide-edge="false"
        :default-edge-style="this.$root.mindEdgeStyle"
        :default-node-style="this.$root.mindNodeStyle"
        @change="onMindMapChange"
        @close="onClose"
      />
    </div>
  </drag-view>
</template>

<script>
import DragView from "../widget/DragView";
import MindGraph from "./MindGraph";
import { MindmapEvent } from "../../../../components/antv-mindmap/mxs-mindmap.es";
export default {
  name: "MapDialog",
  components: { DragView, MindGraph },
  props: {
    modelValue: { type: Object, default: null },
    isTransfer: { type: Boolean, default: false },
    mode: { type: String, default: "default" },
    type: { type: String, default: "" },
  },
  data() {
    return {};
  },
  inject: ["transferNewTreeItem"],
  methods: {
    async importTransfers() {
      await this.$refs.map.graph._editor.blur();
      let model = this.$refs.map.graph.findById("000").getModel();
      if (this.type === "newTab") {
        //
      } else {
        var activeItem = this.$root.activeItem;
        let item = await this.transferNewTreeItem(model.title);
        this.findChild(model.children, this.$root.activeItem);
        this.$root.tips("success", "导入成功");
        setTimeout(() => {
          this.$root.setSelectItem(activeItem);
          this.onClose();
        }, 500);
      }
    },
    async findChild(model, activeItem) {
      for (let i in model) {
        var data = model[i];
        // const res = await this.getSkillPromise(data);
        // console.log(res);
        this.$root.activeItem = activeItem;
        let item = await this.transferNewTreeItem(data.title);
        if (data.children.length > 0) {
          await this.findChild(data.children, this.$root.activeItem);
        }
      }
    },
    onMindMapChange({ type, options }) {
      switch (type) {
        case MindmapEvent.nodeAdd:
          this.onAdd(options);
          break;
        default:
          break;
      }
    },
    onAdd({ node }) {
      this.$refs.map.graph.editAddNode(node, { id: this.GenNonDuplicateID() });
    },
    GenNonDuplicateID() {
      let idStr = Date.now().toString(36);
      idStr += Math.random().toString(36).slice(3);
      return idStr;
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.import {
  width: 60px;
  background-color: #35374b;
  color: #fff;
  height: 26px;
  font-size: 12px;
}
</style>
