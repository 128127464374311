<template>
  <tree-list :init-list="$root.groupList"
             class="flex-1 scroll"
             :update-node-api="onUpdateNodeApi"
             :no-active="true"
             @group-add="groupAdd"
             @onSelect="onSingleClick"
             @onContextMenu="menuOpen"
             @drag-node="onDragNode"
             @onDouble="onDouble"
             @drag-external="onDragExternal"
  >
    <template #default="{ data, node }">
      <document-item
          v-model:isEdit="node.isEdit"
          v-model:title="data.title"
          :is-mobile="isMobile"
          :item="data"
          @update:title="onChangeTitle(node)"
          @input-focus="onFocus"
          @onRename="treeRenameApi(node, $event)"
          @onMenu="(node, type, data) => onMenu(node, type, data)"
      />
    </template>
  </tree-list>
</template>

<script>
import DocumentItem from "../tree/DocumentItem";
import TreeList from "../tree/TreeList";

export default {
  name: "GroupTree.vue",
  components: {
    TreeList,
    DocumentItem,
  },
  inject: ["onDragTreeNode", "cloneArticle"],
  props: {
    isMobile: { type: Boolean, default: false },
    updateNodeApi: { type: Function, default: undefined },
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    async onDragExternal(options) {
      return this.cloneArticle(options);
    },
    onDragNode(node) {
      this.onDragTreeNode(node);
    },
    async groupDelete(item) {
      if (!this.$root.curGroup) return;
      await this.$root.deleteGroupItem(this.$root.curGroup.id, item.id);
    },
    onDouble(e, item) {
      // const input = e.target.querySelector(".tree-title");
      // if (input) {
      //   input.focus();
      // }
    },
    menuOpen(e, node) {
      this.$emit('onContextMenu', e, node)
    },
    onSingleClick(item) {
      item.active = true;
      this.$emit("on-select", item, true);
    },
    onChangeTitle(node) {
      this.$emit("change-title", node);
    },
    onFocus() {
      this.$emit("input-focus");
    },
    treeRenameApi(node) {
      this.$emit("rename-api", node);
    },
    onMenu(node, type, data) {
      this.$emit("on-menu", node, type, data);
    },
    async groupAdd(item) {
      const res = await this.$root.updateGroupItem({
        groupId: this.$root.curGroup.id,
        articleId: item.id,
        sort: item.sort,
      });
      return res;
    },
    async onUpdateNodeApi(data) {
      if (this.updateNodeApi) {
        return await this.updateNodeApi(data);
      }
    },
  },
};
</script>

<style scoped></style>
