<template>
  <div :class="['offer-dialog', $root.config.theme]">
    <el-dialog :model-value="articleShare.Visible" :modal="false" draggable title="分享文档" width="420px"
      :close-on-click-modal="false" custom-class="article-dialog" @close="articleShareVisible('Visible', false)">
      <div class="offer-info">拿到链接的人可获得阅读权限</div>
      <div style="height: 45px">
        <el-input :value="articleShare.url" placeholder="" class="url-input" disabled>
          <template #append>
            <div @click="refreshUrl" style="cursor: pointer">
              <el-icon>
                <Refresh />
              </el-icon>
              重置
            </div>
          </template>
        </el-input>
        <el-button class="url-btn" @click="copyBtn(articleShare.url)">
          复制链接
        </el-button>
      </div>
      <div class="offer-div">
        <div v-if="false" class="offer-b" @click="articleShareVisible('shareTypeVisible', true)">
          <div class="btn-l">
            <el-icon class="btn-icon">
              <User />
            </el-icon>
            订阅模式
          </div>
          <div class="btn-r" v-if="articleShare.shareType === 0">
            副本
            <el-icon class="el-icon--right">
              <CaretRight />
            </el-icon>
          </div>
          <div class="btn-r" v-else-if="articleShare.shareType === 1">
            订阅
            <el-icon class="el-icon--right">
              <CaretRight />
            </el-icon>
          </div>
        </div>
        <div v-if="false" class="offer-b" @click="articleShareVisible('childCascadeVisible', true)">
          <div class="btn-l">
            <el-icon class="btn-icon">
              <Stamp />
            </el-icon>
            包含子节点
          </div>
          <div class="btn-r" v-if="articleShare.childCascade">
            包含
            <el-icon class="el-icon--right">
              <CaretRight />
            </el-icon>
          </div>
          <div class="btn-r" v-else>
            不包含
            <el-icon class="el-icon--right">
              <CaretRight />
            </el-icon>
          </div>
        </div>
        <div class="offer-b" @click="articleShareVisible('allowMultiVisible', true)">
          <div class="btn-l">
            <el-icon class="btn-icon">
              <DocumentCopy />
            </el-icon>
            二次分享
          </div>
          <div class="btn-r" v-if="articleShare.allowMulti">
            允许
            <el-icon class="el-icon--right">
              <CaretRight />
            </el-icon>
          </div>
          <div class="btn-r" v-else>
            不允许
            <el-icon class="el-icon--right">
              <CaretRight />
            </el-icon>
          </div>
        </div>
        <!-- <el-button plain class="btn-add">
          <el-icon>
            <Plus />
          </el-icon>
        </el-button> -->
      </div>
    </el-dialog>
    <el-dialog v-if="articleShare.shareTypeVisible" :model-value="articleShare.shareTypeVisible" draggable title="订阅模式"
      width="420px" :modal="false" custom-class="concert-dialog" @close="articleShareVisible('shareTypeVisible', false)">
      <template #header>
        <div @click="articleShareVisible('shareTypeVisible', false)" class="title">
          &lt; 订阅模式
        </div>
      </template>
      <div class="offer-info">
        副本，分享时的模型树内容。订阅，当前的模型树内容。
      </div>
      <div>
        <div class="concert-btn" @click="articleShareVisible('shareType', 0)">
          <span>副本</span>
          <el-icon v-if="articleShare.shareType === 0">
            <Check />
          </el-icon>
        </div>
        <div class="concert-btn" @click="articleShareVisible('shareType', 1)">
          <span>订阅</span>
          <el-icon v-if="articleShare.shareType === 1">
            <Check />
          </el-icon>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-if="articleShare.childCascadeVisible" :model-value="articleShare.childCascadeVisible" draggable
      title="包含子节点" width="420px" :modal="false" custom-class="concert-dialog"
      @close="articleShareVisible('childCascadeVisible', false)">
      <template #header>
        <div @click="articleShareVisible('childCascadeVisible', false)" class="title">
          &lt; 包含子节点
        </div>
      </template>
      <div class="offer-info">是否包含分享模型的子模型。</div>
      <div>
        <div class="concert-btn" @click="articleShareVisible('childCascade', true)">
          <span>包含</span>
          <el-icon v-if="articleShare.childCascade">
            <Check />
          </el-icon>
        </div>
        <div class="concert-btn" @click="articleShareVisible('childCascade', false)">
          <span>不包含</span>
          <el-icon v-if="!articleShare.childCascade">
            <Check />
          </el-icon>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-if="articleShare.allowMultiVisible" :model-value="articleShare.allowMultiVisible" draggable title="二次分享"
      width="420px" :modal="false" custom-class="concert-dialog" @close="articleShareVisible('allowMultiVisible', false)">
      <template #header>
        <div @click="articleShareVisible('allowMultiVisible', false)" class="title">
          &lt; 二次分享
        </div>
      </template>
      <div class="offer-info">是否允许二次分享。</div>
      <div>
        <div class="concert-btn" @click="articleShareVisible('allowMulti', false)">
          <span>不允许</span>
          <el-icon v-if="!articleShare.allowMulti">
            <Check />
          </el-icon>
        </div>
        <div class="concert-btn" @click="articleShareVisible('allowMulti', true)">
          <span>允许</span>
          <el-icon v-if="articleShare.allowMulti">
            <Check />
          </el-icon>
        </div>
      </div>
    </el-dialog>
    <el-dialog :model-value="paragraphShare.Visible" title="分享段落记录" width="420px" :modal="false"
      :close-on-click-modal="false" custom-class="paragraph-dialog" @close="paragraphShareVisible('Visible', false)">
      <div class="offer-info">拿到链接的人可获得阅读权限</div>
      <div style="height: 45px">
        <el-input :value="paragraphShare.url" placeholder="" class="url-input" disabled>
        </el-input>
        <el-button class="url-btn" @click="copyBtn(paragraphShare.url)">复制链接
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DocumentShare",
  props: {
    articleShare: { type: Object, default: undefined },
    paragraphShare: { type: Object, default: undefined },
    refreshUrl: { type: Function, default: undefined },
  },
  data() {
    return {};
  },
  methods: {
    copyBtn(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$root.tips("success", "复制成功");
      });
    },
    articleShareVisible(key, enable) {
      let articleShare = this.articleShare;
      articleShare[key] = enable;
      this.$emit("update:articleShare", articleShare);
      if (!key.includes("Visible")) {
        this.$parent.refreshUrl();
      }
    },
    paragraphShareVisible(key, enable) {
      let articleShare = this.paragraphShare;
      articleShare[key] = enable;
      this.$emit("update:paragraphShare", articleShare);
    },
  },
};
</script>

<style scoped></style>
