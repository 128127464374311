<template>
  <div :class="['collapse-item', { active: show2 }]">
    <div class="collapse-title" @click="onShow">{{ list.title }}</div>
    <div @mouseover="mouseOver" @mouseleave="mouseLeave">
      <el-input ref="contentRef" v-if="edit" v-model="list.content" type="textarea" placeholder="请输入存量" autosize
        class="collapse-textarea" />
      <div :class="['collapse-content', { 'collapse-show': show }]" @click="onShow" @dblclick="onEdit" v-else>
        {{ list.content }}
      </div>
      <div :class="['collapse-time', { 'border-top': show2 }]">
        <div class="c-time">{{ list.updateTime }}</div>
        <div class="c-btn" v-if="show2 && !edit">
          <svg-icon icon-class="047" @click="toTransfer" />
          <el-icon @click="copyBtn">
            <CopyDocument />
          </el-icon>
          <el-icon @click="this.$emit('remove', list.id)">
            <Delete />
          </el-icon>
          <svg-icon icon-class="030" @click="onEdit" />
        </div>
        <div class="c-btn btn2" v-else-if="show2 && edit">
          <el-button type="primary" size="small" class="edit-btn" @click="onEdit">确认</el-button>
        </div>
        <div class="c-more" v-else>
          <el-icon @click="onShow">
            <MoreFilled />
          </el-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    keyNumber: { type: Number },
    data: {
      type: Object,
      default: () => ({
        id: "",
        insightType: 1,
        title: "",
        content: "",
        createTime: "",
        updateTime: "",
      }),
    },
  },
  data() {
    return {
      show: false,
      show2: false,
      edit: false,
      list: this.data,
      timeOut: null,
    };
  },
  inject: ["passTochatNav"],
  created() { },
  mounted() { },
  methods: {
    onShow() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        if (this.show) {
          this.edit = false;
        }
        this.show = !this.show;
      }, 300);
    },
    mouseOver() {
      this.show2 = true;
    },
    mouseLeave() {
      if (!this.edit) {
        this.show2 = false;
      }
    },
    onEdit() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.show = true;
      if (this.edit) {
        this.$emit("edit", this.list, this.keyNumber);
      } else {
        setTimeout(() => {
          this.$refs.contentRef.focus();
        }, 100);
      }
      this.edit = !this.edit;
    },
    toTransfer() {
      this.passTochatNav("transfer", this.list.content);
    },
    copyBtn() {
      var that = this;
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        navigator.clipboard.writeText(that.list.content).then(() => {
          that.$root.tips("success", "复制成功");
        });
      }, 300);
    },
  },
};
</script>
