<template>
  <div id="transfer" class="transfer">
    <div
      :class="['transfer-flex', { 'right-border': isWide }]"
      style="width: 280px"
    >
      <el-scrollbar class="transfer-scroll">
        <div class="transfer-sk">
          <div class="transfer-new">
            <el-button
              class="right-btn"
              size="small"
              @click="onExpandConceptual"
            >
              <el-tooltip
                effect="dark"
                :content="isShowConceptual ? '收起概念清洗' : '展开概念清洗'"
                placement="bottom"
              >
                <svg-icon icon-class="expand" />
              </el-tooltip>
            </el-button>
            <el-button class="right-btn" size="small" @click="onNew">
              <el-tooltip effect="dark" content="新中转" placement="bottom">
                <svg-icon icon-class="030" />
              </el-tooltip>
            </el-button>
            <el-button
              class="right-btn"
              size="small"
              @click="showHistory = true"
            >
              <el-tooltip effect="dark" content="历史记录" placement="bottom">
                <svg-icon icon-class="031" />
              </el-tooltip>
            </el-button>
            <el-button
              v-if="!type"
              class="right-btn"
              size="small"
              @click="openSetting"
            >
              <el-tooltip effect="dark" content="设置" placement="bottom">
                <svg-icon icon-class="032" />
              </el-tooltip>
            </el-button>
          </div>
          <div class="transfer-title first-title">经典句子</div>
          <div class="transfer-input" id="transferInputDom">
            <!-- <el-input
            ref="transferInputRef"
            v-model="transferInput"
            type="textarea"
            :rows="9"
            resize="none"
            placeholder="请输入你的存量..."
          /> -->
            <froala
              ref="transferInputRef"
              v-if="status"
              :tag="'textarea'"
              :config="config"
              v-model="transferInput"
            />
          </div>
        </div>
        <div
          class="transfer-box concept"
          v-if="Object.keys(concepts).length > 0 && isShowConceptual"
        >
          <div class="transfer-title">概念清洗</div>
          <div class="t-box">
            <div
              v-for="(item, key, index) in concepts"
              :key="index"
              class="t-clear"
            >
              <div class="title">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="item.title + '：'"
                  placement="top-start"
                >
                  {{ item.title + "：" }}
                </el-tooltip>
              </div>
              <div class="content">
                <el-input
                  autosize
                  maxlength="140"
                  resize="none"
                  type="textarea"
                  v-model="item.content"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isWide && (show0 || show1 || show2)" class="t-divider">
          <svg-icon icon-class="033" />
        </div>
        <div v-if="!isWide">
          <div class="transfer-box" v-if="show0">
            <transfer-dropdown
              ref="transferDropdownRef0"
              v-model:list="mindList0"
              :type="type"
              :isWide="isWide"
              @changeModel="changeModel"
              :close-btn="true"
              @close-box="closeTransferBox(0)"
            />
          </div>
          <div class="transfer-box" v-if="show1">
            <transfer-dropdown
              ref="transferDropdownRef1"
              v-model:list="mindList1"
              :type="type"
              :isWide="isWide"
              @changeModel="changeModel"
              :close-btn="true"
              @close-box="closeTransferBox(1)"
            />
          </div>
          <div class="transfer-box" v-if="show2">
            <transfer-dropdown
              ref="transferDropdownRef2"
              v-model:list="mindList2"
              :type="type"
              :isWide="isWide"
              @changeModel="changeModel"
              :close-btn="true"
              @close-box="closeTransferBox(2)"
            />
          </div>
          <div class="transfer-box">
            <div class="transfer-mind" style="height: 500px">
              <div class="transfer-md">
                <div class="transfer-k">思考关系</div>
                <div class="transfer-m">
                  <span @click="newMindmap" style="margin-right: 20px"
                    >新建</span
                  >
                  <span v-if="!type" @click="importMind">一键导入</span>
                </div>
              </div>
              <div class="transfer-map">
                <mind-graph
                  v-if="modelValue"
                  ref="map"
                  mode="edit"
                  theme="white"
                  :model-value="modelValue"
                  :isTransfer="true"
                  :mindmap="true"
                  :show-toolbar="true"
                  :resize-center="true"
                  :scale-ratio="0.8"
                  :sharp-corner="true"
                  :hide-edge="false"
                  :default-edge-style="this.$root.mindEdgeStyle"
                  :default-node-style="this.$root.mindNodeStyle"
                  @change="onMindMapChange"
                  :key="key"
                />
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div class="transfer-btn-box wide" v-if="isWide">
        <div class="btn-box" v-if="$root.config.addThinking === 'true'">
          <el-button class="transfer-btn" :disabled="disabled" @click="newMind">
            <svg-icon icon-class="018" />
            添加思考
          </el-button>
        </div>
      </div>
    </div>
    <div v-if="isWide" class="transfer-flex2" style="display: flex">
      <div
        v-if="show0 || show1 || show2"
        class="transfer-flex maxwidth"
        id="transferDropdownWide"
        style="height: 100%"
      >
        <el-scrollbar>
          <div class="transfer-box" v-if="show0">
            <transfer-dropdown
              ref="transferDropdownRef0"
              v-model:list="mindList0"
              :type="type"
              :isWide="isWide"
              @changeModel="changeModel"
              :close-btn="true"
              @close-box="closeTransferBox(0)"
            />
          </div>
          <div class="transfer-box" v-if="show1">
            <transfer-dropdown
              ref="transferDropdownRef1"
              v-model:list="mindList1"
              :type="type"
              :isWide="isWide"
              @changeModel="changeModel"
              :close-btn="true"
              @close-box="closeTransferBox(1)"
            />
          </div>
          <div class="transfer-box" v-if="show2">
            <transfer-dropdown
              ref="transferDropdownRef2"
              v-model:list="mindList2"
              :type="type"
              :isWide="isWide"
              @changeModel="changeModel"
              :close-btn="true"
              @close-box="closeTransferBox(2)"
            />
          </div>
          <div class="space"></div>
        </el-scrollbar>
        <div class="transfer-btn-box wide" v-if="false">
          <div class="btn-box">
            <el-button
              class="transfer-btn"
              :disabled="disabled"
              @click="openMind"
            >
              <svg-icon icon-class="new-transfer" />
              转成导图
            </el-button>
          </div>
        </div>
      </div>
      <div class="transfer-flex" style="height: 100%; margin-left: 6px">
        <div class="transfer-mind mind-wide">
          <div class="transfer-md">
            <div class="transfer-k">思考关系</div>
            <div class="transfer-m">
              <span @click="newMindmap" style="margin-right: 20px">新建</span>
              <span v-if="!type" @click="importMind">一键导入</span>
            </div>
          </div>
          <div class="transfer-map">
            <mind-graph
              v-if="modelValue"
              ref="map"
              mode="edit"
              theme="white"
              :model-value="modelValue"
              :isTransfer="true"
              :mindmap="true"
              :show-toolbar="true"
              :resize-center="true"
              :scale-ratio="0.8"
              :sharp-corner="true"
              :hide-edge="false"
              :default-edge-style="this.$root.mindEdgeStyle"
              :default-node-style="this.$root.mindNodeStyle"
              @change="onMindMapChange"
              :key="key"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isWide"
      class="transfer-btn-box"
      :style="{ position: type == 'newTab' ? 'fixed' : 'absolute' }"
    >
      <div class="btn-box" v-if="$root.config.addThinking === 'true'">
        <el-button class="transfer-btn" :disabled="disabled" @click="newMind">
          <svg-icon icon-class="018" />
          添加思考
        </el-button>
        <!-- <el-button class="transfer-btn" :disabled="disabled" @click="openMind">
          <svg-icon icon-class="new-transfer" />转成导图</el-button
        > -->
      </div>
    </div>
    <el-collapse-transition>
      <div class="history" v-show="showHistory">
        <div class="d-end title">
          <div class="d-center mind-title">历史记录</div>
          <div class="btn btn-font-gray d-center btn-close">
            <el-button
              class="mind-right-btn"
              size="small"
              @click="showHistory = false"
            >
              <el-icon>
                <CloseBold />
              </el-icon>
            </el-button>
          </div>
        </div>
        <div class="box">
          <div
            class="list"
            v-for="(item, index) in history"
            :key="index"
            @click="exportTransfer(item)"
          >
            <div class="input" v-html="item.transferInput"></div>
            <div class="link" v-html="item.transferInput"></div>
            <el-divider v-if="index !== 4" />
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <tree-dialog
      :is-local="false"
      :need-load-data="true"
      :group-enabled="true"
      :save-cur-group="true"
      :init-list="[
        {
          id: 'root',
          expand: true,
          hasChildren: true,
          title: '我的模型树',
          children: $root.cloudTreeList,
        },
      ]"
      :theme="$root.config.theme"
      :filter-item="{}"
      v-model:dialog-type="treeDialogType"
      @submit="importTransfers"
    />
  </div>
</template>
<script>
import transferDropdown from "./transferDropdown";
import FroalaEditor from "froala-editor";
import * as cheerio from "cheerio";
import { is } from "cheerio/lib/api/traversing";
import MindGraph from "../mind-map/MindGraph";
import { MindmapEvent } from "../../../../components/antv-mindmap/mxs-mindmap.es";
import TreeDialog from "../tree/TreeDialog";

var that;
export default {
  components: { transferDropdown, MindGraph, TreeDialog },
  props: {
    type: { type: String, default: "" },
    viewWidth: { type: [Number, String], default: 280 },
    ffType: { type: String, default: "right" },
  },
  data() {
    that = this;
    return {
      isShowConceptual: false,
      transfer: {
        main: "",
        link: "",
        model: "",
      },
      transferInput: "",
      disabled: false,
      show0: false,
      show1: false,
      show2: false,
      history: [],
      showHistory: false,
      status: false,
      editor: null,
      config: {
        theme: "dark",
        language: "zh_cn",
        placeholderText: "请输入你的存量...",
        pastePlain: true,
        imageUpload: false,
        imagePaste: false,
        toolbarInline: true,
        toolbarContainer: null,
        toolbarBottom: false,
        attribution: false,
        tooltips: true,
        charCounterCount: false,
        autofocus: false,
        fontSizeUnit: "px",
        toolbarButtons: ["conceptCleaning", "clearFormat2"],
        events: {
          initialized: function () {
            that.editor = this;
          },
          // "commands.after": function (cmd, param1, param2) {
          //   if (cmd === "clearFormatting") {
          //     that.getConcepts();
          //   }
          // },
          contentChanged: function () {
            // that.getConcepts();
          },
        },
      },
      textColor: "#de4e4e",
      textColorBg: null,
      textBgColor: "#3c373b",
      textBgBgColor: "#f4e6b9",
      concepts: {},
      conceptID: 1,
      isWide: false,
      modelValue: {
        id: "000",
        title: "新建模型",
        parentId: null,
        children: [],
      },
      key: 0,
      treeDialogType: 0,
      mindList0: {
        id: "000",
        title: "",
        parentId: null,
        children: [
          {
            id: "001",
            title: "",
            parentId: "000",
            children: [
              {
                id: "002",
                title: "",
                parentId: "001",
                children: [],
              },
            ],
          },
        ],
      },
      mindList1: {
        id: "000",
        title: "",
        parentId: null,
        children: [
          {
            id: "001",
            title: "",
            parentId: "000",
            children: [
              {
                id: "002",
                title: "",
                parentId: "001",
                children: [],
              },
            ],
          },
        ],
      },
      mindList2: {
        id: "000",
        title: "",
        parentId: null,
        children: [
          {
            id: "001",
            title: "",
            parentId: "000",
            children: [
              {
                id: "002",
                title: "",
                parentId: "001",
                children: [],
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    viewWidth(val, old) {
      this.changeWide(val);
    },
    ffType(val, old) {
      if (val === "full") {
        this.isWide = true;
        // document.getElementsByClassName("chat-box")[0].style.height = "340px";
      } else {
        this.isWide = false;
      }
    },
  },
  inject: ["transferNewTreeItem"],
  mounted() {
    that = this;
    if (this.$root.config.textColor) {
      this.textColor = this.$root.config.textColor;
      this.textColorBg = this.$root.config.textColorBg;
      this.textBgColor = this.$root.config.textBgColor;
      this.textBgBgColor = this.$root.config.textBgBgColor;
    }
    FroalaEditor.RegisterCommand("conceptCleaning", {
      title: "概念标记",
      icon: '<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="2" fill="#F4E6B9"/><path d="M7.3339 3.004H8.6639L12.5979 13H11.3519L10.2879 10.2H5.6959L4.6319 13H3.3999L7.3339 3.004ZM6.0599 9.234H9.9239L8.0339 4.236H7.9779L6.0599 9.234Z" style="fill:#3c373b" /></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function (res) {
        this.format.apply("span", {
          class: "concept",
          id: "concept" + that.conceptID,
        });
        this.format.applyStyle("color", that.textBgColor);
        this.format.applyStyle("background-color", that.textBgBgColor);
        this.format.applyStyle("border-radius", "4px");
        that.conceptID += 1;
        setTimeout(() => {
          that.getConcepts();
        }, 100);
      },
    });
    FroalaEditor.RegisterCommand("clearFormat2", {
      title: "清除格式",
      icon: '<svg width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M554.3 850.7L981 424c12.5-12.5 12.5-32.8 0-45.3L636.7 34.6c-12.5-12.5-32.8-12.5-45.3 0L90.5 535.5c-50 50-50 131 0 181l272.1 270.3c24 23.8 56.4 37.2 90.2 37.2H864c17.7 0 32-14.3 32-32s-14.3-32-32-32H599.5c-57 0-85.6-68.9-45.2-109.3z m71.1-736.9l276.4 276.4c6.2 6.2 6.2 16.4 0 22.6L607.9 706.6l-299-299 293.8-293.9c6.3-6.2 16.4-6.2 22.7 0.1zM135.8 580.7l127.9-127.9 299 299-127.9 127.9c-25 25-65.5 25-90.5 0L135.8 671.3c-25-25-25-65.6 0-90.6z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function (res) {
        this.commands.clearFormatting();
        // this.format.apply("span", {
        //   class: "concept",
        //   id: "concept" + that.conceptID,
        // });
        // this.format.applyStyle("color", that.textBgColor);
        // this.format.applyStyle("background-color", that.textBgBgColor);
        // this.format.applyStyle("border-radius", "4px");
        // that.conceptID += 1;
        setTimeout(() => {
          that.getConcepts();
        }, 100);
      },
    });
    // FroalaEditor.RegisterShortcut(49, "conceptCleaning", "", "1", false);
    this.config.theme =
      this.$root.config.theme === "default" ? "default" : "dark";
    setTimeout(() => {
      this.status = true;
      if (this.ffType === "right") {
        this.isWide = false;
      }
      if (this.type === "newTab") {
        this.isWide = true;
      }
    }, 10);
    this.getHistory();
  },
  methods: {
    openMind() {
      if (this.show0 == true) {
        this.$refs.transferDropdownRef0.openMind();
      }
      if (this.show1 == true) {
        this.$refs.transferDropdownRef1.openMind();
      }
      if (this.show2 == true) {
        this.$refs.transferDropdownRef2.openMind();
      }
    },
    newMind() {
      if (this.show0 == false) {
        this.show0 = true;
      } else if (this.show1 == false) {
        this.show1 = true;
      } else if (this.show2 == false) {
        this.show2 = true;
      }
    },
    changeInput(data) {
      this.transferInput = data;
    },
    closeTransferBox(type) {
      switch (type) {
        case 0:
          this.show0 = false;
          break;
        case 1:
          this.show1 = false;
          break;
        case 2:
          this.show2 = false;
          break;
        default:
          break;
      }
    },
    onExpandConceptual() {
      this.isShowConceptual = !this.isShowConceptual;
    },
    async onNew() {
      let history = await this.getTransferHistory();
      if (this.transferInput === "") {
        return;
      }
      let transfer = [];
      if (this.show0) {
        transfer.push(this.$refs.transferDropdownRef0.mindList);
      }
      if (this.show1) {
        transfer.push(this.$refs.transferDropdownRef1.mindList);
      }
      if (this.show2) {
        transfer.push(this.$refs.transferDropdownRef2.mindList);
      }
      let data = {
        transferInput: this.transferInput,
        transfer: transfer,
        concepts: this.concepts,
      };
      if (history && history.length > 0) {
        if (history.length <= 4) {
          history.push(data);
        } else {
          history.shift();
          history.push(data);
        }
      } else {
        history = [data];
      }
      history = JSON.stringify(history);
      history = JSON.parse(history);
      this.history = history;
      this.$localForage.setItem("transferHistory", history);
      this.transferInput = "";
      this.concepts = {};
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
    },
    async getHistory() {
      this.history = await this.getTransferHistory();
    },
    async getTransferHistory() {
      let value = await this.$localForage.getItem("transferHistory");
      return value;
    },
    exportTransfer(item) {
      this.transferInput = item.transferInput;
      this.concepts = item.concepts;
      for (let i = 0; i < item.transfer.length; i++) {
        this["show" + i] = true;
        setTimeout(() => {
          this.$refs["transferDropdownRef" + i].mindList.title =
            item.transfer[i].title;
          this.$refs["transferDropdownRef" + i].mindList.children[0].title =
            item.transfer[i].children[0].title;
          this.$refs[
            "transferDropdownRef" + i
          ].mindList.children[0].children[0].title =
            item.transfer[i].children[0].children[0].title;
        }, 100);
      }
      this.showHistory = false;
    },
    openSetting() {
      this.$root.configActive = "transfer";
      this.$root.configVisible = true;
    },
    getConcepts() {
      const $ = cheerio.load(this.editor.html.get());
      var concepts = {};
      if ($("span.concept").length > 0) {
        for (let i = 0; i < $("span.concept").length; i++) {
          concepts[$("span.concept")[i].attribs.id] = {
            title: $("span.concept")[i].children[0].data,
            content: "",
          };
        }
        Object.keys(concepts).forEach((key) => {
          if (this.concepts && this.concepts[key]) {
            concepts[key].content = this.concepts[key].content;
          }
        });
        this.concepts = concepts;
      } else {
        this.concepts = {};
      }
    },
    changeWide(width) {
      if (width >= 600) {
        this.isWide = true;
      } else {
        this.isWide = false;
      }
    },
    importMind() {
      this.treeDialogType = 5;
    },
    async importTransfers(item, group) {
      // console.log(group);
      await this.$refs.map.graph._editor.blur();
      let model = this.$refs.map.graph.findById("000").getModel();
      let data = await this.transferNewTreeItem(
        item.id && item.id !== "root" ? item : undefined,
        model.title
      );
      await this.findChild(model.children, data);
      this.$root.tips("success", "导入成功");
      if (group) {
        await this.$root.addGroupItem(group.id, data.id);
        this.$root.curGroup = group;
      }
      setTimeout(async () => {
        if (data) {
          this.$root.setSelectItem(data);
        }
        this.$emit("close", false);
      }, 500);
    },
    async findChild(children, parentItem) {
      for (let i in children) {
        const data = children[i];
        const item = await this.transferNewTreeItem(parentItem, data.title);
        if (data.children.length > 0) {
          await this.findChild(data.children, item);
        }
      }
    },
    onMindMapChange({ type, options }) {
      switch (type) {
        case MindmapEvent.nodeAdd:
          this.onAdd(options);
          break;
        default:
          break;
      }
    },
    onAdd({ node }) {
      this.$refs.map.graph.editAddNode(node, { id: this.GenNonDuplicateID() });
    },
    GenNonDuplicateID() {
      let idStr = Date.now().toString(36);
      idStr += Math.random().toString(36).slice(3);
      return idStr;
    },
    changeModel(mindList) {
      this.$root.showConfirm({
        title: "确认覆盖",
        subtitle: "是否覆盖掉当前自由导图？（无法恢复）",
        onConfirm: () => {
          this.key += 1;
          this.modelValue = mindList;
        },
      });
    },
    newMindmap() {
      var mindList = {
        id: "000",
        title: "",
        parentId: null,
        children: [],
      };
      this.changeModel(mindList);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .action-bar {
  display: none !important;
}

:deep .fr-box {
  color: var(--color-white);
  background-color: var(--color-chat-box-fbg);
  border-radius: 0 0 8px 8px;
  height: 200px;
  padding: 2px 8px;
}

.transfer-input {
  overflow: hidden;

  :deep .fr-wrapper {
    overflow: auto;
  }

  :deep .fr-wrapper > .fr-element {
    padding-bottom: 10px !important;
  }
}

:deep .fr-wrapper,
:deep .fr-element {
  height: 100%;
}

:deep .fr-element p {
  font-size: 14px;
  margin-block-start: 0;
  margin-block-end: 0.6em;
}

.mind-title {
  .btn {
    position: unset;
  }

  .btn:hover {
    background-color: unset;
  }
}
.transfer-scroll {
  height: calc(100% - 70px);
}
</style>
