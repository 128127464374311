<template>
  <el-breadcrumb separator="〉" class="right-breadcrumb">
    <el-breadcrumb-item>
      <svg-icon icon-class="tree_b" class-name="tree_b"></svg-icon>
      我的模型树
    </el-breadcrumb-item>
    <template v-for="(item, idx) of list">
      <el-breadcrumb-item  v-if="(list.length>maxLength && idx < maxLength-2) || (list.length<=maxLength&&idx < list.length-1)"
                           :key="item.id">
        <div class="over-content" @click="onChange(item)">
          {{ item.title }}
        </div>
      </el-breadcrumb-item>
    </template>
    <el-breadcrumb-item v-if="list.length>maxLength">
      <div class="over-content">
        <el-dropdown>
          <span class="el-dropdown-link">
            ...
            <el-icon class="el-icon--right">
            <ArrowDown/>
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <template v-for="(item, idx) of list">
                <el-check-tag
                    v-if="idx>=maxLength-2 && idx<list.length-1"
                    :key="item.id"
                    @change="onChange(item)"
                    class="tree-tag">
                  {{ item.title }}
                </el-check-tag>
              </template>

            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-breadcrumb-item>
    <el-breadcrumb-item v-if="list.length>0">
      <div class="over-content">
        {{ list[list.length-1].title }}
      </div>
    </el-breadcrumb-item>

    <el-breadcrumb-item v-if="midType === 'paragraphHistory'">段落记录</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  props: {
    activeItem: {type: Object, default: null},
    midType: {type: String, default: ''}
  },
  watch: {
    'activeItem.parent': {
      handler(val, old) {
        if ((!old || !val) || (val.id !== old.id || val.parent !== old.parent)) {
          this.reset();
        }
      }
    },
    activeItem(val, old) {
      if ((!old && val) || !val || val.id !== old.id || val.parent !== old.parent) {
        this.reset();
      }
    }
  },
  data() {
    return {
      list: [],
      maxLength: 3
    }
  },
  methods: {
    reset() {
      let item = this.activeItem, list = [];
      while (item) {
        list.unshift(item);
        item = item.parent;
      }
      this.list = list;
    },
    onChange(item) {
      this.$emit('onChange', item);
    }
  }
}

</script>
