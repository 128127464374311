import base from "../../../../api/base";

export const defaultFroalaConfig = {
  // height: "calc(100% - 150px)",
  theme: "dark",
  language: "zh_cn",
  placeholderText: "请输入存量",
  requestHeaders: {
    "Content-Type": "application/json; charset=utf-8",
    mindmap_token: localStorage.getItem("mindmap_token"),
    mindmap_device: localStorage.getItem("mindmap_device"),
  },
  // 将内容粘贴到富文本编辑器时删除文本格式，但保留内容的结构。
  pastePlain: true,
  // 排除项
  // pasteAllowedStyleProps: ["color", "font-weight", "background-color"],
  // 将某些内容粘贴到富文本编辑器中时与其内容一起被删除的标签。
  // pasteDeniedTags: ['a', 'i'],
  // 将某些内容粘贴到富文本编辑器中时删除的属性。
  // pasteDeniedAttrs: ["class", "id", "style"],
  // 更改保存间隔（时间以毫秒为单位）。
  saveInterval: 0,
  // 设置保存参数。
  saveParam: "content",
  // 设置保存地址。
  saveURL: `${base.baseURL}/api/Article/UpdateContent`,
  // HTTP 请求类型。
  saveMethod: "POST",
  // 额外的保存参数。
  saveParams: {
    articleId: "",
    // topArticleId: null,
    // articleParagraphModels: null,
  },

  imageUpload: false,

  // 允许从剪贴板粘贴图像。
  imagePaste: true,
  // imageMaxSize: 1024 * 1024 * 2,
  // imageAllowedTypes: ["jpeg", "jpg", "png"],
  // imageAddNewLine: false,
  // imageDefaultAlign: "left",

  // false 工具栏顶部显示 true 跟随选中文字
  toolbarInline: true,
  // 不选中文字也显示工具栏
  // toolbarVisibleWithoutSelection: true,
  // 将工具栏放置在其中的自定义 HTML 选择器。
  // toolbarContainer: "#toolbarContainer",
  toolbarContainer: null,

  // 工具栏底部显示
  toolbarBottom: false,
  // 工具栏偏移量
  // toolbarStickyOffset: 50,

  //powerd by
  attribution: false,
  // 工具栏按钮是否显示提示
  tooltips: true,
  charCounterCount: false,
  autofocus: false,
  fontSizeDefaultSelection: 36,
  fontSizeUnit: "px",
  // 保持编辑器内的 HTML 不变，无需对其进行任何特殊处理，除了 HTML 清理。
  htmlUntouched: true,
  // htmlAllowedTags: [".*"],
  // htmlAllowedAttrs: [".*"],
  paragraphFormat: {
    N: "Normal",
    H1: "Heading 1",
    H2: "Heading 2",
    H3: "Heading 3",
    H4: "Heading 4",
  },
  fontSize: [
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "24",
    "30",
    "36",
    "42",
    "48",
    "60",
    "72",
    "96",
  ],
  fontFamily: {
    "Arial,Helvetica,sans-serif": "Arial",
    "Georgia,serif": "Georgia",
    "Impact,Charcoal,sans-serif": "Impact",
    "Tahoma,Geneva,sans-serif": "Tahoma",
    "'Times New Roman',Times,serif": "Times New Roman",
    "Verdana,Geneva,sans-serif": "Verdana",
    SimSun: "宋体",
    SimHei: "黑体",
    "Microsoft Yahei": "微软雅黑",
    "Microsoft JhengHei": "微软正黑体",
    KaiTi: "楷体",
    NSimSun: "新宋体",
    FangSong: "仿宋",
  },
  toolbarButtons: [
    "bold",
    "btn2",
    "btn1",
    // "btn3",
    "paragraphFormat",
    "italic",
    "strikeThrough",
    "clearFormatting",
    // "PDF",
    "ttips",
    "ttips2",
    "timage",
    "tmath",
    // "insertImage",
    // 引用
    // "tips",
    // 粘贴模式
    // "PastePlain",
    // 工具栏样式
    // "toolTop",
    // "toolInline",
    // "toolBottom",
    "lineHeight",
    "segmentSpacing",
    "setting",
  ],
  // imageInsertButtons: ["imageUpload", "imageByURL"],
  // quickInsertButtons: ["qimage", "qtips"],
  imageEditButtons: [
    "imageZoom",
    "imageDisplay",
    "imageAlign",
    "imageSize",
    "imageLink",
    "imageDown",
    "imageRemove",
  ],
  shortcutsEnabled: [
    "bold",
    "btn2",
    "btn1",
    "paragraphFormat",
    "italic",
    "fontFamily",
    "fontSize",
    "textColor",
    "backgroundColor",
    "clearFormatting",
    "ttips",
    "timage",
    "tmath",
    "indent",
    "lineHeight",
    "segmentSpacing",
    "formatOL",
    "formatUL",
    "insertHR",
    "insertLink",
    "undo",
    "redo",
    "cut",
    "copy",
  ],
};
