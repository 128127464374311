<template>
  <div
    v-show="midType !== 'recyclebin'"
    :class="[
      'nav-header',
      'pos-relative',
      { 'no-border': this.$root.config.theme === 'dark5' },
    ]"
  >
    <div class="d-center">
      <div
        class="d-flex"
        v-if="!showLeft && navbarButtons.logo"
        style="margin: 0 4px 0 13px"
      >
        <svg-icon
          :icon-class="$root.config.theme.includes('dark') ? 'new-logo1b' : 'new-logo1'"
          class-name="svg-20"
        />
      </div>
      <el-divider direction="vertical" v-if="!showLeft && navbarButtons.logo" />
      <!-- <el-button
        class="tree-expand"
        size="small"
        v-if="!showLeft"
        @click="onShowLeft"
        :style="navbarButtons.logo ? '' : 'margin-left:20px;'"
      >
        <svg-icon icon-class="new-left" class-name="svg-small" />
      </el-button> -->
      <el-tooltip
        effect="dark"
        :content="isWin ? '切换 Ctrl + K' : '切换 ⌘ + K'"
        placement="bottom"
      >
        <div class="d-center mind-editor-change">
          <div
            class="btn btn-gray d-center btn-box"
            :class="{ active: isShowMindMap() }"
            @click="openMind"
          >
            <svg-icon icon-class="new-11" class-name="svg-small" />
          </div>
          <div
            class="btn btn-gray d-center btn-box"
            :class="{ active: isShowMDEditor() && !isShowMindMap() }"
            @click="onCloseMindMap"
          >
            <svg-icon icon-class="file" class-name="svg-small" />
          </div>
        </div>
      </el-tooltip>
      <!--      <div-->
      <!--        class="d-flex"-->
      <!--        v-if="!showLeft && navbarButtons.logo"-->
      <!--        style="margin: 0 4px 0 13px"-->
      <!--      >-->
      <!--        <svg-icon-->
      <!--          :icon-class="-->
      <!--            $root.config.theme.includes('dark') ? 'new-logo1b' : 'new-logo1'-->
      <!--          "-->
      <!--          class-name="svg-20"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <el-divider direction="vertical" v-if="!showLeft && navbarButtons.logo" />-->

      <!-- <el-icon v-if="showBack" @click="onBack">
        <ArrowLeftBold />
      </el-icon> -->
      <!-- <div class="btn btn-gray d-center open-map" @click="onMap">
        <svg-icon v-if="showMap" icon-class="011" class-name="map" />
        <svg-icon v-else icon-class="005" class-name="editor" />
        {{ showMap ? "折叠导图" : "模型导图" }}
      </div> -->
      <div
        class="btn btn-gray d-center change-cross"
        v-if="showMap && false"
        :class="{ 'cross-c': crossDirection === 'column' }"
        size="small"
        @click="onChangeCross"
      >
        <!-- 隐藏功能 -->
        <svg-icon icon-class="tree-fold" />
      </div>
      <span class="state-text">{{ stateText }}</span>
    </div>
    <div class="d-center">
      <!-- <message-box></message-box> -->
      <el-tooltip
        v-if="navbarButtons.save"
        :effect="effect"
        content="保存"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box" @click="onSave">
          <svg-icon icon-class="025" class-name="svg-small" />
        </div>
      </el-tooltip>
      <el-tooltip
        v-if="$root.config.lock && $root.config.lock.status === 'true'"
        :effect="effect"
        content="锁屏"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box" @click="onLock">
          <el-icon>
            <Lock />
          </el-icon>
        </div>
      </el-tooltip>
      <!--      <el-tooltip-->
      <!--        v-if="showShare && navbarButtons.share"-->
      <!--        :effect="effect"-->
      <!--        content="分享模型"-->
      <!--        placement="bottom"-->
      <!--      >-->
      <!--        <div class="btn btn-gray d-center btn-box" @click="onShare">-->
      <!--          <svg-icon icon-class="new-19" class="size-2"></svg-icon>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <el-tooltip
        v-if="false && showMap"
        :effect="effect"
        content="双链导图"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box" @click="onMindShowEdges">
          <svg-icon icon-class="020" class-name="svg-small" />
        </div>
      </el-tooltip>
      <el-tooltip
        v-if="navbarButtons.fullScreen"
        :effect="effect"
        content="全屏模式"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box" @click="onFullScreen">
          <svg-icon
            :icon-class="isFullscreen ? 'new-50' : 'new-58'"
            class="size-2"
          ></svg-icon>
        </div>
      </el-tooltip>
      <div
        class="btn btn-gray d-center btn-box"
        v-if="
          showShortcutMenu &&
          showChatGpt &&
          $root.config.shortcutMenu.flashThought === 'true'
        "
        @click="openChatGpt('flashThought')"
      >
        <svg-icon icon-class="010" class-name="svg-small" />
      </div>
      <el-tooltip
        v-if="
          showShortcutMenu && showChatGpt && $root.config.shortcutMenu.chatgpt === 'true'
        "
        :effect="effect"
        content="AI 助手"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box" @click="openChatGpt('ai')">
          <svg-icon icon-class="007" class-name="svg-small" />
        </div>
      </el-tooltip>
      <el-tooltip
        v-if="showShortcutMenu && showChatGpt"
        :effect="effect"
        content="中转站"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box" @click="openChatGpt('transfer')">
          <svg-icon
            :icon-class="$root.config.theme.includes('dark') ? '019_b' : '019'"
            class-name="svg-small"
          />
        </div>
      </el-tooltip>
      <el-tooltip
        v-if="navbarButtons.setting"
        :effect="effect"
        content="设置"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box" @click="openConfig('bindAccount')">
          <svg-icon icon-class="new-17" class="size-2"></svg-icon>
        </div>
      </el-tooltip>
      <el-dropdown
        :hide-on-click="true"
        :popper-class="[
          $root.config.theme,
          $root.config.themeBody,
          { 'right-menu-frame': $root.config.frame === 'true' },
        ]"
        style="margin-right: 14px"
      >
        <!-- v-if="showMore" -->
        <div class="btn btn-gray d-center btn-box">
          <el-icon>
            <MoreFilled />
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="drop-set">
            <!-- <el-dropdown-item>
              <div
                class="document-elm-menu d-flex"
                @click.stop="openConfig('tree')"
              >
                <svg-icon icon-class="new-62" class="size-1 mr-2"></svg-icon>
                <span>外观设置</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="document-elm-menu d-flex" @click.stop="goToHome">
                <svg-icon icon-class="new-63" class="size-1 mr-2"></svg-icon>
                <span>模型树官网</span>
              </div>
            </el-dropdown-item> -->
            <el-dropdown-item>
              <div class="document-elm-menu d-flex" @click.stop="onOpenHistory">
                <svg-icon icon-class="new-59" class="size-1 mr-2"></svg-icon>
                <span>历史记录</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="document-elm-menu d-flex" @click.stop="openHelp">
                <svg-icon icon-class="new-54" class="size-1 mr-2"></svg-icon>
                <span>帮助文档</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="document-elm-menu d-flex" @click.stop="logVisible = true">
                <svg-icon icon-class="new-55" class="size-1 mr-2"></svg-icon>
                <span>上传日志</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="document-elm-menu d-flex" @click.stop="goToOld">
                <svg-icon icon-class="new-60" class="size-1 mr-2"></svg-icon>
                <span>切换旧版</span>
              </div>
            </el-dropdown-item>
            <!-- <el-dropdown-item v-if="true">
              <div class="document-elm-menu d-flex" @click.stop="goToEditor">
                <svg-icon icon-class="new-60" class="size-1 mr-2"></svg-icon>
                <span>{{ isTest ? "旧版编辑器" : "新版编辑器" }}</span>
              </div>
            </el-dropdown-item> -->
            <el-dropdown-item v-if="false">
              <div
                class="document-elm-menu d-flex"
                @click.stop="openChatGpt('hotkeyDrawer')"
              >
                <svg-icon icon-class="new-68" class="size-1 mr-2"></svg-icon>
                <span>效率指南</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="document-elm-menu d-flex" @click.stop="openConfig()">
                <svg-icon icon-class="new-17" class="size-1 mr-2"></svg-icon>
                <span>系统设置</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="document-elm-menu d-flex" @click.stop="onLoginOut">
                <svg-icon icon-class="new-64" class="size-1 mr-2"></svg-icon>
                <span>退出登录</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <div class="column-line" v-if="!showDocument && showMap"></div>
      <el-tooltip
        v-if="!isShowMDEditor()"
        :effect="effect"
        content="文档模式"
        placement="bottom"
      >
        <div class="btn btn-gray d-center btn-box open-doc" @click="onMap">
          <svg-icon icon-class="slider-close" class="size-2"></svg-icon>
        </div>
      </el-tooltip> -->
      <!--      <el-tooltip-->
      <!--          v-else-if="isShowMindMap()"-->
      <!--          :effect="effect"-->
      <!--          content="关闭导图"-->
      <!--          placement="bottom"-->
      <!--      >-->
      <!--        <div class="btn btn-gray d-center btn-box open-doc" @click="closeRightEditor">-->
      <!--          <svg-icon icon-class="slider-close" class="size-2"></svg-icon>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->

      <div class="d-center">
        <div class="d-center mind-editor-change" style="margin: 0 20px 0 -10px">
          <div
            class="btn btn-gray d-center btn-box"
            @click="qaVisible = true"
            style="width: 60px; font-size: 12px"
          >
            问题反馈
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog
    v-model="qaVisible"
    custom-class="tree-dialog-question"
    title="问题反馈，进群反馈问题即可"
    @close="qaVisible = false"
    width="400px"
    center
  >
    <div>
      <el-image
        src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/public/qa-wechat.jpg"
        class="qa-img"
      ></el-image>
    </div>
  </el-dialog>
  <el-dialog
    v-model="logVisible"
    custom-class="tree-dialog-question"
    title="上传日志"
    @close="logVisible = false"
  >
    <div>
      为了帮助我们提高产品的质量和性能，您可以将日志信息（如浏览器品牌、版本、内存使用量信息和编辑操作信息等）以匿名的方式收集并提交给模型树。我们将根据我们的隐私政策和服务条款使用您提供的信息。
    </div>
    <br />
    <div>是否确定上传日志信息？</div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="close" @click="logVisible = false">取消</el-button>
        <el-button type="primary" @click="uploadLog">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import SvgIcon from "../../../../svgIcon/index.vue";
import { proxyApi } from "../../../../workers/ProxyApi";
import screenfull from "screenfull";
import { isWin } from "../../../../components/antv-mindmap/mxs-mindmap.es";
import MessageBox from "./MessageBox.vue";
export default {
  name: "NavHeader",
  components: { SvgIcon, MessageBox },
  inject: [
    "isShowMDEditor",
    "isShowMindMap",
    "closeMindMap",
    "openCurMindMap",
    "closeEditor",
  ],
  props: {
    midType: { type: String, default: "vditor" },
    stateText: { type: String, default: "" },
    showShare: { type: Boolean, default: true },
    showChatGpt: { type: Boolean, default: false },
    showLeft: { type: Boolean, default: false },
    showMap: { type: Boolean, default: false },
    showBack: { type: Boolean, default: false },
    showMore: { type: Boolean, default: true },
    showDocument: { type: Boolean, default: false },
    crossDirection: { type: String, default: "row" },
    navbarButtons: { type: Object, default: undefined },
  },
  data() {
    return {
      // isTest: localStorage.getItem("__test") === "1",
      isTest: true,
      effect: "dark",
      logVisible: false,
      isFullscreen: false,
      showEditor: false,
      isWin: false,
      qaVisible: false,
    };
  },
  computed: {
    showShortcutMenu: {
      get() {
        if (
          this.$root.config.shortcutMenu &&
          this.$root.config.shortcutMenu.location === "top"
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  mounted() {
    screenfull.on("change", this.onChangeFullScreen);
    this.isWin = isWin;
  },
  unmounted() {
    screenfull.off("change", this.onChangeFullScreen);
  },
  methods: {
    closeRightEditor() {
      this.closeEditor();
    },
    openMind() {
      // if (!this.showEditor) {
      //   this.closeEditor();
      // }
      this.openCurMindMap(true);
    },
    onChangeFullScreen() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    onBack() {
      this.$emit("back");
    },
    onShare() {
      this.$emit("share");
    },
    onFullScreen() {
      this.$emit("full-screen");
    },
    onOpenHistory() {
      this.$emit("history");
    },
    openChatGpt(type) {
      this.$emit("chat-gpt", type);
    },
    onShowLeft() {
      this.$emit("update:showLeft", !this.showLeft);
    },
    onMap() {
      this.$emit("map");
    },
    onChangeCross() {
      this.$emit(
        "update:crossDirection",
        this.crossDirection === "row" ? "column" : "row"
      );
    },
    onSave() {
      this.$emit("save-editor");
      setTimeout(() => {
        this.$root.tips("success", "保存成功");
      }, 100);
    },
    onMindShowEdges() {
      this.$emit("mind-show-edges");
    },
    onLock() {
      this.$store.commit("updatelockTime", localStorage.getItem("lockedTime"));
    },
    changeShowShortcutMenu() {
      if (
        this.$root.config.shortcutMenu &&
        this.$root.config.shortcutMenu.location === "top"
      ) {
        this.showShortcutMenu = true;
      } else {
        this.showShortcutMenu = false;
      }
    },
    openHelp() {
      let routeUrl = this.$router.resolve({
        path: "/share/article/648832ec9107eace111df4c3",
      });
      window.open(routeUrl.href, "_blank");
    },
    goToEditor() {
      const p = localStorage.getItem("__test");
      localStorage.setItem("__test", p === "1" ? "0" : "1");
      window.location.reload();
    },
    goToOld() {
      window.open("https://old.moxingshu.cn", "_blank");
    },
    goToHome() {
      window.open("https://www.moxingshu.cn", "_blank");
    },
    async uploadLog() {
      this.$root.tips("success", "上传日志成功！感谢您的配合和帮助");
      this.logVisible = false;
      let data = await proxyApi.logSubmit();
    },
    openConfig(configActive) {
      this.$root.configKey += 1;
      this.$root.configActive = configActive;
      this.$root.configVisible = true;
    },
    async onLoginOut() {
      this.$root.logout();
      this.$root.configVisible = false;
    },
    onCloseMindMap() {
      if (this.showDocument) {
        this.showEditor = true;
      } else {
        this.showEditor = false;
      }
      this.closeMindMap();
    },
    onChangeHotkeyDrawer() {
      this.$emit("hotkey-drawer");
    },
  },
};
</script>

<style lang="scss">
.nav-header {
  .btn {
    &:hover {
      color: var(--mxs-h-editor-active-color);
      background-color: var(--mxs-h-editor-hover);
      // opacity: 0.8;
    }
    &.active {
      color: var(--mxs-h-editor-active-color);
      background-color: var(--mxs-h-editor-active);
    }
  }
}
.tree-dialog-question {
  --el-text-color-primary: #fff;
  background: #181818 !important;
  .el-dialog__headerbtn {
    z-index: 999;
  }
}
.qa-img {
  width: 100%;
}
</style>
