<template>
  <el-menu
    v-if="selectNode && menuButtons"
    class="tree-menus"
    :class="[theme, 'right-menu']"
  >
    <el-menu-item
      v-if="menuButtons.append"
      index="1"
      @click="onClick($event, 'new')"
    >
      <svg-icon icon-class="new-10" class="menu-icon mr-2"></svg-icon>
      <span>新建模型</span>
      <span class="menu-hot-key">{{
        shortcutKeys.find((a) => a.key === "new-item").shortcutText
      }}</span>
    </el-menu-item>
    <el-menu-item
      v-if="menuButtons.changeMindMap"
      index="2"
      @click="onClick($event, 'map')"
    >
      <svg-icon icon-class="new-11" class="menu-icon mr-2"></svg-icon>
      <span>思维导图</span>
      <span class="menu-hot-key">{{
        shortcutKeys.find((a) => a.key === "open-mind").shortcutText
      }}</span>
    </el-menu-item>
    <el-menu-item v-if="sameMap" index="2" @click="onClick($event, 'sameMap')">
      <svg-icon icon-class="new-18" class="menu-icon mr-2"></svg-icon>
      <span>双开导图</span>
    </el-menu-item>
    <!--    <el-menu-item v-else-if="isDoubleMap(selectNode.item.id)" index="2" @click="onClick($event, 'closeMap')">-->
    <!--      <svg-icon icon-class="new-18" class="menu-icon mr-2"></svg-icon>-->
    <!--      <span>关闭导图</span>-->
    <!--    </el-menu-item>-->
    <li
      v-if="menuButtons.rename || menuButtons.newTab"
      class="el-dropdown-menu__item--divided"
    ></li>
    <el-menu-item v-if="menuButtons.newTab" index="26" @click="onNewTab">
      <svg-icon icon-class="new-12" class="menu-icon mr-2"></svg-icon>
      <span>新开网页</span>
    </el-menu-item>
    <el-menu-item
      v-if="menuButtons.rename"
      index="3"
      @click="onClick($event, 'rename')"
    >
      <svg-icon icon-class="new-13" class="menu-icon mr-2"></svg-icon>
      <span>重新命名</span>
    </el-menu-item>
    <div v-if="!selectNode.item.parent">
      <el-menu-item v-if="menuButtons.group" index="3" class="menu-drop">
        <div class="d-flex menu-drop-inner" @click="onClick($event, 'group')">
          <svg-icon icon-class="new-14" class="menu-icon mr-2"></svg-icon>
          <span>模型分组</span>
          <el-icon class="pos-right">
            <ArrowRight />
          </el-icon>
        </div>

        <el-menu
          ref="groupList"
          class="tree-menus right-menu"
          :class="[theme, 'menu-group']"
          v-if="showGroup"
        >
          <div
            class="checkbox-group-btn"
            v-for="(item, index) in $root.groups"
            :key="index"
          >
            <div
              class="check-inner d-flex"
              :class="{ active: item.checked }"
              @click="onChangeGroup($event, item)"
            >
              <svg-icon icon-class="new-30" class="menu-icon mr-2"></svg-icon>
              <span>{{ item.groupName }}</span>
            </div>
          </div>
          <div class="checkbox-group-btn" @click="onAddGroup">
            <div class="check-inner d-flex">
              <svg-icon icon-class="new-31" class="menu-icon mr-2"></svg-icon>
              添加分组
            </div>
          </div>
        </el-menu>
      </el-menu-item>
    </div>
    <li
      v-if="
        menuButtons.append || menuButtons.changeMindMap || menuButtons.rename
      "
      class="el-dropdown-menu__item--divided"
    ></li>
    <el-menu-item
      v-if="menuButtons.remove"
      index="4"
      @click="onClick($event, 'delete')"
    >
      <svg-icon icon-class="new-15" class="menu-icon mr-2"></svg-icon>
      <span>删除模型</span>
      <span class="menu-hot-key">{{
        shortcutKeys.find((a) => a.key === "del-item").shortcutText
      }}</span>
    </el-menu-item>
    <div>
      <el-menu-item
        v-if="menuButtons.moveNode"
        index="5"
        @click="onClick($event, 'move')"
      >
        <svg-icon icon-class="new-16" class="menu-icon mr-2"></svg-icon>
        <span>移动模型</span>
      </el-menu-item>
      <el-menu-item
        v-if="menuButtons.copyNode"
        index="6"
        @click="onClick($event, 'copy')"
      >
        <svg-icon icon-class="new-20" class="menu-icon mr-2"></svg-icon>
        <span>复制模型</span>
      </el-menu-item>
      <!-- v-if="menuButtons.shareNode" -->
      <el-menu-item index="7" @click="onClick($event, 'share')" v-if="!isLocal">
        <svg-icon icon-class="new-19" class="menu-icon mr-2"></svg-icon>
        <span>分享模型</span>
      </el-menu-item>
    </div>
    <li
      v-if="menuButtons.iconChange"
      class="el-dropdown-menu__item--divided"
    ></li>
    <div v-if="!selectNode.item.parent">
      <el-menu-item
        v-for="(item, idx) in icons"
        @click="onClick($event, 'iconType', item.type)"
        :key="item.key + '-' + idx"
      >
        <svg-icon :icon-class="item.icon" class="menu-icon mr-2"></svg-icon>
        <span>{{ item.label }}</span>
      </el-menu-item>
    </div>
    <el-menu-item v-else class="menu-drop">
      <div class="d-flex menu-drop-inner" @click="onClick($event, 'showType')">
        <svg-icon icon-class="new-67" class="menu-icon mr-2" />
        <span>切换图标</span>
        <el-icon class="pos-right">
          <ArrowRight />
        </el-icon>
      </div>
      <el-menu
        class="tree-menus right-menu"
        :class="[theme, 'menu-group']"
        v-if="showType"
      >
        <el-menu-item
          v-for="(item, idx) in icons"
          @click="onClick($event, 'iconType', item.type)"
          :key="item.key + '-' + idx"
        >
          <svg-icon :icon-class="item.icon" class="menu-icon mr-2"></svg-icon>
          <span>{{ item.label }}</span>
        </el-menu-item>
      </el-menu>
    </el-menu-item>
    <el-menu-item @click="onClick($event, 'mark')">
      <svg-icon
        icon-class="new-66"
        class="menu-icon mr-2"
        :class="{ 'svg-color-red': $isMarkEmphasis(selectNode.item) }"
      />
      <span v-if="$isMarkEmphasis(selectNode.item)">取消标记</span>
      <span v-else>标记重点</span>
    </el-menu-item>
    <el-menu-item
      v-if="selectNode.item.link2"
      @click="onClick($event, 'view-link')"
    >
      <svg-icon class="ml-1 size-0 mr-2" icon-class="link-single"></svg-icon>
      <span>查看关联</span>
    </el-menu-item>
    <li
      v-if="
        menuButtons.remove ||
        menuButtons.moveNode ||
        menuButtons.copyNode ||
        menuButtons.iconChange
      "
      class="el-dropdown-menu__item--divided"
    ></li>
    <el-menu-item
      v-if="
        menuButtons.expandChild &&
        !selectNode.item.expand &&
        selectNode.item.hasChildren
      "
      index="26"
      @click="onClick($event, 'expand')"
    >
      <el-icon style="vertical-align: -18%">
        <Expand />
      </el-icon>
      <span>一键展开</span>
    </el-menu-item>
    <el-menu-item
      v-if="
        menuButtons.reduceAll &&
        selectNode.item.expand &&
        selectNode.item.hasChildren
      "
      index="27"
      @click="onClick($event, 'closed')"
    >
      <el-icon style="vertical-align: -18%">
        <Fold />
      </el-icon>
      <span>一键收缩</span>
    </el-menu-item>
    <li
      v-if="menuButtons.paragraphHistory"
      class="el-dropdown-menu__item--divided"
    ></li>
    <el-menu-item
      v-if="menuButtons.aiTranslate"
      index="27"
      @click="onClick($event, 'aiTranslate')"
    >
      <svg-icon icon-class="053" class="menu-icon mr-2"></svg-icon>
      <span>AI翻译</span>
    </el-menu-item>
    <li
      v-if="menuButtons.paragraphHistory"
      class="el-dropdown-menu__item--divided"
    ></li>
    <!-- <el-menu-item
      v-if="menuButtons.paragraphHistory && !selectNode.item.parent"
      index="28"
      @click="onClick($event, 'record')"
    >
      <el-icon style="vertical-align: -18%">
        <Clock />
      </el-icon>
      <span>段落记录</span>
    </el-menu-item> -->
    <li class="el-dropdown-menu__item--divided"></li>
    <el-menu-item index="29" @click="onClick($event, 'set')">
      <svg-icon
        icon-class="new-17"
        class="menu-icon mr-2"
        style="margin-left: 2px"
      ></svg-icon>
      <span>菜单设置</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
import SvgIcon from "../../../../svgIcon/index.vue";
import { shortcutKeys } from "../../../../utils/constaints";
export default {
  components: { SvgIcon },
  inject: ["isDoubleMap"],
  props: {
    isLocal: { type: Boolean, default: false },
    theme: { type: String, default: "" },
    selectNode: { type: Object, default: undefined },
    menuButtons: { type: Object, default: undefined },
    sameMap: { type: Boolean, default: false },
  },
  data() {
    return {
      showGroup: false,
      showType: false,
      shortcutKeys: [],
    };
  },
  computed: {
    icons: {
      get() {
        if (!this.menuButtons.iconChange) return [];
        let p = [];
        const iconType = this.$getIconType(this.selectNode.item);
        if (!this.selectNode.item.parent) {
          if (!iconType || iconType === 99) {
            p.push({
              type: 2,
              icon: "new-01",
              // label: "切换为“森林”",
              label: "切换图标",
            });
          } else if (iconType === 2) {
            p.push({
              type: 99,
              icon: "new-02",
              label: "切换为“树”",
            });
          }
        } else {
          p = [
            {
              type: 2,
              icon: "new-01",
              label: "切换为“森林”",
            },
            {
              type: 99,
              icon: "new-02",
              label: "切换为“树”",
            },
            {
              type: 20,
              icon: "new-06",
              label: "切换为“树干”",
            },
            {
              type: 0,
              icon: "new-04",
              label: "切换为“果实”",
            },
          ];
          if (!iconType) {
            p.splice(3, 1);
          } else if (iconType === 1) {
            p.splice(3, 1);
          } else if (iconType === 20) {
            p.splice(2, 1);
          } else if (iconType === 99) {
            p.splice(1, 1);
          } else if (iconType === 2) {
            p.splice(0, 1);
          }
        }
        return p;
      },
    },
  },
  watch: {
    showGroup(val) {
      if (val) {
        this.$nextTick(() => {
          if (!this.$refs.groupList) return;
          const offset = this.$refs.groupList.$el.offset();
          if (document.body.clientHeight < offset.bottom + 6) {
            this.$refs.groupList.$el.style.top =
              -(offset.bottom - document.body.clientHeight + 6) + "px";
          }
        });
      }
    },
    selectNode(val, old) {
      clearTimeout(this.timer);
      this.showGroup = false;
      this.showType = false;
      this.removeListener();
      if (val) {
        this.$root.updateGroupChecked(val.item?.id);
        this.addListener();
      }
    },
  },
  created() {
    this.shortcutKeys = shortcutKeys;
  },
  methods: {
    async onChangeGroup(e, item) {
      e.stopPropagation();
      item.checked = !item.checked;
      this.clickType = "group";
      if (item.checked) {
        await this.$root.addGroupItem(item.id, this.selectNode.item.id);
      } else {
        await this.$root.deleteGroupItem(item.id, this.selectNode.item.id);
      }
    },
    onAddGroup() {
      this.$root.configKey += 1;
      this.$root.configActive = "groups";
      this.$root.configVisible = true;
    },
    onGroup(e, item, idx) {
      this.clickType = "group";
    },
    onClick(e, type, data) {
      if (type === "showType") {
        this.clickType = "group";
        this.showType = !this.showType;
        this.$emit("onChange", this.selectNode, type, data);
        return;
      }
      this.clickType = type;
      this.showGroup = !this.showGroup;
      this.$emit("onChange", this.selectNode, type, data);
    },
    onClose(e) {
      if (this.clickType === "group") {
        this.clickType = undefined;
        return;
      }
      if (this.allowClose) {
        this.timer = setTimeout(() => {
          if (this.clickType === "group") {
            this.clickType = undefined;
            this.addListener();
            return;
          }
          this.$emit("update:selectNode", undefined);
        }, 150);
      }
      this.removeListener();
    },
    addListener() {
      this.allowClose = false;
      clearTimeout(this.timer);
      this.removeListener();
      this.timer = setTimeout(() => {
        window.addEventListener("mousedown", this.onClose);
        this.allowClose = true;
      });
    },
    removeListener() {
      window.removeEventListener("mousedown", this.onClose);
    },
    onNewTab() {
      let routeUrl = this.$router.resolve({
        path: "/article/" + this.selectNode.item.id,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
