<template>
  <div class="insight" @scroll="scroll($event)">
    <div class="insight-collapse">
      <el-scrollbar ref="scrollbarRef">
        <collapse-list v-for="(item, index) in insightLists" :key="item.id" :keyNumber="index" :data="item" @edit="onEdit"
          @remove="onRemove"></collapse-list>
        <div v-if="loading" class="loading">
          <el-icon class="is-loading">
            <Loading />
          </el-icon><span>加载中...</span>
        </div>
      </el-scrollbar>
    </div>
  </div>
  <div class="chat-drawer-footer insight-footer">
    <div class="input-box">
      <el-input class="input-title" v-model="title" maxlength="100" placeholder="请输入模型" />
      <el-input ref="flashTroughtInput" v-model="content" type="textarea" :rows="3" maxlength="400" resize="none"
        placeholder="请输入存量" :disabled="chatDisabled" class="input-textarea" />
      <!-- @keyup.enter="confirmClick" -->
      <el-button size="small" class="chat-btn-l" :disabled="chatDisabled" @click="confirmClick">
        <svg-icon icon-class="014" />
      </el-button>
    </div>
  </div>
</template>
<script>
import collapseList from "./collapseList";
export default {
  props: {
    chatNav: { type: String, default: "" },
  },
  components: { collapseList },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      loading: false,
      noMore: false,
      scrollTop: 0,
      title: "",
      content: "",
      insightLists: [],
    };
  },
  created() { },
  mounted() {
    this.getList();
  },
  methods: {
    changeInput(data) {
      this.content = data;
    },
    async getList() {
      let res = await this.$api.insight.list(this.pageNo, this.pageSize);
      if (res && res.data.isSuccess === true) {
        this.insightLists.push(...res.data.data);
        if (this.insightLists.length < this.pageNo * this.pageSize) {
          this.loading = false;
          this.noMore = true;
        }
      } else {
        this.$root.tips("error", res.data.message);
      }
    },
    loadLists() {
      this.pageNo += 1;
      this.loading = true;
      setTimeout(() => {
        this.getList();
        this.loading = false;
      }, 2000);
    },
    async onEdit(list, key) {
      let res = await this.$api.insight.save({
        id: list.id,
        insightType: 1,
        title: list.title,
        content: list.content,
      });
      this.insightLists[key].title = list.title;
      this.insightLists[key].content = list.content;
    },
    async onRemove(id) {
      let res = await this.$api.insight.remove(id);
      if (res && res.data.isSuccess === true) {
        var index = this.insightLists.findIndex(function (item) {
          return item.id === id;
        });
        if (index != -1) {
          if (this.noMore) {
            this.insightLists.splice(index, 1);
          } else {
            this.pageNo = 1;
            this.insightLists = [];
            this.noMore = false;
            this.getList();
          }
        }
      } else {
        this.$root.tips("error", res.data.message);
      }
      return res;
    },
    async confirmClick() {
      let res = await this.$api.insight.save({
        id: "",
        insightType: 1,
        title: this.title,
        content: this.content,
      });
      if (res && res.data.isSuccess === true) {
        this.pageNo = 1;
        this.insightLists = [];
        this.noMore = false;
        this.getList();
        this.title = "";
        this.content = "";
      } else {
        this.$root.tips("error", res.data.message);
      }
    },
    scroll(e) {
      if (!this.noMore && !this.loading) {
        let scroll = e.target;
        let scrollHeight = scroll.scrollHeight - scroll.clientHeight;
        this.scrollTop = scroll.scrollTop;
        if (scrollHeight - scroll.scrollTop < 40) {
          this.loadLists();
        }
      }
    },
  },
};
</script>
