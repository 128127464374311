<template>
  <div class="document-list">
    <div class="document-column">
      <svg-icon icon-class="tree_l_dark" class-name="tree-search" />
      <div class="document-column-name">我的模型树</div>
      <div class="document-column-methods d-center">
        <el-icon class="color-icon" size="16px" @click="insertAfter()">
          <Plus></Plus>
        </el-icon>
        <svg-icon
          icon-class="mind"
          class-name="mind-all"
          @click="menuOpenMindMap(undefined)"
        />
      </div>
    </div>
    <tree-list
      ref="treeList"
      class="flex-1 scroll"
      :load="loadNode"
      :tree-type="1"
      :initLoad="getInitTreeNodes"
      :update-node-api="treeUpdateNodeApi"
      :clone-api="treeCloneApi"
      :delete-api="deleteApi"
      @onSelect="onSelectTreeItem"
      @onContextMenu="menuOpen"
    >
      <template #default="{ data, node }">
        <document-item
          v-model:title="data.title"
          :item="data"
          @update:title="onChangeTitle(node)"
          @input-focus="onFocus"
          @onRename="treeRenameApi(node)"
          @onMenu="(node, type, data) => onMenu(node, type, data)"
          @onClick="onSelectTreeItem(data)"
        />
      </template>
    </tree-list>
    <tree-menus
      ref="menus"
      :is-local="true"
      :menuButtons="$root.config.menuButtons"
      v-model:select-node="menuSelectItem"
      :theme="$root.config.theme"
      @onChange="onMenu"
    />

    <tree-dialog
      :init-list="$refs.treeList && $refs.treeList.list"
      :load-node="loadNode"
      v-model:filter-item="treeDialogItem"
      :theme="$root.config.theme"
      :update-node-api="treeUpdateNodeApi"
      :clone-api="treeCloneApi"
      :on-select="onSelectTreeItem"
      v-model:dialog-type="treeDialogType"
    />
  </div>
</template>

<script>
import TreeList from "../tree/TreeList";
import DocumentItem from "../tree/DocumentItem";
import TreeMenus from "../tree/TreeMenus";
import TreeDialog from "../tree/TreeDialog";

export default {
  name: "LocalTree",
  components: { TreeDialog, TreeMenus, DocumentItem, TreeList },
  props: {
    midType: { type: String, default: "" },
    selectItem: { type: Object, default: undefined },
    changeTitleApi: { type: Function, default: undefined },
    deleteApi: { type: Function, default: undefined },
  },
  data() {
    return {
      menuSelectItem: undefined,
      treeDialogItem: undefined,
      treeDialogType: 0,
      activeItem: undefined,
    };
  },
  watch: {
    selectItem(val, old) {
      if (val !== old && this.activeItem !== val) {
        if (old) old.active = false;
        if (!val || (val && val.id.startsWith("local-"))) {
          this.activeItem = val;
        } else {
          this.activeItem = undefined;
        }
      }
    },
  },
  mounted() {},
  methods: {
    onFocus() {
      this.$emit("input-focus");
    },
    onChangeTitle(node) {
      this.$emit("change-title", node.item);
    },
    onMenu(node, type, data) {
      switch (type) {
        case "more":
          this.menuOpen(data, node);
          break;
        case "new":
          this.menuNewTreeItem(node);
          break;
        case "map":
          this.menuOpenMindMap(node);
          break;
        case "expand":
          this.menuExpandAll(node, true);
          break;
        case "closed":
          this.menuExpandAll(node, false);
          break;
        case "rename":
          this.menuSelectItem.focus();
          break;
        case "delete":
          this.menuDelete(node);
          break;
        case "move":
          this.treeDialogItem = node.item;
          this.treeDialogType = 1;
          break;
        case "copy":
          this.treeDialogItem = node.item;
          this.treeDialogType = 2;
          break;
        case "share":
          this.menuShare(node);
          break;
        case "iconType":
          this.menuIconType(node, data);
          break;
        case "record":
          this.menuParagraphHistory(node);
          break;
        case "set":
          this.$root.configVisible = true;
          this.$root.configActive = "menu";
          break;
      }
    },
    menuOpenMindMap(node) {
      this.$emit("mind-map", node, true);
    },
    menuParagraphHistory(node) {
      this.$emit("paragraph-history", node);
    },
    menuShare(node) {
      this.$emit("share", node);
    },
    menuExpandAll(node, expand) {
      this.$refs.treeList.expandAll(node.item, expand);
    },
    async menuIconType(node, iconType) {
      return await this.$api.article
        .updateIconType(
          {
            articleId: node.item.id,
            iconType: iconType,
          },
          true
        )
        .then(() => {
          node.item.iconType = iconType;
        });
    },
    async menuDelete(node) {
      await this.$refs.treeList.deleteItem(node.item);
    },
    async menuNewTreeItem(node, type = "left", iconType = 0) {
      let res = await this.$refs.treeList.appendItem(
        node && node.item,
        iconType
      );
      return res;
    },
    menuOpen(e, node) {
      this.menuSelectItem = node;
      this.$nextTick(() => {
        let menu = this.$refs.menus.$el;
        // console.log(event, window.innerHeight, menu.offsetHeight);
        menu.style.left = e.clientX + 20 + "px";
        let top = e.clientY;
        if (e.clientY + menu.offsetHeight > window.innerHeight) {
          top = window.innerHeight - menu.offsetHeight;
        }
        menu.style.top = top - 5 + "px";
      });
    },
    insertAfter() {
      this.menuNewTreeItem(null, "left", 99);
    },
    async loadNode(node) {
      return await this.$api.article.childrenList(node && node.id, true);
    },
    async getInitTreeNodes() {
      let articleId = this.$route.params.articleId;
      articleId =
        articleId && articleId.startsWith("local-") ? articleId : undefined;
      return this.$api.article.articleTree(articleId, true);
    },
    async treeUpdateNodeApi(data) {
      if (data.articleId) {
        this.$emit("move", data);
      }
      return await this.$api.article.addOrUpdateArticleCatalog(data, true);
    },
    async treeCloneApi(item, parent) {
      let s = await this.$root.cloneArticle(item, parent);
      this.$emit("clone", s);
      return s;
    },

    treeRenameApi(node) {
      if (this.changeTitleApi) {
        return this.changeTitleApi({
          id: node.item.id,
          title: node.item.title,
        });
      }
    },
    onSelectTreeItem(item, checkExpand = true) {
      if (!item) {
        this.activeItem = undefined;
        this.$emit("update:selectItem", undefined);
        this.$router.push({
          path: `/article`,
        });
        return;
      }
      if (this.activeItem && this.activeItem.id === item.id) {
        if (this.midType !== "vditor") {
          this.$emit("update:midType", "vditor");
          return;
        }
        if (checkExpand) {
          this.$refs.treeList.expand(item);
        }
        return;
      }

      if (this.activeItem) this.activeItem.active = false;
      this.activeItem = item;
      this.$emit("update:midType", "vditor");
      this.$emit("update:selectItem", item);
      this.activeItem.active = true;
      if (checkExpand) {
        if (!this.activeItem.expand) {
          this.$refs.treeList.expand(item);
        }
      }
      if (this.$route.params.articleId !== item.id) {
        this.$router.push({
          path: `/article/${item.id}`,
        });
      }
    },
  },
};
</script>

<style scoped></style>
