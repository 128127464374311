<template>
  <div class="ar-select" @touchend="onNoDrop" @mousedown="onNoDrop">
    <div class="ar-select-inner">
      <div class="ar-s" @click="onPreSearch">
        <svg-icon class-name="size-2" icon-class="new-search"/>
      </div>
      <input ref="searchInput" placeholder="搜索模型" v-model="searchValue"
             @blur="onBlur"
             @input="searchArticle(0)"/>
    </div>
    <div class="ar-select-drop scroll"
         @scroll="onScroll"
         :class="startSearch? (!list || list.length===0? 'ar-empty':'ar-show'):''">
      <div v-if="searching">
        <div class="ui-loading small"></div>
      </div>
      <div v-else-if="!list || list.length===0">
        No Data
      </div>
      <div v-else class="ar-list">
        <div class="ar-list-item"
             v-if="list"
             v-for="item in list"
             :key="item.id"
             @click="searchSubmit(item)"
        >
          {{ item.title || '新建模型' }}
        </div>
        <div v-if="isLoadingMore" class="ar-list-item d-center">
          <div class="ui-loading small"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../../../../svgIcon/index.vue";
import {proxyApi} from "../../../../workers/ProxyApi";

export default {
  name: "SearchIndex",
  components: {SvgIcon},
  props: {
    word: {type: String, default: ""},
  },
  data() {
    return {
      searchValue: '',
      searching: false,
      isLoadingMore: false,
      list: [],
      curPageNum: 0,
      noMore: false,
      isFocus: false,
      startSearch: false,
    };
  },
  mounted() {
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('touchend', this.onMouseDown);
  },
  unmounted() {
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('touchend', this.onMouseDown);
  },
  methods: {
    onNoDrop(e) {
      e.stopPropagation();
    },
    onMouseDown() {
      window.clearTimeout(this.timeSearchArticle);
      this.timeSearchArticle = setTimeout(() => {
        this.isFocus = false;
        this.startSearch = false;
        this.searchValue = '';
        this.list = [];
        this.searching = false;
      }, 100)
    },
    onScroll(e) {
      const rect = e.target.getBoundingClientRect();
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      if (scrollTop + rect.height > scrollHeight - 30) {
        if (this.isLoadingMore || this.searching) {
          return;
        }
        this.searchArticle(this.curPageNum + 1);
      }
    },
    onPreSearch() {
      window.clearTimeout(this.timeSearchArticle);
      if (this.keyword && (!this.list || this.list.length === 0)) {
        if (this.keyword) {
          this.searchValue = this.keyword;
          this.$refs.searchInput.focus();
          this.isLoadingMore = false;
          this.noMore = false;
          this.searching = false;
          this.searchArticle();
        }
      }
    },
    onFocus() {
      this.isFocus = true;
      if (this.timeSearchArticle) {
        window.clearTimeout(this.timeSearchArticle);
        this.timeSearchArticle = undefined;
      }
    },
    onBlur() {
      // if (this.timeSearchArticle) {
      //   window.clearTimeout(this.timeSearchArticle);
      //   this.timeSearchArticle = undefined;
      // }
      // this.timeSearchArticle = setTimeout(() => {
      //   this.isFocus = false;
      //   this.startSearch = false;
      //   this.searchValue = '';
      //   this.list = [];
      //   this.searching = false;
      // }, 100)
    },
    searchArticle(pageNo = 0) {
      this.keyword = this.searchValue;
      if (this.keyword) {
        this.startSearch = true;
      }
      if (pageNo === 0) {
        this.noMore = false;
        this.isLoadingMore = false;
      }
      if (this.isLoadingMore || this.searching || this.noMore) return;
      this.curPageNum = pageNo;
      if (this.timeSearchArticle) {
        window.clearTimeout(this.timeSearchArticle);
      }
      if (this.keyword) {
        if (pageNo === 0) {
          this.list = [];
          this.searching = true;
        } else {
          this.isLoadingMore = true;
        }
        this.timeSearchArticle = window.setTimeout(() => {
          this.$api.article
              .search(this.keyword, pageNo, this.treeType === 1)
              .then((res) => {
                if (res.data) {
                  this.list.push(...res.data.filter(item => !!this.$root.findItemById(item.id)));
                  this.noMore = res.data.length < 10;
                }
                this.searching = false;
                this.isLoadingMore = false;
              });
        }, 500);
      } else {
        this.searching = false;
      }
    },
    searchSubmit(item) {
      const node = this.$root.findItemById(item.id);
      console.log('searchSubmit-', node);
      if (node) {
        let parent = node.parent;
        while (parent) {
          parent.expand = true;
          parent = parent.parent;
        }
        this.$emit("search-change", node);
      } else {
        proxyApi.log({
          key: "error",
          content: {id: item.id, title: item.title},
          error: '搜索bug、点击无效',
        });
      }
      this.onMouseDown();
    },
  },
};
</script>

<style lang="scss" scoped></style>
