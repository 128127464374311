<template>
  <div
    v-if="isRefreshFinished"
    id="editor-box"
    class="vditor-box"
    :class="[
      'toolbar-' + $root.config.toolbarType,
      $root.config.theme,
      'editor-width-' + isWideScreen,
    ]"
    :style="[
      { margin: '0 auto' },
      { '--p-segment-spacing': ($root.config.pSegmentSpacing || 0.8) + 'em' },
      { '--p-line-height': $root.config.lineHeight || 1.5 },
      { '--p-font-size': ($root.config.fontSize || 15) + 'px' },
    ]"
  >
    <div :id="toolbarId" class="toolbarContainer" />
    <div class="title-box document-title">
      <div
        :class="['title mxs-title', { nounderline: isTitleLine == 'false' }]"
      >
        <form-input
          v-model:title="editTitle"
          placeholder="请提炼模型"
          ref="editorTitle"
          class="title-input"
          @save="onSaveTitle"
        >
          <!-- <template #prefix>
              <svg-icon icon-class="apple" class-name="apple"></svg-icon>
            </template> -->
        </form-input>
        <el-button
          v-if="showClose && $root.config.frame !== 'true'"
          link
          size="small"
          class="document-close"
          @click="$emit('close')"
        >
          <svg-icon icon-class="new-65" class="d-center size-1"></svg-icon>
          <!--          <svg-icon icon-class="slider-close" class="d-center size-1"></svg-icon>-->
        </el-button>
      </div>
    </div>
    <div
      class="froala-box"
      @contextmenu.prevent="openMenu($event)"
      v-loading="loading"
      element-loading-text="存量导入中..."
      :element-loading-spinner="loadSvg"
      element-loading-svg-view-box="0, 0, 16, 16"
    >
      <froala
        ref="froala"
        v-if="status && !loading"
        :tag="'textarea'"
        :config="config"
        v-model="content"
      />
    </div>
    <!-- <el-button v-else class="vditor-history" size="small" @click="history"
      ><el-icon><Clock /></el-icon
    ></el-button> -->
    <el-drawer
      v-model="drawer"
      size="100%"
      append-to-body
      :with-header="false"
      :before-close="historyClose"
      :custom-class="[
        $root.config.theme,
        $root.config.themeBody,
        'history-frame',
      ]"
    >
      <el-container style="height: 100%" class="history-box">
        <el-container>
          <el-header class="history-header">
            <div class="editor-header-item">
              <button type="button" class="ud__button" @click="historyClose">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-icon="ReturnItemOutlined"
                  style="margin-right: 4px; vertical-align: -10%"
                >
                  <path
                    d="M4 4h16v4h2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2h-7.586l1.293-1.293a1 1 0 1 0-1.414-1.414l-3 3a1 1 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414-1.414L12.414 13H20v7H4V4Z"
                    fill="currentColor"
                  ></path>
                </svg>
                返回文档
              </button>
            </div>
            <div class="editor-header-item" style="text-align: center">
              <el-button
                type="primary"
                size="small"
                @click="recoverArticle"
                :disabled="disabled"
                >还原此版本
              </el-button>
            </div>
            <div class="editor-header-item"></div>
          </el-header>
          <el-main class="history-main">
            <el-scrollbar :height="height">
              <div style="padding: 0 40px">
                <h1 style="font-size: 20px">{{ historyTitle }}</h1>
                <div v-html="historyContent" style="padding-left: 20px"></div>
              </div>
            </el-scrollbar>
          </el-main>
        </el-container>
        <el-aside class="history-aside">
          <el-header class="history-header">历史记录</el-header>
          <el-main>
            <el-scrollbar :height="height">
              <el-tree
                v-if="isHistory"
                ref="historyTree"
                node-key="id"
                :data="historyData"
                :props="defaultProps"
                :highlight-current="true"
                @node-click="handleNodeClick"
                @current-change="currentChange"
              >
                <template #default="{ node, data }">
                  <div class="history-list">
                    <div>{{ data.articleUpdateTime }}</div>
                    <div class="history-list-title">{{ node.label }}</div>
                  </div>
                </template>
              </el-tree>
            </el-scrollbar>
          </el-main>
        </el-aside>
      </el-container>
    </el-drawer>
    <el-dialog
      v-model="isUpload"
      title="插入图片"
      width="600px"
      draggable
      class="input-img"
    >
      <el-upload
        class="editor-upload"
        ref="upload"
        drag
        action
        multiple
        :http-request="handleUpload"
        :auto-upload="true"
        :limit="20"
        :on-exceed="handleExceed"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-preview="handlePictureCardPreview"
        accept=".jpg,.jpeg,.png,.JPG,.JPEG"
      >
        <el-icon class="el-icon--upload">
          <upload-filled />
        </el-icon>
        <div class="el-upload__text">
          <!--          拖拽到这里或者 <em style="color: #6ba4fa">点击选择图片</em>上传-->
          拖拽到这里或者 <em class="el-dm">点击选择图片</em>上传
        </div>
        <!-- <template #tip>
          <div class="el-upload__tip">
            jpg/png files with a size less than 500kb
          </div>
        </template> -->
      </el-upload>
    </el-dialog>
    <div class="tips-dialog" v-if="isTips">
      <el-dialog
        v-model="isTips"
        title="出处"
        width="500px"
        draggable
        @closed="tipsClose"
      >
        <div>
          <div style="display: flex">
            <el-input
              v-model="tipsinput"
              placeholder="添加文本"
              style="margin-right: 40px"
            ></el-input>
            <el-button
              type="primary"
              @click="saveTips"
              :disabled="tipsinput == ''"
              >储存
            </el-button>
          </div>
          <el-divider></el-divider>
          <div style="height: 270px">
            <el-scrollbar max-height="245px">
              <draggable v-model="fontlist" @end="datadragEnd">
                <div v-for="(item, index) in fontlist" :key="index">
                  <div
                    v-if="item.isfouce"
                    style="
                      padding: 8px 10px;
                      background: rgba(19, 128, 255, 0.16);
                      border-radius: 2px;
                      display: flex;
                    "
                  >
                    <div
                      @click="insertHtml(item.tip)"
                      style="
                        width: 410px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                      "
                    >
                      {{ item.tip }}
                    </div>
                    <div>
                      <el-icon @click="removeTip(item.id)">
                        <Delete color="#F54949" />
                      </el-icon>
                    </div>
                    <div style="padding-left: 10px">
                      <el-icon>
                        <Operation color="#333" />
                      </el-icon>
                    </div>
                  </div>
                  <div
                    v-else
                    @click="insertHtml(item.tip)"
                    @mouseover="focusfont(item)"
                    class="tip-tip"
                    style="
                      padding: 8px 10px;
                      width: 410px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: #333333;
                    "
                  >
                    {{ item.tip }}
                  </div>
                </div>
              </draggable>
            </el-scrollbar>
          </div>
        </div>
      </el-dialog>
    </div>
    <div
      class="diashowstyle"
      v-show="isMath"
      :style="{
        width: '360px',
        height: '100px',
        padding: '40px 20px 20px',
        position: 'fixed',
        top: '20vh',
        left: '50vw',
        'margin-left': '-180px',
      }"
    >
      <el-button class="math-close" size="small" @click="changeMath">
        <el-icon>
          <Close />
        </el-icon>
      </el-button>
      <div style="width: 100%">
        <math-field
          id="formula"
          class="formula"
          virtual-keyboard-mode="manual"
        ></math-field>
      </div>
      <el-button
        type="primary"
        @click="insertMath"
        :disabled="mathDisabled"
        style="margin-top: 20px"
        >插入公式
      </el-button>
    </div>
    <div
      ref="editorMenu"
      class="editor-right-menu"
      v-show="isMenu"
      :style="{
        width: 'auto',
        'min-width': '140px',
        top: position.y,
        left: position.x,
      }"
    >
      <div style="width: 100%">
        <el-menu class="el-menu-vertical-demo" @open="handleOpen">
          <el-menu-item
            v-if="editorMenuButtons.paste"
            index="paste"
            @click="pasteBtn"
          >
            <i class="el-icon">
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M704 128l-128 0L576 64c0-35.2-28.8-64-64-64l-128 0c-35.2 0-64 28.8-64 64l0 64L192 128l0 128 512 0L704 128zM512 128l-128 0L384 64.128c0.032-0.032 0.064-0.064 0.128-0.128l127.776 0c0.032 0.032 0.096 0.064 0.128 0.128L512 128zM832 320 832 160c0-17.6-14.4-32-32-32l-64 0 0 64 32 0 0 128L384 320l0 448L128 768 128 192l32 0L160 128 96 128c-17.6 0-32 14.4-32 32l0 640c0 17.6 14.4 32 32 32l288 0 0 192 448 0 192-192L1024 320 832 320zM832 933.504 832 832l101.504 0L832 933.504zM960 768l-192 0 0 192L448 960 448 384l512 0L960 768z"
                ></path>
              </svg>
            </i>
            <span>粘贴</span>
            <span class="menu-key">Ctrl + V</span>
          </el-menu-item>
          <el-menu-item
            v-if="editorMenuButtons.embInsertHR"
            index="embInsertHR"
            @click="insertHRBtn"
          >
            <i class="el-icon">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M5,12h14 M19,11H5v2h14V11z"></path>
              </svg>
            </i>
            <span>分割线</span>
          </el-menu-item>
          <el-menu-item
            v-if="editorMenuButtons.tips"
            index="tips"
            @click="tipsRightBtn"
            @mouseenter="tipsMenuShow"
            @mouseleave="tipsMenuHidden"
          >
            <i class="el-icon">
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M470.9888 261.77536l0 98.54976c0 13.3632-10.83392 24.19712-24.19712 24.19712-47.68768 0-73.6256 48.90624-77.21984 145.43872l77.21984 0c13.3632 0 24.19712 10.84416 24.19712 24.19712l0 208.0768c0 13.3632-10.83392 24.19712-24.19712 24.19712L240.90624 786.432c-13.37344 0-24.19712-10.84416-24.19712-24.19712l0-208.0768c0-46.27456 4.6592-88.73984 13.84448-126.22848 9.4208-38.44096 23.87968-72.04864 42.96704-99.90144 19.64032-28.6208 44.20608-51.07712 73.02144-66.72384 29.00992-15.73888 62.74048-23.72608 100.25984-23.72608C460.15488 237.57824 470.9888 248.41216 470.9888 261.77536zM783.09376 384.52224c13.3632 0 24.19712-10.84416 24.19712-24.19712l0-98.54976c0-13.3632-10.83392-24.19712-24.19712-24.19712-37.50912 0-71.23968 7.9872-100.2496 23.72608-28.81536 15.64672-53.39136 38.10304-73.03168 66.72384-19.08736 27.8528-33.54624 61.46048-42.96704 99.91168-9.17504 37.49888-13.83424 79.96416-13.83424 126.21824l0 208.0768c0 13.3632 10.83392 24.19712 24.19712 24.19712l205.8752 0c13.3632 0 24.19712-10.84416 24.19712-24.19712l0-208.0768c0-13.3632-10.83392-24.19712-24.19712-24.19712L706.9696 529.96096C710.51264 433.42848 736.07168 384.52224 783.09376 384.52224z"
                ></path>
              </svg>
            </i>
            <span>出处</span>
            <span class="menu-key">Ctrl + 4</span>
            <div
              ref="subEditorMenu"
              v-if="tipsMenu"
              class="editor-right-menu"
              :style="{
                position: 'absolute',
                width: '140px',
                top: '-6px',
                left: tipsMenuX,
                'z-index': 5,
              }"
              @click.stop="insertHtml('')"
              @mouseenter="tipsSubMenuShow"
              @mouseleave="tipsSubMenuHidden"
            >
              <div style="width: 100%">
                <el-menu class="el-menu-vertical-demo">
                  <el-menu-item
                    v-for="(item, index) in fontlist.slice(0, 5)"
                    :index="index"
                    :key="index"
                    @click="tipsRightSubMenu(item.tip)"
                  >
                    <div class="menu-tips">{{ item.tip }}</div>
                  </el-menu-item>
                  <el-menu-item @click="openSetting('tips')"
                    >编辑出处···</el-menu-item
                  >
                </el-menu>
              </div>
            </div>
          </el-menu-item>
          <el-menu-item
            v-if="editorMenuButtons.image"
            index="image"
            @click="changeUpload"
          >
            <i class="el-icon">
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style="width: 19px; height: 19px"
              >
                <path
                  fill="currentColor"
                  d="M14.2,11l3.8,5H6l3-3.9l2.1,2.7L14,11H14.2z M8.5,11c0.8,0,1.5-0.7,1.5-1.5S9.3,8,8.5,8S7,8.7,7,9.5C7,10.3,7.7,11,8.5,11z   M22,6v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h16C21.1,4,22,4.9,22,6z M20,8.8V6H4v12h16V8.8z"
                ></path>
              </svg>
            </i>
            <span>图片</span>
          </el-menu-item>
          <el-menu-item
            v-if="editorMenuButtons.math"
            index="math"
            @click="changeMath"
          >
            <i class="el-icon">
              <svg
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="width: 16px; height: 16px"
              >
                <path
                  fill="currentColor"
                  d="M13.9521 0.971514C13.899 0.701384 13.7478 0.45692 13.5243 0.279771C13.3008 0.102623 13.0189 0.00375037 12.7265 0H1.23704C0.944679 0.00375037 0.662746 0.102623 0.439277 0.279771C0.215807 0.45692 0.0646264 0.701384 0.0114922 0.971514C-0.00383075 1.04881 -0.00383075 1.1281 0.0114922 1.2054V10.7946C0.00876781 11.1058 0.135232 11.4058 0.364046 11.6308C0.592859 11.8559 0.906011 11.9883 1.23704 12H12.7265C12.8946 12 13.061 11.9688 13.2161 11.9081C13.3712 11.8474 13.512 11.7585 13.6304 11.6464C13.7488 11.5344 13.8424 11.4014 13.9058 11.2552C13.9692 11.109 14.0012 10.9525 14 10.7946V1.2054C13.9998 1.12531 13.9835 1.04597 13.9521 0.971514ZM5.5456 7.1964V4.8036H8.41798V7.1964H5.5456ZM8.41798 8.4018V10.7946H5.5456V8.4018H8.41798ZM1.23704 4.8036H4.27218V7.1964H1.23704V4.8036ZM9.6914 4.8036H12.7265V7.1964H9.6914V4.8036ZM12.7265 1.2054V3.5982H1.23704V1.2054H12.7265ZM1.23704 8.4018H4.27218V10.7946H1.23704V8.4018ZM9.6914 10.7946V8.4018H12.7265V10.7946H9.6914Z"
                />
              </svg>
            </i>
            <span>公式</span>
          </el-menu-item>
          <el-menu-item
            v-if="editorMenuButtons.today"
            index="today"
            @click="insertHtml(today())"
          >
            <i class="el-icon">
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M1024 512C1024 229.230208 794.769792 0 512 0 229.230208 0 0 229.230208 0 512 0 794.769792 229.230208 1024 512 1024 625.316262 1024 733.09232 987.060258 821.44823 919.93747 832.170355 911.792017 834.259159 896.496821 826.113707 885.774697 817.968254 875.052572 802.67306 872.963767 791.950935 881.10922 712.006355 941.842033 614.569408 975.238095 512 975.238095 256.160663 975.238095 48.761905 767.839337 48.761905 512 48.761905 256.160663 256.160663 48.761905 512 48.761905 767.839337 48.761905 975.238095 256.160663 975.238095 512 975.238095 606.738266 946.765111 697.157764 894.355733 773.603714 886.741822 784.709602 889.572629 799.884996 900.678517 807.498908 911.784403 815.112819 926.959799 812.282012 934.573709 801.176124 992.505146 716.675526 1024 616.659703 1024 512ZM487.619049 609.52381C487.619049 622.989037 498.534771 633.904762 512 633.904762 525.465229 633.904762 536.380951 622.989037 536.380951 609.52381L536.380951 243.809523C536.380951 230.344297 525.465229 219.428572 512 219.428572 498.534771 219.428572 487.619049 230.344297 487.619049 243.809523L487.619049 609.52381ZM481.586633 471.564079C472.719716 461.430462 457.316742 460.403597 447.183125 469.270511 437.049508 478.137425 436.022643 493.540401 444.889559 503.674018L615.556226 698.721636C624.42314 708.855253 639.826114 709.882118 649.959731 701.015204 660.093348 692.148288 661.120213 676.745314 652.253299 666.611697L481.586633 471.564079Z"
                ></path>
              </svg>
            </i>
            <span>时间</span>
          </el-menu-item>
          <el-menu-item
            v-if="editorMenuButtons.undo"
            index="undo"
            @click="undoRightBtn"
          >
            <i class="el-icon">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M10.4,9.4c-1.7,0.3-3.2,0.9-4.6,2L3,8.5v7h7l-2.7-2.7c3.7-2.6,8.8-1.8,11.5,1.9c0.2,0.3,0.4,0.5,0.5,0.8l1.8-0.9  C18.9,10.8,14.7,8.7,10.4,9.4z"
                ></path>
              </svg>
            </i>
            <span>撤销</span>
          </el-menu-item>
          <el-menu-item
            v-if="editorMenuButtons.redo"
            index="redo"
            @click="redoRightBtn"
          >
            <i class="el-icon">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M13.6,9.4c1.7,0.3,3.2,0.9,4.6,2L21,8.5v7h-7l2.7-2.7C13,10.1,7.9,11,5.3,14.7c-0.2,0.3-0.4,0.5-0.5,0.8L3,14.6  C5.1,10.8,9.3,8.7,13.6,9.4z"
                ></path>
              </svg>
            </i>
            <span>重做</span>
          </el-menu-item>
          <el-menu-item index="config" @click="openConfig('config')">
            <i class="el-icon">
              <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"
                ></path>
              </svg>
            </i>
            <span>设置</span>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
import FroalaEditor from "froala-editor";
import html2canvas from "html2canvas";
import * as cheerio from "cheerio";
import { defaultFroalaConfig } from "./defaultConfig";
import FormInput from "./FormInput";
import { proxyApi } from "../../../../workers/ProxyApi";

let that;
var __is_ff = navigator.userAgent.indexOf("Firefox") != -1; //Firefox
// const OSS = require("ali-oss");

export default {
  components: { FormInput },
  // components: {
  //   draggable: VueDraggableNext,
  // },
  beforeCreate() {
    that = this;
  },
  props: {
    midType: { type: String, default: "" },
    mindVditor: { type: Boolean, default: false },
    articleId: { type: String, default: "" },
    saveStatus: { type: Function, default: undefined },
    changeContent: { type: Function, default: undefined },
    editorRefresh: { type: Function, default: undefined },
    openConfig: { type: Function, default: undefined },
    topArticleId: { type: String, default: "" },
    queryChatgpt: { type: Function, default: undefined },
    title: { type: String, default: "" },
    show: { type: Boolean, default: true },
    showClose: { type: Boolean, default: false },
    toolbarId: { type: String, default: "toolbarContainer" },
  },
  data() {
    var that = this;
    var height = window.innerHeight - 70;
    var editorHeight = window.innerHeight - 240;
    return {
      loading: true,
      loadSvg:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">  <path d="M13.3033 2.6967L12.1247 3.87521C11.0692 2.81958 9.61083 2.16667 8 2.16667C4.77834 2.16667 2.16667 4.77834 2.16667 8C2.16667 11.2217 4.77834 13.8333 8 13.8333C11.2217 13.8333 13.8333 11.2217 13.8333 8H15.5C15.5 12.1422 12.1422 15.5 8 15.5C3.85787 15.5 0.5 12.1422 0.5 8C0.5 3.85787 3.85787 0.5 8 0.5C10.0711 0.5 11.9461 1.33947 13.3033 2.6967Z" fill="currentColor"/></svg>',
      position: {
        x: 0,
        y: 0,
      },
      diashow: false,
      fontlist: [],
      tipsinput: "",
      height: height,
      editorHeight: editorHeight,
      pastePlain: true,
      toolbarInline: true,
      toolbarBottom: false,
      editor: null,
      mindmap_token: "",
      mindmap_device: "",
      status: false,
      content: "",
      changeCount: 0,
      lastEditRange: null,
      lastKeyCode: "",
      config: {
        ...defaultFroalaConfig,
        events: {
          initialized: function () {
            // this.editor = editor;
            // console.log(editor.opts.imageUploadParams);
            that.editor = this;
            that.loadImg();
            // that.editor.html.set(that.content);
            // console.log(that.editor);
            if (
              that.$root.config.scrollPosition &&
              that.$root.config.scrollPosition === "true"
            ) {
              document.querySelector(".fr-wrapper").scrollTop =
                document.querySelector(".fr-wrapper").scrollHeight;
              document.querySelector(".fr-element.fr-view").scrollTop =
                document.querySelector(".fr-element.fr-view").scrollHeight;
            }

            that.initTimer = setTimeout(() => {
              if (that.mindVditor === false) {
                if (that.editTitle !== "") {
                  if (that.editTitle === "新建模型") {
                    // if (that.midType === "vditor") {
                    that.titleFocus();
                    // }
                  }
                  //  else if (that.content === "") {
                  //   if (that.midType === "vditor") {
                  //     // that.editor.events.focus();
                  //     that.titleFocus();
                  //   }
                  // }
                  else {
                    if (that.midType === "vditor") {
                      that.editor.selection.setAtEnd(that.editor.$el.get(0));
                      that.editor.selection.restore();
                    }
                  }
                }
              }

              // console.log(that.content);
              that.content = that.getSimpleText(that.content);
              if (that.content.indexOf("<img") !== -1) {
                that.changeCount = 0;
              }
            }, 500);
          },
          focus: function () {
            // var htmlTest = JSON.stringify(that.content);
          },
          blur: function () {
            // 工具栏跟随模式 主动隐藏工具栏
            // that.editor.toolbar.hide();
            // console.log('-------blur--')
          },
          input: function (inputEvent) {
            // that.updateSaveStatus("未保存");
            that.firstOpen = false;
          },
          keyup: function (keyupEvent) {
            // 输入回车清除上一行带的格式
            that.firstOpen = false;
            if (keyupEvent.keyCode === 13) {
              that.editor.commands.clearFormatting();
              that.lastKey = "enter";
              that.save();
            } else {
              that.lastKey = null;
            }
            // 记录光标最后位置
            if (getSelection().baseNode != null) {
              that.lastEditRange = getSelection().getRangeAt(0);
            }
            if (that.isMenu == true) {
              that.isMenu = false;
            }
            // 工具栏跟随模式 输入/出现工具栏并删除/
            // if (that.config.toolbarInline == true) {
            // if (keyupEvent.keyCode == 191) {
            //   // that.editor.toolbar.showInline(null, true);
            //   that.editor.cursor.backspace();
            //   that.openMenu();
            //   // }
            // }
          },
          "buttons.refresh": function () {
            // Do something here.
            // this is the editor instance.
          },
          "window.cut": function () {
            // var a = that.getClipboardText();
            // console.log(a);
          },
          "window.copy": function () {
            // var a = that.getClipboardText();
            // console.log(a);
          },
          "paste.before": function (original_event) {
            // console.log(original_event);
            that.pasteBefore = true;
            if (
              original_event &&
              original_event.path &&
              original_event.path[0].localName == "br"
            ) {
              original_event.path[0].remove();
            }
          },
          "paste.beforeCleanup": function (clipboard_html) {
            // console.log(clipboard_html);
          },
          "paste.afterCleanup": function (clipboard_html) {
            // console.log(clipboard_html);
            // if (that.pastePlain == "true") {
            //   clipboard_html = clipboard_html.replace(/'/gim, '"');
            //   clipboard_html = clipboard_html.replace(/style=".*?"/gim, "");
            //   return clipboard_html;
            // }
          },
          "paste.after": function () {
            // console.log(that.content);
            // console.log(that.editor.clean.html(that.content));
            // document.querySelector("span[data-lark-record-data]");
            that.pasteBefore = false;
            // that.$localForage.setItem(that.articleId, {
            //   articleId: that.articleId,
            //   content: that.content,
            // });
            that.cleanHtml();
            that.save();
          },
          "save.before": function (res) {
            that.updateSaveStatus("保存中");
            // that.config.saveParams.articleParagraphModels;
            // console.log("保存前：", that.articleId, that.content);
            // that.setArticleParagraphModels();
            that.saveContent(res);
            return false;
          },
          "save.after": function (res) {
            // console.log(res.data);
            that.lastKey = null;
            if (res.data.message == "成功") {
              // console.log("保存后：", that.articleId, that.content);
              // setTimeout(() => {
              that.updateSaveStatus("已保存到云端");
              // }, 1000);
              // that.changeCount = 1;
              // that.pasteImgDom = null;
            } else {
              that.updateSaveStatus("已保存到云端");
              that.tips(
                "error",
                "自动保存未成功，可按CTRL+S手动保存，如果仍无法保存，请做好备份工作，尝试刷新页面重新编辑<br>未保存原因：" +
                  res.data.message
              );
            }
          },
          "save.error": function (error, response) {
            that.lastKey = null;
            // console.log(res);
            // console.log("保存前：", that.articleId, that.content);
            that.updateSaveStatus("已保存到云端");
            that.tips(
              "error",
              "自动保存未成功，可按CTRL+S手动保存，如果仍无法保存，请做好备份工作，尝试刷新页面重新编辑<br>未保存原因：" +
                res.data.message
            );
          },
          click: function (clickEvent) {
            that.diashow = false;
            that.firstOpen = false;
            if (clickEvent.currentTarget.tagName === "IMG") {
              that.clickImgUrl =
                clickEvent.currentTarget.getAttribute("data-src");
            }
            if (getSelection().baseNode != null) {
              that.lastEditRange = getSelection().getRangeAt(0);
            }
            // 双击取消选择弹右键菜单
            that.clickCount += 1;
            if (that.clickCount > 1) {
              that.openMenu();
            }
            if (that.clickTimeOut) {
              window.clearTimeout(that.clickTimeOut);
            }
            that.clickTimeOut = window.setTimeout(() => {
              that.clickCount = 0;
            }, 200);
          },
          contentChanged: function () {
            that.changeCount += 1;
            // console.log(that.changeCount);
            if (
              that.firstOpen == false &&
              that.isImagePasteUpload == false &&
              that.pasteBefore == false
            ) {
              if (that.changeCount >= 2) {
                // that.updateSaveStatus("未保存");
                // that.$localForage.setItem(that.articleId, {
                //   articleId: that.articleId,
                //   content: that.content,
                // });
                if (that.timeOut) {
                  window.clearTimeout(that.timeOut);
                }
                that.timeOut = window.setTimeout(() => {
                  // console.log("保存1");
                  that.save();
                }, 500);
              }
            }
            // that.content = that.getSimpleText(that.content);
            // console.log("改变", that.changeCount);
            //console.log(that.content);
          },
          "image.beforeUpload": function (files) {
            // console.log(files);
            // 转base64
            // if (files.length) {
            //   console.log(files);
            //   // Create a File Reader.
            //   var reader = new FileReader();
            //   // Set the reader to insert images when they are loaded.
            //   reader.onload = function (e) {
            //     var result = e.target.result;
            //     that.editor.image.insert(
            //       result,
            //       null,
            //       null,
            //       that.editor.image.get()
            //     );
            //   };
            //   // Read image as base64.
            //   reader.readAsDataURL(files[0]);
            // }
            // that.editor.popups.hideAll();
            // // Stop default upload chain.
            return false;
          },
          "image.beforePasteUpload": function (img) {
            // console.log(img.getAttribute("data-fr-image-pasted"));
            that.isImagePasteUpload = true;
            var loading = that.$loading({
              target: "#editor-box",
              text: "图片上传中",
              background: "transparent",
            });
            if (img) {
              // console.log('dataUrl=-----', img);
              // console.log(that.pasteDataUrl);
              const dataUrl = img.outerHTML.replace(
                /(<img data-fr-image-pasted="true".*?)(\ssrc=")(.*?)(" .*?>)/g,
                "$3"
              );
              img.removeAttribute("data-fr-image-pasted");
              img.removeAttribute("id");

              var fileName = "pasteImg_" + Date.now() + ".png"; // 定义文件名字（例如：cober.png）
              var type = "image/png"; // 定义图片类型（canvas转的图片一般都是png，也可以指定其他类型）
              var file = that.base64ToBlob(dataUrl, type); // 调用base64转图片方法
              file.name = fileName;
              that.$api.upload
                .uploadFile(file, that.isLocal())
                .then((res) => {
                  img.setAttribute("data-src", res.fileUrl);
                  img.setAttribute("data-loaded", true);
                  // img.setAttribute('src', res.localUrl || res.fileUrl);
                  that.changeCount = 0;
                  setTimeout(() => {
                    that.isImagePasteUpload = false;
                    loading.close();
                    that.cleanHtml();
                    that.save();
                  }, 500);
                })
                .catch((err) => {
                  that.isImagePasteUpload = false;
                  loading.close();
                });
            }
            return false;
          },
          "image.beforeRemove": function ($img) {
            // console.log($img);
          },
          "image.inserted": function ($img, response) {
            // console.log($img, response);
          },
          "image.loaded": function ($img) {},
          "image.replaced": function ($img, response) {
            // console.log($img, response);
          },
          "commands.before": function (cmd, param1, param2) {
            // console.log(cmd, param1, param2);
          },
          destroy: () => {
            clearTimeout(this.initTimer);
            // console.log('-------destroy--')
          },
        },
      },
      lastTime: 0,
      refreshTime: 0,

      drawer: false,
      timeOut: null,
      isHistory: false,
      disabled: true,
      historyData: [],
      defaultProps: {
        label: "title",
      },
      currentId: "",
      historyTitle: "",
      historyContent: "",
      isUpload: false,
      isMath: false,
      mathDisabled: false,
      mathText: "",
      isTips: false,
      isMenu: false,
      // pasteImgDom: null,
      clickCount: 0,
      clickTimeOut: null,
      selectText: "",
      textColor: "#de4e4e",
      textColorBg: null,
      textBgColor: "#3c373b",
      textBgBgColor: "#f4e6b9",
      editorMenuButtons: {
        paste: true,
        tips: true,
        image: true,
        math: true,
        today: true,
        undo: true,
        redo: true,
        embInsertHR: true,
      },
      isWideScreen: "default",
      isTitleLine: "false",
      pSegmentSpacing: 0.8,
      pLineHeight: 1.5,
      tipsMenu: false,
      tipsMenuTime: null,
      firstOpen: true,
      isImagePasteUpload: false,
      timeInputTitle: null,
      timeOutTips: null,
      lastKey: null,
      pasteBefore: false,
      isAndroid: false,
      isiOS: false,

      isRefreshFinished: true,
      tipsMenuX: "150px",
      imgIndex: 0,
      imageList: [],
      clickImgUrl: "",
    };
  },
  inject: ["reload", "passTochatNav"],
  created() {
    this.isAndroid =
      navigator.userAgent.indexOf("Android") > -1 ||
      navigator.userAgent.indexOf("Adr") > -1;
    this.isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.setConfig();
  },
  computed: {
    editTitle: {
      get() {
        return this.title || "";
      },
      set(val) {
        this.$emit("update:title", val);
      },
    },
  },
  mounted() {
    window["historyBtn"] = () => {
      this.history();
    };
    window["changePastePlainBtn"] = () => {
      this.changePastePlain();
    };
    window["toolTopBtn"] = () => {
      this.changeToolBarTop();
    };
    window["toolInlineBtn"] = () => {
      this.changeToolBarInline();
    };
    window["toolBottomBtn"] = () => {
      this.changeToolBarBottom();
    };
    window["changeUploadBtn"] = () => {
      this.changeUpload();
    };
    window["changeMathBtn"] = () => {
      this.changeMath();
    };
    window["openSettingBtn"] = () => {
      this.openSetting();
    };
    // html2pdf
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.id = "html2pdf";
    // script.src =
    //   "https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js";
    // document.body.appendChild(script);

    // 获取引用信息
    this.initTipList();

    // this.initBridge();

    // 创建svg图标模板
    FroalaEditor.DefineIconTemplate("newIcon", "[PATH]");
    // 自定义图标
    FroalaEditor.DefineIcon("bold", {
      template: "newIcon",
      PATH: '<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H5.65603C6.83806 0 7.77187 0.268908 8.45745 0.806723C9.13121 1.34454 9.46809 2.08403 9.46809 3.02521C9.46809 3.69748 9.27896 4.28011 8.90071 4.77311C8.51064 5.21008 7.99645 5.52941 7.35816 5.73109C8.17376 5.86555 8.81797 6.19048 9.29078 6.70588C9.76359 7.22129 10 7.87115 10 8.65546C10 9.83193 9.56265 10.7059 8.68794 11.2773C7.93144 11.7591 6.90898 12 5.62057 12H0V0ZM1.4539 1.15966V5.2437H5.30142C6.17612 5.2437 6.84988 5.07003 7.32269 4.72269C7.79551 4.36415 8.03192 3.84314 8.03192 3.15966C8.03192 2.4874 7.80142 1.98319 7.34043 1.64706C6.89125 1.32213 6.21749 1.15966 5.31915 1.15966H1.4539ZM1.4539 6.38655V10.8403H5.49645C6.34752 10.8403 7.05083 10.6835 7.60638 10.3697C8.23286 10 8.5461 9.42297 8.5461 8.63866C8.5461 7.87675 8.27423 7.30532 7.7305 6.92437C7.23404 6.56583 6.48936 6.38655 5.49645 6.38655H1.4539Z" fill="#333333"/></svg>',
    });
    FroalaEditor.DefineIcon("italic", {
      template: "newIcon",
      PATH: '<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.40384 0H2.3637C2.28688 -1.36631e-09 2.21081 0.0127704 2.13983 0.0375819C2.06886 0.0623934 2.00437 0.0987602 1.95005 0.144606C1.89573 0.190452 1.85264 0.244879 1.82324 0.304779C1.79384 0.364679 1.77871 0.42888 1.77871 0.493716C1.77871 0.558551 1.79384 0.622752 1.82324 0.682653C1.85264 0.742553 1.89573 0.79698 1.95005 0.842825C2.00437 0.888671 2.06886 0.925038 2.13983 0.94985C2.21081 0.974661 2.28688 0.987431 2.3637 0.987431H4.11449L2.43909 11.0126H0.584992C0.429842 11.0126 0.281047 11.0646 0.17134 11.1572C0.0616328 11.2498 0 11.3753 0 11.5063C0 11.6372 0.0616328 11.7628 0.17134 11.8554C0.281047 11.948 0.429842 12 0.584992 12H5.62513C5.78028 12 5.92907 11.948 6.03878 11.8554C6.14849 11.7628 6.21012 11.6372 6.21012 11.5063C6.21012 11.3753 6.14849 11.2498 6.03878 11.1572C5.92907 11.0646 5.78028 11.0126 5.62513 11.0126H3.87435L5.54974 0.987431H7.41501C7.57016 0.987431 7.71895 0.935415 7.82866 0.842825C7.93837 0.750236 8 0.624657 8 0.493716C8 0.362774 7.93837 0.237196 7.82866 0.144606C7.71895 0.0520163 7.57016 0 7.41501 0H7.40384Z" fill="#333333"/></svg>',
    });
    FroalaEditor.DefineIcon("paragraphFormat", {
      template: "newIcon",
      PATH: '<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H1.35833V5.21008H8.19969V0H9.55802V12H8.19969V6.40336H1.35833V12H0V0Z" fill="#333333"/><path d="M14 3.42857V12H12.7103V5.0012C12.1897 5.4974 11.5429 5.85754 10.7698 6.08163V4.78511C11.1484 4.68908 11.5507 4.52101 11.9767 4.28091C12.4027 4.0088 12.7497 3.72469 13.0179 3.42857H14Z" fill="#333333"/></svg>',
    });
    FroalaEditor.DefineIcon("textColor", {
      template: "newIcon",
      PATH: '<svg width="16" height="16" viewBox="0 0 240 240" version="1.1" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0.000000, 0.500000)"><g transform="translate(39.000000, 17.353553)"><path d="M11,201.146447 L167,201.146447 C173.075132,201.146447 178,206.071314 178,212.146447 C178,218.221579 173.075132,223.146447 167,223.146447 L11,223.146447 C4.92486775,223.146447 7.43989126e-16,218.221579 0,212.146447 C-7.43989126e-16,206.071314 4.92486775,201.146447 11,201.146447 Z" id="矩形" fill="#E8323C" fill-rule="evenodd"></path><path d="M72.3425855,16.8295583 C75.799482,7.50883712 86.1577877,2.75526801 95.4785089,6.21216449 C100.284516,7.99463061 104.096358,11.7387855 105.968745,16.4968188 L106.112518,16.8745422 L159.385152,161.694068 C161.291848,166.877345 158.635655,172.624903 153.452378,174.531599 C148.358469,176.405421 142.719567,173.872338 140.716873,168.864661 L140.614848,168.598825 L89.211,28.86 L37.3759214,168.623816 C35.4885354,173.712715 29.8981043,176.351047 24.7909589,174.617647 L24.5226307,174.522368 C19.4337312,172.634982 16.7953993,167.044551 18.5287999,161.937406 L18.6240786,161.669077 L72.3425855,16.8295583 Z" id="路径-21" fill="#262626" fill-rule="nonzero"></path><path d="M121,103.146447 C126.522847,103.146447 131,107.623599 131,113.146447 C131,118.575687 126.673329,122.994378 121.279905,123.142605 L121,123.146447 L55,123.146447 C49.4771525,123.146447 45,118.669294 45,113.146447 C45,107.717207 49.3266708,103.298515 54.7200952,103.150288 L55,103.146447 L121,103.146447 Z" id="路径-22" fill="#262626" fill-rule="nonzero"></path></g></g></svg>',
    });
    FroalaEditor.DefineIcon("backgroundColor", {
      template: "newIcon",
      PATH: '<svg width="16" height="16" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="编组" fill="#262626"><g transform="translate(119.502295, 137.878331) rotate(-135.000000) translate(-119.502295, -137.878331) translate(48.002295, 31.757731)" id="矩形"><path d="M100.946943,60.8084699 L43.7469427,60.8084699 C37.2852111,60.8084699 32.0469427,66.0467383 32.0469427,72.5084699 L32.0469427,118.70847 C32.0469427,125.170201 37.2852111,130.40847 43.7469427,130.40847 L100.946943,130.40847 C107.408674,130.40847 112.646943,125.170201 112.646943,118.70847 L112.646943,72.5084699 C112.646943,66.0467383 107.408674,60.8084699 100.946943,60.8084699 Z M93.646,79.808 L93.646,111.408 L51.046,111.408 L51.046,79.808 L93.646,79.808 Z" fill-rule="nonzero"></path><path d="M87.9366521,16.90916 L87.9194966,68.2000001 C87.9183543,69.4147389 86.9334998,70.399264 85.7187607,70.4 L56.9423078,70.4 C55.7272813,70.4 54.7423078,69.4150264 54.7423078,68.2 L54.7423078,39.4621057 C54.7423078,37.2523513 55.5736632,35.1234748 57.0711706,33.4985176 L76.4832996,12.4342613 C78.9534987,9.75382857 83.1289108,9.5834005 85.8093436,12.0535996 C87.1658473,13.303709 87.9372691,15.0644715 87.9366521,16.90916 Z" fill-rule="evenodd"></path><path d="M131.3,111.241199 L11.7,111.241199 C5.23826843,111.241199 0,116.479467 0,122.941199 L0,200.541199 C0,207.002931 5.23826843,212.241199 11.7,212.241199 L131.3,212.241199 C137.761732,212.241199 143,207.002931 143,200.541199 L143,122.941199 C143,116.479467 137.761732,111.241199 131.3,111.241199 Z M124,130.241 L124,193.241 L19,193.241 L19,130.241 L124,130.241 Z" fill-rule="nonzero"></path></g></g><path d="M51,218 L205,218 C211.075132,218 216,222.924868 216,229 C216,235.075132 211.075132,240 205,240 L51,240 C44.9248678,240 40,235.075132 40,229 C40,222.924868 44.9248678,218 51,218 Z" id="矩形" fill="#FADB14"></path></svg>',
    });
    FroalaEditor.DefineIcon("lineHeight", {
      template: "newIcon",
      PATH: '<svg width="13" height="13" viewBox="0 0 1097 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M37.888 155.355429v-109.714286h1024.219429v109.714286H37.888zM476.891429 445.952v-109.714286h584.265142v109.714286H476.891429z m0.877714 225.718857v-109.714286h582.656v109.714286H477.622857zM40.228571 958.025143v-109.714286h1018.587429v109.714286H40.228571z m-40.301714-514.925714l181.833143-172.690286L363.52 443.172571H-0.073143z m1.974857 148.772571h363.300572L183.588571 762.660571 1.901714 591.872z"></path></svg>',
    });
    FroalaEditor.DefineIcon("clearFormatting", {
      template: "newIcon",
      PATH: '<svg width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M554.3 850.7L981 424c12.5-12.5 12.5-32.8 0-45.3L636.7 34.6c-12.5-12.5-32.8-12.5-45.3 0L90.5 535.5c-50 50-50 131 0 181l272.1 270.3c24 23.8 56.4 37.2 90.2 37.2H864c17.7 0 32-14.3 32-32s-14.3-32-32-32H599.5c-57 0-85.6-68.9-45.2-109.3z m71.1-736.9l276.4 276.4c6.2 6.2 6.2 16.4 0 22.6L607.9 706.6l-299-299 293.8-293.9c6.3-6.2 16.4-6.2 22.7 0.1zM135.8 580.7l127.9-127.9 299 299-127.9 127.9c-25 25-65.5 25-90.5 0L135.8 671.3c-25-25-25-65.6 0-90.6z"></path></svg>',
    });

    // FroalaEditor.RegisterCommand("btn3", {
    //   title: "有点重要",
    //   icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.3" y="0.3" width="15.4" height="15.4" rx="1.7" stroke="#C4C4C4" stroke-width="0.6"/><path d="M7.372 3.432H8.512L11.884 12H10.816L9.904 9.6H5.968L5.056 12H4L7.372 3.432ZM6.28 8.772H9.592L7.972 4.488H7.924L6.28 8.772Z" fill="#CF4C45"/></svg>',
    //   undo: true,
    //   focus: true,
    //   showOnMobile: true,
    //   refreshAfterCallback: false,
    //   callback: function () {
    //     this.format.applyStyle("color", "#CF4C45"); //加颜色
    //     this.format.applyStyle("font-weight", "bold"); //加粗
    //     // that.editor.commands.bold();
    //     // this.format.apply("span", { class: "little-important" });
    //   },
    // });
    FroalaEditor.RegisterCommand("btn1", {
      title: "重要内容",
      // icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="2" fill="#CF4C45"/><path d="M7.3339 3.004H8.6639L12.5979 13H11.3519L10.2879 10.2H5.6959L4.6319 13H3.3999L7.3339 3.004ZM6.0599 9.234H9.9239L8.0339 4.236H7.9779L6.0599 9.234Z" fill="#FFFFFF"/></svg>',
      icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.3" y="0.3" width="15.4" height="15.4" rx="1.7" stroke="#C4C4C4" stroke-width="0.6"/><path d="M7.372 3.432H8.512L11.884 12H10.816L9.904 9.6H5.968L5.056 12H4L7.372 3.432ZM6.28 8.772H9.592L7.972 4.488H7.924L6.28 8.772Z" fill="#de4e4e"/></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function (res) {
        that.moreImportant();
      },
    });
    FroalaEditor.RegisterCommand("btn2", {
      title: "非常重要",
      icon: '<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="2" fill="#F4E6B9"/><path d="M7.3339 3.004H8.6639L12.5979 13H11.3519L10.2879 10.2H5.6959L4.6319 13H3.3999L7.3339 3.004ZM6.0599 9.234H9.9239L8.0339 4.236H7.9779L6.0599 9.234Z" fill="#625125"/></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function (res) {
        // this.format.apply("span", { class: "more-important" });
        that.mostImportant();
      },
    });
    // FroalaEditor.RegisterCommand("PDF", {
    //   title: "导出为PDF",
    //   icon: '<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7,3C5.9,3,5,3.9,5,5v14c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V7.6L14.4,3H7z M17,19H7V5h6v4h4V19z M16.3,13.5  c-0.2-0.6-1.1-0.8-2.6-0.8c-0.1,0-0.1,0-0.2,0c-0.3-0.3-0.8-0.9-1-1.2c-0.2-0.2-0.3-0.3-0.4-0.6c0.2-0.7,0.2-1,0.3-1.5  c0.1-0.9,0-1.6-0.2-1.8c-0.4-0.2-0.7-0.2-0.9-0.2c-0.1,0-0.3,0.2-0.7,0.7c-0.2,0.7-0.1,1.8,0.6,2.8c-0.2,0.8-0.7,1.6-1,2.4  c-0.8,0.2-1.5,0.7-1.9,1.1c-0.7,0.7-0.9,1.1-0.7,1.6c0,0.3,0.2,0.6,0.7,0.6c0.3-0.1,0.3-0.2,0.7-0.3c0.6-0.3,1.2-1.7,1.7-2.4  c0.8-0.2,1.7-0.3,2-0.3c0.1,0,0.3,0,0.6,0c0.8,0.8,1.2,1.1,1.8,1.2c0.1,0,0.2,0,0.3,0c0.3,0,0.8-0.1,1-0.6  C16.4,14.1,16.4,13.9,16.3,13.5z M8.3,15.7c-0.1,0.1-0.2,0.1-0.2,0.1c0-0.1,0-0.3,0.6-0.8c0.2-0.2,0.6-0.3,0.9-0.7  C9,15,8.6,15.5,8.3,15.7z M11.3,9c0-0.1,0.1-0.2,0.1-0.2S11.6,9,11.5,10c0,0.1,0,0.3-0.1,0.7C11.3,10.1,11,9.5,11.3,9z M10.9,13.1  c0.2-0.6,0.6-1,0.7-1.5c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.2,0.3,0.7,0.7,0.9C12.2,12.8,11.6,13,10.9,13.1z M15.2,14.1  c-0.1,0-0.1,0-0.2,0c-0.2,0-0.7-0.2-1-0.7c1.1,0,1.6,0.2,1.6,0.6C15.5,14.1,15.4,14.1,15.2,14.1z" fill="#333333"></path></svg>',
    //   undo: true,
    //   focus: true,
    //   showOnMobile: true,
    //   refreshAfterCallback: false,
    //   callback: function () {
    //     // html2pdf(that.content);
    //     var element = document.getElementById("editor-box");
    //     html2pdf().from(element).save(that.title);
    //   },
    // });
    FroalaEditor.RegisterCommand("PastePlain", {
      title: "切换粘贴模式",
      icon: '<svg class="fr-svg" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15,10v8H9v-8H15 M14,4H9.9l-1,1H6v2h12V5h-3L14,4z M17,8H7v10c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V8z" fill="#333333"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: false,
      refreshAfterCallback: false,
      callback: () => {
        // 按钮执行
        this.changePastePlain();
      },
    });
    FroalaEditor.RegisterCommand("timage", {
      title: "插入图片",
      icon: '<svg width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.2,11l3.8,5H6l3-3.9l2.1,2.7L14,11H14.2z M8.5,11c0.8,0,1.5-0.7,1.5-1.5S9.3,8,8.5,8S7,8.7,7,9.5C7,10.3,7.7,11,8.5,11z   M22,6v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h16C21.1,4,22,4.9,22,6z M20,8.8V6H4v12h16V8.8z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function () {
        // 按钮执行
        // changePastePlainBtn();
        changeUploadBtn();
      },
    });
    FroalaEditor.RegisterCommand("cut", {
      title: "剪切",
      icon: '<svg width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M995.5584 839.0912 896 839.0912 896 355.5584c0-15.6928-12.7488-28.4416-28.4416-28.4416s-28.4416 12.7488-28.4416 28.4416l0 483.5584L225.1008 839.1168 930.3552 133.888c11.1104-11.1104 11.1104-29.1072 0-40.2176-11.136-11.1104-29.1584-11.1104-40.2432 0L184.8832 798.8992 184.8832 184.8832l469.3248 0c15.7184 0 28.416-12.7488 28.416-28.4416C682.6496 140.7488 669.952 128 654.2336 128L184.8832 128 184.8832 28.4416C184.8832 12.7488 172.16 0 156.4416 0 140.7488 0 128 12.7488 128 28.4416L128 128 28.4416 128C12.7488 128 0 140.7488 0 156.4416c0 15.6928 12.7488 28.4416 28.4416 28.4416L128 184.8832l0 682.6752C128 883.2512 140.7488 896 156.4416 896l682.6496 0 0 99.5584c0 15.6928 12.7488 28.4416 28.4416 28.4416S896 1011.2512 896 995.5584L896 896l99.5584 0C1011.2512 896 1024 883.2512 1024 867.5584S1011.2512 839.0912 995.5584 839.0912z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function () {
        document.execCommand("Cut");
        // that.cutBtn();
      },
    });
    FroalaEditor.RegisterCommand("copy", {
      title: "复制",
      icon: '<svg width="18" height="18" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M853.333333 224h-53.333333V170.666667c0-40.533333-34.133333-74.666667-74.666667-74.666667H170.666667C130.133333 96 96 130.133333 96 170.666667v554.666666c0 40.533333 34.133333 74.666667 74.666667 74.666667h53.333333V853.333333c0 40.533333 34.133333 74.666667 74.666667 74.666667h554.666666c40.533333 0 74.666667-34.133333 74.666667-74.666667V298.666667c0-40.533333-34.133333-74.666667-74.666667-74.666667zM160 725.333333V170.666667c0-6.4 4.266667-10.666667 10.666667-10.666667h554.666666c6.4 0 10.666667 4.266667 10.666667 10.666667v554.666666c0 6.4-4.266667 10.666667-10.666667 10.666667H170.666667c-6.4 0-10.666667-4.266667-10.666667-10.666667z m704 128c0 6.4-4.266667 10.666667-10.666667 10.666667H298.666667c-6.4 0-10.666667-4.266667-10.666667-10.666667v-53.333333H725.333333c40.533333 0 74.666667-34.133333 74.666667-74.666667V288H853.333333c6.4 0 10.666667 4.266667 10.666667 10.666667v554.666666zM576 416h-96V320c0-17.066667-14.933333-32-32-32s-32 14.933333-32 32v96H320c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h96V576c0 17.066667 14.933333 32 32 32s32-14.933333 32-32v-96H576c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function () {
        document.execCommand("Copy");
        // that.copyBtn();
      },
    });
    FroalaEditor.RegisterCommand("paste", {
      title: "粘贴",
      icon: '<svg width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M704 128l-128 0L576 64c0-35.2-28.8-64-64-64l-128 0c-35.2 0-64 28.8-64 64l0 64L192 128l0 128 512 0L704 128zM512 128l-128 0L384 64.128c0.032-0.032 0.064-0.064 0.128-0.128l127.776 0c0.032 0.032 0.096 0.064 0.128 0.128L512 128zM832 320 832 160c0-17.6-14.4-32-32-32l-64 0 0 64 32 0 0 128L384 320l0 448L128 768 128 192l32 0L160 128 96 128c-17.6 0-32 14.4-32 32l0 640c0 17.6 14.4 32 32 32l288 0 0 192 448 0 192-192L1024 320 832 320zM832 933.504 832 832l101.504 0L832 933.504zM960 768l-192 0 0 192L448 960 448 384l512 0L960 768z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function () {
        // document.execCommand("paste");
        that.pasteBtn();
      },
    });
    FroalaEditor.RegisterCommand("today", {
      title: "时间",
      icon: '<svg width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M1024 512C1024 229.230208 794.769792 0 512 0 229.230208 0 0 229.230208 0 512 0 794.769792 229.230208 1024 512 1024 625.316262 1024 733.09232 987.060258 821.44823 919.93747 832.170355 911.792017 834.259159 896.496821 826.113707 885.774697 817.968254 875.052572 802.67306 872.963767 791.950935 881.10922 712.006355 941.842033 614.569408 975.238095 512 975.238095 256.160663 975.238095 48.761905 767.839337 48.761905 512 48.761905 256.160663 256.160663 48.761905 512 48.761905 767.839337 48.761905 975.238095 256.160663 975.238095 512 975.238095 606.738266 946.765111 697.157764 894.355733 773.603714 886.741822 784.709602 889.572629 799.884996 900.678517 807.498908 911.784403 815.112819 926.959799 812.282012 934.573709 801.176124 992.505146 716.675526 1024 616.659703 1024 512ZM487.619049 609.52381C487.619049 622.989037 498.534771 633.904762 512 633.904762 525.465229 633.904762 536.380951 622.989037 536.380951 609.52381L536.380951 243.809523C536.380951 230.344297 525.465229 219.428572 512 219.428572 498.534771 219.428572 487.619049 230.344297 487.619049 243.809523L487.619049 609.52381ZM481.586633 471.564079C472.719716 461.430462 457.316742 460.403597 447.183125 469.270511 437.049508 478.137425 436.022643 493.540401 444.889559 503.674018L615.556226 698.721636C624.42314 708.855253 639.826114 709.882118 649.959731 701.015204 660.093348 692.148288 661.120213 676.745314 652.253299 666.611697L481.586633 471.564079Z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function () {
        that.insertHtml(that.today());
      },
    });
    FroalaEditor.RegisterCommand("tmath", {
      title: "插入数学公式",
      icon: '<svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg"><path d="M13.9521 0.971514C13.899 0.701384 13.7478 0.45692 13.5243 0.279771C13.3008 0.102623 13.0189 0.00375037 12.7265 0H1.23704C0.944679 0.00375037 0.662746 0.102623 0.439277 0.279771C0.215807 0.45692 0.0646264 0.701384 0.0114922 0.971514C-0.00383075 1.04881 -0.00383075 1.1281 0.0114922 1.2054V10.7946C0.00876781 11.1058 0.135232 11.4058 0.364046 11.6308C0.592859 11.8559 0.906011 11.9883 1.23704 12H12.7265C12.8946 12 13.061 11.9688 13.2161 11.9081C13.3712 11.8474 13.512 11.7585 13.6304 11.6464C13.7488 11.5344 13.8424 11.4014 13.9058 11.2552C13.9692 11.109 14.0012 10.9525 14 10.7946V1.2054C13.9998 1.12531 13.9835 1.04597 13.9521 0.971514ZM5.5456 7.1964V4.8036H8.41798V7.1964H5.5456ZM8.41798 8.4018V10.7946H5.5456V8.4018H8.41798ZM1.23704 4.8036H4.27218V7.1964H1.23704V4.8036ZM9.6914 4.8036H12.7265V7.1964H9.6914V4.8036ZM12.7265 1.2054V3.5982H1.23704V1.2054H12.7265ZM1.23704 8.4018H4.27218V10.7946H1.23704V8.4018ZM9.6914 10.7946V8.4018H12.7265V10.7946H9.6914Z" fill="#333333"/></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      // callback: function () {
      //   that.insertHtml(
      //     '<math-field virtual-keyboard-mode="manual"></math-field><p></p>'
      //   );
      // },
      callback: function (res, item) {
        // 按钮执行
        // changePastePlainBtn();
        this.position.refresh();
        changeMathBtn();
        var positon = that.getMouseLocation(res);
        if (positon.x > 300) {
          that.position.x = positon.x - 600 + "px";
        } else {
          that.position.x = positon.x + "px";
        }
        if (positon.y > 300) {
          that.position.y = positon.y - 42;
        } else {
          that.position.y = positon.y;
        }
        if (that.toolbarBottom == true) {
          that.position.y = that.position.y - 300;
        }
        that.position.y = that.position.y + "px";
      },
    });
    FroalaEditor.RegisterCommand("setting", {
      title: "更多",
      icon: '<svg width="16" height="16" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: false,
      refreshAfterCallback: false,
      callback: function () {
        // 按钮执行
        // openSettingBtn();
        that.openSetting("config");
      },
    });

    FroalaEditor.RegisterCommand("sumUp", {
      title: "总结",
      icon: '<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M1.6665 2.33301H5.33317C6.80594 2.33301 7.99984 3.52691 7.99984 4.99967V13.9997C7.99984 12.8951 7.1044 11.9997 5.99984 11.9997H1.6665V2.33301Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" style="fill: transparent"></path><path d="M14.3333 2.33301H10.6667C9.1939 2.33301 8 3.52691 8 4.99967V13.9997C8 12.8951 8.89543 11.9997 10 11.9997H14.3333V2.33301Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" style="fill: transparent"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function (res) {
        // this.format.apply("span", { class: "more-important" });
        that.queryChatgpt("sumUp", that.editor.selection.text());
      },
    });

    FroalaEditor.RegisterCommand("explain", {
      title: "解释",
      icon: '<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2.3335 12.3337C2.3335 9.76589 2.3335 3.66699 2.3335 3.66699C2.3335 2.56242 3.22893 1.66699 4.3335 1.66699H11.6668V10.3337C11.6668 10.3337 6.0777 10.3337 4.3335 10.3337C3.2335 10.3337 2.3335 11.2284 2.3335 12.3337Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" style="fill: transparent"></path><path d="M11.6668 10.333C11.6668 10.333 4.71806 10.333 4.3335 10.333C3.22893 10.333 2.3335 11.2284 2.3335 12.333C2.3335 13.4376 3.22893 14.333 4.3335 14.333C5.06986 14.333 8.62543 14.333 13.6668 14.333V2.33301" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.6665 12.333H11.3332" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" style="fill: transparent"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: false,
      callback: function (res) {
        // this.format.apply("span", { class: "more-important" });
        that.queryChatgpt("explain", that.editor.selection.text());
      },
    });
    FroalaEditor.RegisterCommand("imageZoom", {
      title: "放大",
      icon: '<svg class="fr-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704m-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64z"></path></svg>',
      undo: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: true,
      callback: function (res) {
        document.querySelector(".fr-popup").classList.remove("fr-active");
        that.imgZoom();
      },
    });

    // 自定义快捷键
    FroalaEditor.RegisterShortcut(49, "btn1", "", "1", false);
    FroalaEditor.RegisterShortcut(50, "btn2", "", "2", false);
    FroalaEditor.RegisterShortcut(51, "btn3", "", "3", false);
    FroalaEditor.RegisterShortcut(52, "ttips", "", "4", false);
    FroalaEditor.RegisterShortcut(67, "textColor", "", "C", false, true);
    FroalaEditor.RegisterShortcut(72, "backgroundColor", "", "H", false, true);
    // this.keyCodeForEvent();

    this.updateData();
    // this.contentEditor.outline(false)
    // console.log(document.getElementsByClassName('vditor-toolbar'))
    // console.log(this.articleId);
    // var loading = this.$loading();

    // const mf = document.querySelector("#formula");
    // mf.addEventListener("input", () => {
    //   this.mathText = mf.value;
    // });
  },
  watch: {
    articleId(val, old) {
      // newVal是新值，oldVal是旧值
      if (val !== old) {
        this.updateData();
      }
    },
    "$root.config.theme": {
      handler(val, old) {
        this.config.theme = val.includes("dark") ? "dark" : "default";
        this.refreshEditor();
      },
    },
    "$root.config": {
      handler(val, old) {
        if (val !== old) {
          if (this.$refs.froala) {
            this.$refs.froala.destroyEditor();
            this.editor = null;
          }
          this.setConfig();
          this.refreshEditor();
        }
      },
    },
    "$root.fontList": {
      handler(val, old) {
        if (val !== old) {
          this.initTipList();
          if (this.$refs.froala) {
            this.$refs.froala.destroyEditor();
            this.editor = null;
          }
          this.refreshEditor();
        }
      },
    },
    show(val, old) {
      if (this.config.saveParams.articleId !== this.articleId) {
        this.updateData();
      }
    },
    isMenu(val) {
      if (val) {
        document.addEventListener("click", this.closeRightMenu);
      } else {
        document.removeEventListener("click", this.closeRightMenu);
      }
    },
  },
  beforeUnmount() {
    this.$refs.froala?.destroyEditor();
    this.editor = null;
  },
  methods: {
    loadImg() {
      if (this.editor && this.editor.el) {
        this.$api.upload.loadImages(this.editor.el);
      }
    },
    getContent(content) {
      const sss = /<img[^>]* src=['"]([^'"]+)[^>]*>/gi;
      const ss = /<img [^>]*data-loaded=['"]([^'"]+)[^>]*>/gi;
      content = content.replace(ss, function (match, capture) {
        return match.replace(` data-loaded="true"`, "");
      });
      content = content.replace(sss, function (match, capture) {
        return match.replace(` src="${capture}"`, "");
      });
      return content;
    },
    async saveContent(content) {
      // let p = this.config.saveParams.articleParagraphModels;
      let saveResult = await this.$api.article.updateContent({
        articleId: this.articleId,
        // topArticleId: this.topArticleId,
        // articleParagraphModels: p ? JSON.parse(JSON.stringify(p)) : undefined,
        content: this.getContent(content),
      });
      if (saveResult) {
        this.saveContentSuccess();
      } else {
        this.saveContentFail();
      }
    },
    saveContentSuccess() {
      this.lastKey = null;
      if (this.isLocal()) {
        this.updateSaveStatus("你的更改全部保存在本地，请勿清理浏览器缓存。");
      } else {
        this.updateSaveStatus("已保存到云端");
      }
    },
    saveContentFail() {
      this.lastKey = null;
      this.updateSaveStatus("已保存到本地");
      this.$root.tips(
        "error",
        "网络连接异常。你可在离线状态继续编辑，内容将在联网后与云端同步。"
      );
    },
    isLocal() {
      return this.articleId && this.articleId.startsWith("local-");
    },
    refreshEditor() {
      this.isRefreshFinished = false;
      this.$nextTick(() => {
        this.isRefreshFinished = true;
      });
    },
    refresh() {
      this.reload();
    },
    getMouseLocation() {
      var event = event || window.event;
      var data = {};
      // console.log(event);
      if (event.x || event.y) {
        //兼容Mozilla浏览器
        data.x = event.x;
        data.y = event.y;
      } else if (event.offsetX || event.offsetY) {
        //适用非Mozilla浏览器
        data.x = event.offsetX;
        data.y = event.offsetY;
      } else if (event.layerX || event.layerY) {
        //兼容Mozilla浏览器
        data.x = event.layerX;
        data.y = event.layerY;
      }
      return data;
    },
    focusfont(item) {
      this.fontlist.forEach((element) => {
        element.isfouce = false;
      });
      item.isfouce = true;
    },
    updateData() {
      if (!this.show || !this.articleId) return;
      this.saveContentSuccess();
      if (this.$refs.froala) {
        this.$refs.froala.destroyEditor();
        this.editor = null;
      }
      this.loading = true;
      this.config.saveParams.articleId = this.articleId;
      let tempId = this.articleId;
      this.$api.article.articleDetail(this.articleId).then((res) => {
        if (tempId !== this.articleId) return;

        this.config.saveParams.topArticleId = this.topArticleId;
        this.changeCount = 0;
        if (res && res.data && res.data.content) {
          this.content = res.data.content || "";
        } else {
          this.content = "";
          this.changeCount = 1;
        }
        this.status = false;
        this.$nextTick(() => {
          this.status = true;
          this.loading = false;
        });
      });
    },
    titleFocus() {
      if (this.$root.cursorElement) {
        return;
      }
      this.$refs.editorTitle && this.$refs.editorTitle.focus();
    },
    setConfig() {
      const cfig = this.$root.config;
      if (cfig) {
        if (cfig.toolbarType === "default") {
          this.toolbarInline = true;
          this.toolbarBottom = false;
          this.config.toolbarContainer = null;
        } else if (cfig.toolbarType === "top") {
          this.toolbarInline = false;
          this.toolbarBottom = false;
          this.config.toolbarContainer = "#" + this.toolbarId;
        } else if (cfig.toolbarType === "bottom") {
          this.toolbarInline = false;
          this.toolbarBottom = true;
          this.config.toolbarContainer = "#" + this.toolbarId;
        }
        this.pastePlain = cfig.pastePlain.isTrue();
        this.config.toolbarButtons = cfig.toolbarButtons || [];
        this.config.theme = cfig.theme === "default" ? "default" : "dark";

        this.textColor = cfig.textColor;
        this.textColorBg = cfig.textColorBg;
        this.textBgColor = cfig.textBgColor;
        this.textBgBgColor = cfig.textBgBgColor;
        this.isWideScreen = cfig.isWideScreen;
        this.isTitleLine = cfig.isTitleLine;
        this.pSegmentSpacing = cfig.segmentSpacing;
        this.pLineHeight = cfig.lineHeight;

        this.config.toolbarContainer =
          this.toolbarInline === false ? "#" + this.toolbarId : null;

        if (this.pastePlain.isTrue()) {
          this.config.pasteDeniedAttrs = ["class", "id", "style"];
        } else {
          this.config.pasteDeniedAttrs = undefined;
        }

        this.config.pastePlain = this.pastePlain;
        this.config.toolbarInline = this.toolbarInline;
        this.config.toolbarBottom = this.toolbarBottom;

        if (this.mindVditor === true) {
          this.config.toolbarInline = true;
          this.config.toolbarBottom = false;
          this.config.toolbarContainer = null;
        }
        if (cfig.editorMenuButtons && cfig.editorMenuButtons.length > 0) {
          this.editorMenuButtons = [];
          for (let i = 0; i < cfig.editorMenuButtons.length; i++) {
            this.editorMenuButtons[cfig.editorMenuButtons[i]] = true;
          }
        }
      }
    },
    changePastePlain() {
      this.pastePlain = !this.pastePlain.isTrue();
      this.config.pastePlain = this.pastePlain;
      this.$root.config.pastePlain = this.pastePlain;
      var txt = "";
      var type = "success";
      if (this.pastePlain.isFalse()) {
        txt = "不清除格式";
        type = "error";
      } else {
        txt = "清除格式";
      }
      this.$message({
        message: "粘贴模式当前状态：" + txt,
        type: type,
      });
    },
    changeToolBarTop() {
      this.toolbarBottom = false;
      this.config.toolbarBottom = this.toolbarBottom;
      this.toolbarInline = false;
      this.config.toolbarInline = this.toolbarInline;
      this.config.toolbarContainer = "#" + this.toolbarId;
      this.$message({
        message: "工具栏模式当前状态：固定在顶部",
        type: "success",
      });
      // this.refresh();
      this.editorRefresh();
    },
    changeToolBarInline() {
      this.toolbarBottom = false;
      this.config.toolbarBottom = this.toolbarBottom;
      this.toolbarInline = true;
      this.config.toolbarInline = this.toolbarInline;
      this.config.toolbarContainer = null;
      this.$message({
        message: "工具栏模式当前状态：跟随光标",
        type: "success",
      });
      // this.refresh();
      this.editorRefresh();
    },
    changeToolBarBottom() {
      this.toolbarInline = false;
      this.config.toolbarInline = this.toolbarInline;
      this.toolbarBottom = true;
      this.config.toolbarBottom = this.toolbarBottom;
      this.config.toolbarContainer = "#" + this.toolbarId;
      this.$message({
        message: "工具栏模式当前状态：固定在底部",
        type: "success",
      });
      // this.refresh();
      this.editorRefresh();
    },
    onSaveTitle() {
      if (!this.editTitle) {
        this.$root.tips("error", "标题不能为空，请提炼模型");
        this.titleFocus();
      } else {
        //
        this.$emit("onSaveTitle", {
          id: this.articleId,
          title: this.editTitle,
        });
      }
    },
    updateSaveStatus(text) {
      this.saveStatus(text);
    },
    history() {
      this.historyTitle = "";
      this.historyContent = "";
      this.disabled = true;
      this.$api.article.historyArticleList(this.articleId).then((res) => {
        if (res.data.length > 0) {
          this.historyData = res.data;
          this.isHistory = true;
          this.NodeClick(res.data[0].id);
        } else {
          this.historyData = [];
          this.isHistory = false;
        }
        // 工具栏跟随模式 主动隐藏工具栏
        // this.editor.toolbar.hide();
        this.drawer = true;
      });
    },
    handleNodeClick(data, node, event) {
      this.$api.article.historyArticleDetail(data.id).then((res) => {
        this.historyTitle = res.data.title;
        this.historyContent = res.data.content;
        this.currentId = data.id;
        this.disabled = false;
      });
    },
    NodeClick(id) {
      this.$api.article.historyArticleDetail(id).then((res) => {
        this.historyTitle = res.data.title;
        this.historyContent = res.data.content;
        this.currentId = id;
      });
    },
    recoverArticle() {
      this.$api.article
        .historyRecoverArticle(this.currentId, this.articleId)
        .then(() => {
          this.updateData();
          this.historyClose();
        });
    },
    currentChange(data, node) {},
    historyClose() {
      this.drawer = false;
    },

    async initTipList() {
      var options = {};
      if (this.$root.fontList) {
        var data = this.$root.fontList;
        for (let i = 0; i < data.length; i++) {
          data[i].isfouce = false;
          options[i] = data[i].tip;
        }
        options.menu = "编辑出处···";
        this.fontlist = data;
      } else {
        this.fontlist = [];
        options = {
          menu: "编辑出处···",
        };
      }
      FroalaEditor.RegisterCommand("ttips", {
        title: "出处",
        icon: '<svg width="17" height="17" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M470.9888 261.77536l0 98.54976c0 13.3632-10.83392 24.19712-24.19712 24.19712-47.68768 0-73.6256 48.90624-77.21984 145.43872l77.21984 0c13.3632 0 24.19712 10.84416 24.19712 24.19712l0 208.0768c0 13.3632-10.83392 24.19712-24.19712 24.19712L240.90624 786.432c-13.37344 0-24.19712-10.84416-24.19712-24.19712l0-208.0768c0-46.27456 4.6592-88.73984 13.84448-126.22848 9.4208-38.44096 23.87968-72.04864 42.96704-99.90144 19.64032-28.6208 44.20608-51.07712 73.02144-66.72384 29.00992-15.73888 62.74048-23.72608 100.25984-23.72608C460.15488 237.57824 470.9888 248.41216 470.9888 261.77536zM783.09376 384.52224c13.3632 0 24.19712-10.84416 24.19712-24.19712l0-98.54976c0-13.3632-10.83392-24.19712-24.19712-24.19712-37.50912 0-71.23968 7.9872-100.2496 23.72608-28.81536 15.64672-53.39136 38.10304-73.03168 66.72384-19.08736 27.8528-33.54624 61.46048-42.96704 99.91168-9.17504 37.49888-13.83424 79.96416-13.83424 126.21824l0 208.0768c0 13.3632 10.83392 24.19712 24.19712 24.19712l205.8752 0c13.3632 0 24.19712-10.84416 24.19712-24.19712l0-208.0768c0-13.3632-10.83392-24.19712-24.19712-24.19712L706.9696 529.96096C710.51264 433.42848 736.07168 384.52224 783.09376 384.52224z"></path></svg>',
        undo: true,
        focus: true,
        showOnMobile: true,
        refreshAfterCallback: false,
        callback: function () {
          if (that.fontlist.length > 0) {
            // 记录光标最后位置
            if (getSelection().baseNode != null) {
              that.lastEditRange = getSelection().getRangeAt(0);
            }
            that.insertHtml(that.fontlist[0].tip);
          } else {
            this.position.refresh();
            // that.isTips = true;
            that.openSetting("tips");
            that.isMath = false;
          }
        },
      });
      FroalaEditor.RegisterCommand("ttips2", {
        title: "出处",
        type: "dropdown",
        icon: '<svg width="1" height="1" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m192 384 320 384 320-384z"></path></svg>',
        undo: true,
        focus: true,
        showOnMobile: true,
        refreshAfterCallback: false,
        options: options,
        callback: function (cmd, val) {
          // console.log(val);
          if (val === "menu") {
            this.position.refresh();
            // that.isTips = true;
            that.openSetting("tips");
            that.isMath = false;
          } else {
            if (that.fontlist.length > 0) {
              // 记录光标最后位置
              if (getSelection().baseNode != null) {
                that.lastEditRange = getSelection().getRangeAt(0);
              }
              that.insertHtml(that.fontlist[val].tip);
            } else {
              this.position.refresh();
              // that.isTips = true;
              that.openSetting("tips");
              that.isMath = false;
            }
          }
        },
        // Callback on refresh.
        refresh: function ($btn) {
          // console.log("do refresh");
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
          // console.log("do refresh when show");
        },
      });
      FroalaEditor.RegisterCommand("robots", {
        title: "AI 助手",
        type: "dropdown",
        icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3333 6H3.66667C3.29848 6 3 6.29848 3 6.66667V13.3333C3 13.7015 3.29848 14 3.66667 14H12.3333C12.7015 14 13 13.7015 13 13.3333V6.66667C13 6.29848 12.7015 6 12.3333 6Z" stroke="currentColor" style="fill: transparent"/><path d="M5.66667 9.33333C6.03486 9.33333 6.33333 9.03486 6.33333 8.66667C6.33333 8.29848 6.03486 8 5.66667 8C5.29848 8 5 8.29848 5 8.66667C5 9.03486 5.29848 9.33333 5.66667 9.33333Z" fill="currentColor"/><path d="M10.3334 9.33333C10.7016 9.33333 11.0001 9.03486 11.0001 8.66667C11.0001 8.29848 10.7016 8 10.3334 8C9.96522 8 9.66675 8.29848 9.66675 8.66667C9.66675 9.03486 9.96522 9.33333 10.3334 9.33333Z" fill="currentColor"/><path d="M6.66667 10.6665C6.29847 10.6665 6 10.965 6 11.3332C6 11.7014 6.29847 11.9998 6.66667 11.9998V10.6665ZM9.33333 11.9998C9.70153 11.9998 10 11.7014 10 11.3332C10 10.965 9.70153 10.6665 9.33333 10.6665V11.9998ZM6.66667 11.9998H9.33333V10.6665H6.66667V11.9998Z" fill="currentColor"/><path d="M8 3.3335V6.00016" stroke="currentColor" style="fill: transparent" stroke-linecap="round" stroke-linejoin="round"/><path d="M1.33325 8.6665V11.3332" stroke="currentColor" style="fill: transparent" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.6667 8.6665V11.3332" stroke="currentColor" style="fill: transparent" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.99992 3.33333C8.36811 3.33333 8.66659 3.03486 8.66659 2.66667C8.66659 2.29848 8.36811 2 7.99992 2C7.63173 2 7.33325 2.29848 7.33325 2.66667C7.33325 3.03486 7.63173 3.33333 7.99992 3.33333Z" stroke="currentColor" style="fill: transparent"/></svg>',
        undo: true,
        focus: true,
        showOnMobile: true,
        refreshAfterCallback: false,
        options: {
          abstract: "提炼模型",
          sumUp: "总结内容",
          createTag: "抓关键词",
          explain: "解释内容",
          example: "经典案例",
          counterExample: "举出反例",
          askQuestion: "问答模式",
        },
        html: function () {
          var e = '<ul class="fr-dropdown-list" role="presentation">',
            // t = this.opts.segmentSpacings;
            t = {
              abstract: "提炼模型",
              sumUp: "总结内容",
              createTag: "抓关键词",
              explain: "解释内容",
              example: "经典案例",
              counterExample: "举出反例",
              askQuestion: "问答模式",
            },
            //  class="robot-svg-color"
            img = {
              abstract:
                '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_214_1532)"><path class="robot-svg-color" fill-rule="evenodd" clip-rule="evenodd" d="M8 7.9987V6.33203L13 1.33203L14.6667 2.9987L9.66667 7.9987H8Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path class="robot-svg-color" d="M5.33301 8H2.99967C2.0792 8 1.33301 8.7462 1.33301 9.66667C1.33301 10.5871 2.0792 11.3333 2.99967 11.3333H12.9997C13.9201 11.3333 14.6663 12.0795 14.6663 13C14.6663 13.9205 13.9201 14.6667 12.9997 14.6667H5.99967" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_214_1532"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>',
              sumUp:
                '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="robot-svg-color" d="M9.66634 1.33203H2.99967C2.63148 1.33203 2.33301 1.63051 2.33301 1.9987V13.9987C2.33301 14.3669 2.63148 14.6654 2.99967 14.6654H12.333C12.7012 14.6654 12.9997 14.3669 12.9997 13.9987V6.6669" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path class="robot-svg-color" d="M4.33301 6H6.99967" stroke="#333333" stroke-width="1.33333" stroke-linecap="round"/><path class="robot-svg-color" d="M4.33301 9.33203H8.33301" stroke="#333333" stroke-width="1.33333" stroke-linecap="round"/><path class="robot-svg-color" d="M13.6662 2L9.66797 5.99827" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>',
              createTag:
                '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_8463_1290)"><path d="M16 0H0V16H16V0Z" fill="white" fill-opacity="0.01"></path><path d="M11.3335 3.33301L14.0002 5.99967" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.0002 3.33301L11.3335 5.99967" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.6667 10L12.3333 12.6667L11 11.3333" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path class="robot-svg-color" d="M8.66683 3.33301H1.3335V5.99967H8.66683V3.33301Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path class="robot-svg-color" d="M8.66683 10H1.3335V12.6667H8.66683V10Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_8463_1290"><rect width="16" height="16" fill="white"></rect></clipPath></defs></svg>',
              explain:
                '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_221_38)"><path class="robot-svg-color" d="M7.99967 14.6654C9.84061 14.6654 11.5073 13.9192 12.7137 12.7127C13.9201 11.5063 14.6663 9.83963 14.6663 7.9987C14.6663 6.15776 13.9201 4.4911 12.7137 3.28465C11.5073 2.07822 9.84061 1.33203 7.99967 1.33203C6.15874 1.33203 4.49207 2.07822 3.28563 3.28465C2.0792 4.4911 1.33301 6.15776 1.33301 7.9987C1.33301 9.83963 2.0792 11.5063 3.28563 12.7127C4.49207 13.9192 6.15874 14.6654 7.99967 14.6654Z" stroke="#333333" stroke-width="1.33333" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 3.66797C8.46056 3.66797 8.83366 4.04107 8.83366 4.5013C8.83366 4.96154 8.46056 5.33464 8.00033 5.33464C7.54009 5.33464 7.16699 4.96154 7.16699 4.5013C7.16699 4.04107 7.54009 3.66797 8.00033 3.66797Z" fill="#333333"/><path class="robot-svg-color" d="M8.16667 11.3346V6.66797H7.83333H7.5" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path class="robot-svg-color" d="M7 11.332H9.33333" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_221_38"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>',
              example:
                '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect class="robot-svg-color" x="1.665" y="1.665" width="12.67" height="12.67" rx="3.335" stroke="#333333" stroke-width="1.33"/><path class="robot-svg-color" d="M7.71297 3.96584C7.84317 3.74949 8.15683 3.74949 8.28703 3.96584L8.85757 4.91388C9.09005 5.30018 9.46925 5.57568 9.90848 5.67741L10.9864 5.92707C11.2324 5.98404 11.3294 6.28235 11.1638 6.47303L10.4385 7.30861C10.1429 7.64908 9.9981 8.09486 10.0371 8.54403L10.1327 9.64637C10.1546 9.89793 9.90082 10.0823 9.66832 9.98379L8.6495 9.55217C8.23436 9.37629 7.76564 9.37629 7.3505 9.55217L6.33168 9.98379C6.09918 10.0823 5.84542 9.89793 5.86725 9.64637L5.96292 8.54403C6.0019 8.09486 5.85706 7.64908 5.56151 7.30861L4.83617 6.47303C4.67065 6.28235 4.76758 5.98404 5.01357 5.92707L6.09152 5.67741C6.53075 5.57568 6.90995 5.30018 7.14243 4.91388L7.71297 3.96584Z" stroke="#333333" stroke-width="1.33"/><rect class="robot-svg-color-b" x="5" y="11.3984" width="6" height="1.33" rx="0.665" fill="#333333"/></svg>',
              counterExample:
                '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="robot-svg-color" d="M8 1.66797H2V12.3346H8V1.66797Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path class="robot-svg-color" d="M14 3.66797H8V14.3346H14V3.66797Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path class="robot-svg-color" d="M5.66634 5.66797L4.33301 6.93114L5.55524 8.33464" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/><path class="robot-svg-color" d="M10.333 7.66797L11.6663 8.93114L10.4441 10.3346" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>',
              askQuestion:
                '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_219_27)"><rect width="16" height="16" fill="white" style="fill: transparent"/><path class="robot-svg-color" d="M3.165 1.5C3.165 1.31498 3.31498 1.165 3.5 1.165H14.5C14.685 1.165 14.835 1.31498 14.835 1.5V11.0858C14.835 11.3842 14.4742 11.5337 14.2631 11.3227L13.0131 10.0727C12.7009 9.76042 12.2774 9.585 11.8358 9.585H9H3.5C3.31498 9.585 3.165 9.43502 3.165 9.25V1.5Z" fill="white" stroke="#333333" stroke-width="1.33"/><path class="robot-svg-color" d="M1.165 8.5C1.165 8.31498 1.31498 8.165 1.5 8.165H7.5C7.68502 8.165 7.835 8.31498 7.835 8.5V13.1667C7.835 13.3517 7.68502 13.5017 7.5 13.5017H4.5H3.5C2.97593 13.5017 2.48244 13.7484 2.168 14.1677L1.768 14.701C1.57484 14.9586 1.165 14.8219 1.165 14.5V8.5Z" fill="white" stroke="#333333" stroke-width="1.33"/><path class="robot-svg-color" d="M7.5 4.5V3.5L9 3L10.5 3.5V5L9 6V6.5M9 8V7.5V7" stroke="#333333"/><path class="robot-svg-color" d="M4.5 9.5V11M4.5 12.5V12V11.5" stroke="#333333"/></g><defs><clipPath id="clip0_219_27"><rect width="16" height="16" fill="white" style="fill: transparent"/></clipPath></defs></svg>',
            };
          for (var i in t)
            Object.prototype.hasOwnProperty.call(t, i) &&
              (e += '<li role="presentation"><a class="fr-command '
                .concat(
                  i,
                  '" tabIndex="-1" role="option" data-cmd="robots" data-param1="'
                )
                .concat(i, '" title="')
                .concat(t[i], '">')
                .concat(img[i], "<span>")
                .concat(t[i], "</span></a></li>"));
          return (e += "</ul>");
        },

        callback: function (cmd, val) {
          switch (val) {
            case "abstract":
              that.queryChatgpt("abstract", that.editor.selection.text());
              break;
            case "sumUp":
              that.queryChatgpt("sumUp", that.editor.selection.text());
              break;
            case "createTag":
              that.queryChatgpt("createTag", that.editor.selection.text());
              break;
            case "explain":
              that.queryChatgpt("explain", that.editor.selection.text());
              break;
            case "example":
              that.queryChatgpt("example", that.editor.selection.text());
              break;
            case "counterExample":
              that.queryChatgpt("counterExample", that.editor.selection.text());
              break;
            case "askQuestion":
              that.queryChatgpt("askQuestion", that.editor.selection.text());
              break;
            default:
              break;
          }
        },
        // Callback on refresh.
        refresh: function ($btn) {
          // console.log("do refresh");
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
          // console.log("do refresh when show");
        },
      });
      FroalaEditor.RegisterCommand("flashThought", {
        title: "闪念",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M6.33335 1.33325H12.3334L8.66669 5.99992H13.6667L5.66669 14.6666L7.33335 8.33325H2.66669L6.33335 1.33325Z" fill="currentColor" stroke="currentColor" stroke-width="1.33" stroke-linejoin="round"/></svg>',
        undo: true,
        focus: true,
        showOnMobile: false,
        refreshAfterCallback: false,
        callback: function () {
          that.passTochatNav("flashThought", that.editor.selection.text());
        },
      });
      FroalaEditor.RegisterCommand("transfer", {
        title: "中转",
        icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1605_733)"><circle cx="8" cy="8" r="7.335" stroke="currentColor" stroke-width="1.33"/><path class="robot-svg-color" d="M8 0.700195C9 0.700195 11.5 1.32763 11.5 4.50024C11.5 7.00024 9 8.00024 8 8.00024C7 8.00024 4.5 8.70024 4.5 11.5002C4.5 14.3002 7 15.3002 8 15.3002" stroke="currentColor" stroke-width="1.2"/><circle cx="8" cy="4.5" r="1" fill="currentColor"/><circle cx="8" cy="11.5" r="1" fill="currentColor"/></g><defs><clipPath id="clip0_1605_733"><rect width="16" height="16" fill="currentColor"/></clipPath></defs></svg>',
        undo: true,
        focus: true,
        showOnMobile: false,
        refreshAfterCallback: false,
        callback: function () {
          that.passTochatNav("transfer", that.editor.selection.text());
        },
      });
    },
    saveTips() {
      var sort = 0;
      if (this.$root.fontlist.length > 0) {
        sort = this.$root.fontlist.length;
      }
      this.$api.article
        .saveTip({
          tip: this.tipsinput,
          sort: sort,
        })
        .then((res) => {
          this.$root.fontList.push({
            sort: sort,
            id: res.data,
            tip: this.tipsinput,
          });
          this.tipsinput = "";
        });
    },
    removeTip(id) {
      this.$api.article.removeTip(id).then((res) => {
        let idx = this.$root.fontList.findIndex((r) => r.id === id);
        if (idx !== -1) {
          this.$root.fontList.splice(idx, 1);
        }
      });
    },
    insertHtml(content) {
      // 将当前HTML保存在撤消堆栈中
      this.editor.undo.saveStep();
      //设置编辑器获取焦点
      // this.editor.events.focus();
      // 获取选定对象
      const selection = getSelection();
      // 判断是否有最后光标对象存在
      if (this.lastEditRange) {
        // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
        selection.removeAllRanges();
        selection.addRange(this.lastEditRange);
      }
      // 双击默认有选中文字，弹菜单进行操作后会吞掉选中文字，这里重新加回
      if (this.selectText != "") {
        this.editor.html.insert(this.selectText);
        this.selectText = "";
      }
      //插入内容
      this.editor.html.insert(content);
      // 手动保存
      // console.log("保存3");
      this.save();
      this.save();

      //记录最后光标停留位置
      this.lastEditRange = selection.getRangeAt(0);
      this.diashow = false;
      this.isTips = false;
      this.editor.undo.saveStep();
    },
    changeUpload() {
      this.isUpload = true;
      this.isMath = false;
    },
    changeMath() {
      this.isMath = !this.isMath;
    },
    // 自定义上传操作
    handleUpload(op) {
      this.$api.upload
        .uploadFile(op.file, this.isLocal(), (percent) => {
          op.onProgress(percent);
        })
        .then((res) => {
          op.onSuccess({ ...res });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleExceed(files, fileList) {
      this.$message({
        message: `当前限制选择 20 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        type: "warning",
      });
    },

    handleSuccess(response, file, files) {
      this.isUpload = false;
      this.insertHtml(
        `<p><img data-src="${response.fileUrl}" data-loaded="true" src="${response.localUrl}"/></p>`
      );
      this.loadImg();
    },
    insertMath() {
      const loading = this.$loading({
        text: "加载中",
      });
      this.mathDisabled = true;
      // var truecontent=gtx.shadowRoot.getElementById("translation");
      // var copyDom = this.$refs.ff.cloneNode(true); //克隆dom节点
      var gtx = document.getElementById("formula");
      var dom = gtx.shadowRoot.querySelector(".ML__container");
      // console.log(this.mathText);
      // this.insertHtml(
      //   "<math-field disabled>" + this.mathText + "</math-field><p></p>"
      // );
      // console.log(dom);
      var copyDom = dom.cloneNode(true);
      var keyboard = copyDom.getElementsByClassName(
        "ML__virtual-keyboard-toggle"
      );
      keyboard[0].parentNode.removeChild(keyboard[0]); // 删除键盘div
      copyDom.style.position = "absolute";
      copyDom.style.top = "0px";
      copyDom.style.margin = "10px";
      copyDom.style.zIndex = "-100";
      document.body.appendChild(copyDom); //把copy的截图对象追加到body后面

      var rect = copyDom.getBoundingClientRect(); //获取元素相对于视察的偏移量

      var width = rect.width; //dom宽
      var height = rect.height + 10; //dom高
      // var height = copyDom.clientHeight; //dom高

      // console.log(width, height);
      // console.log(rect);

      var scale = 2.5; //放大倍数
      var canvas = document.createElement("canvas"); //创建画布
      canvas.width = width * scale; //canvas宽度
      canvas.height = height * scale; //canvas高度
      var ctx = canvas.getContext("2d");
      ctx.scale(scale, scale);

      html2canvas(copyDom, {
        scale: 1,
        canvas: canvas,
        width: width * scale,
        height: height * scale,
        background: "#fff",
        scrollY: 0,
        scrollX: 0,
        x: 0,
        y: 0,
      })
        .then((canvas) => {
          let dataUrl = canvas.toDataURL("image/jpg");
          // console.log(dataUrl);
          // this.testImg = dataUrl;
          var fileName = "math_" + Date.now() + ".png"; // 定义文件名字（例如：cober.png）
          var type = "image/png"; // 定义图片类型（canvas转的图片一般都是png，也可以指定其他类型）
          var file = this.base64ToBlob(dataUrl, type); // 调用base64转图片方法
          file.name = fileName;
          // console.log(file);
          this.$api.upload
            .uploadFile(file, this.isLocal())
            .then((res) => {
              this.isMath = false;
              loading.close();
              this.mathDisabled = false;
              this.insertHtml(
                `<p><img data-src="${res.fileUrl}" data-loaded="true" src="${res.localUrl}"/></p>`
              );
            })
            .catch((err) => {
              setTimeout(() => {
                loading.close();
                this.mathDisabled = false;
              }, 500);
              this.$message({
                message: "未知错误，尝试重新插入，仍无效刷新后尝试",
                type: "warning",
              });
            });
          copyDom.remove();
        })
        .catch((err) => {
          setTimeout(() => {
            loading.close();
            this.mathDisabled = false;
          }, 500);
          copyDom.remove();
          this.$message({
            message: "未知错误，尝试重新插入，仍无效刷新后尝试",
            type: "warning",
          });
        });
      // console.log(document.getElementsByClassName("formula"));
    },
    base64ToBlob(urlData, type) {
      let arr = urlData.split(",");
      let mime = arr[0].match(/:(.*?);/)[1] || type;
      let bytes = window.atob(arr[1]);
      let ab = new ArrayBuffer(bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], {
        type: mime,
      });
    },
    insertMindImg(dataURL) {
      const loading = this.$loading({
        text: "加载中",
      });
      var fileName = "mind_" + Date.now() + ".png"; // 定义文件名字（例如：cober.png）
      var type = "image/png"; // 定义图片类型（canvas转的图片一般都是png，也可以指定其他类型）
      var file = this.base64ToBlob(dataURL, type); // 调用base64转图片方法
      file.name = fileName;
      // console.log(file);
      this.$api.upload
        .uploadFile(file, this.isLocal())
        .then((res) => {
          loading.close();
          this.insertHtml(
            `<p><img data-src="${res.fileUrl}" data-loaded="true" src="${res.localUrl}"/></p>`
          );
        })
        .catch((err) => {
          setTimeout(() => {
            loading.close();
          }, 500);
          this.$message({
            message: "未知错误，尝试重新插入，仍无效刷新后尝试",
            type: "warning",
          });
        });
    },
    datadragEnd(evt) {
      evt.preventDefault();
      // console.log("拖动前的索引 :" + evt.oldIndex);
      // console.log("拖动后的索引 :" + evt.newIndex);
      // console.log(evt);
      // console.log(this.fontlist);
      for (let i = 0; i < this.fontlist.length; i++) {
        this.$api.article.saveTip({
          tipId: this.fontlist[i].id,
          tip: this.fontlist[i].tip,
          sort: i,
        });
      }
      // 遍历数组,将索引值赋值到对应的sort_order上面,完成排序
      //   let arr = this.fontlist;
      //   let newArr = await arr.map((item, i) => {
      //     return {
      //       sort_order: i,
      //      field_code: item.field_code
      //    };
      //  });
      //  const res = await this.$axios.post(`customer/save_order`, {
      //    list: newArr
      //  });
      // console.log(res)
      //  const { error, message } = res.data;
      //  if (error == 0) {
      //    this.$message.success(message);
      //  }
    },
    openSetting(configActive = "config") {
      this.$root.configActive = configActive;
      this.$root.configVisible = true;
      // this.refreshEditor();
    },

    //富文本中提取纯文本
    getSimpleText(html) {
      var re1 = new RegExp('<p data-f-id="pbf".+?</p>', "g");
      var msg = html.replace(re1, "");
      return msg;
    },
    tipsClose() {
      this.editorRefresh();
    },
    closeRightMenu() {
      this.isMenu = false;
      // 及时关掉鼠标监听事件
      // document.removeEventListener("click", this.closeRightMenu);
    },
    handleOpen(index) {
      // if (this.lastKeyCode == 191) {
      //   this.editor.cursor.backspace();
      // }
    },
    openMenu(event) {
      this.selectText = this.editor.selection.text();
      // console.log(this.isAndroid);
      if (this.selectText != "" && this.isAndroid == false) {
        this.editor.selection.clear();
      }
      if (getSelection().baseNode != null) {
        this.lastEditRange = getSelection().getRangeAt(0);
      }
      this.lastKeyCode = 191;
      if (!event) {
        event = window.event;
      }
      let menu = this.$refs.editorMenu;
      let x = event.clientX + 15;
      let y = event.clientY + 15;
      let mx = menu.offsetWidth == 0 ? 152 : menu.offsetWidth;
      let my = menu.offsetHeight == 0 ? 366 : menu.offsetHeight;
      if (event.clientX + mx > window.innerWidth) {
        x = window.innerWidth - mx;
      }
      if (event.clientY + my > window.innerHeight) {
        y = window.innerHeight - my;
      }
      if (event.clientX + 310 > window.innerWidth) {
        this.tipsMenuX = "-160px";
      } else {
        this.tipsMenuX = "150px";
      }
      this.position.x = x + "px";
      this.position.y = y + "px";
      // document.addEventListener("click", this.closeRightMenu);
      setTimeout(() => {
        this.isMenu = true;
      }, 100);
    },
    tipsRightBtn() {
      this.tipsMenu = false;
      if (this.fontlist.length > 0) {
        this.insertHtml(this.fontlist[0].tip);
      } else {
        this.isTips = true;
        this.isMath = false;
      }
    },
    tipsRightSubMenu(tip) {
      this.tipsMenu = false;
      this.isMenu = false;
      this.insertHtml(tip);
    },
    undoRightBtn() {
      this.editor.commands.undo();
    },
    redoRightBtn() {
      this.editor.commands.redo();
    },
    moreImportant() {
      // this.editor.format.applyStyle("color", "#be1a1d");
      // this.editor.format.applyStyle("color", "#D14841");
      this.editor.format.applyStyle("color", this.textColor);
      if (this.textColorBg != null) {
        this.editor.format.applyStyle("background-color", this.textColorBg);
        this.editor.format.applyStyle("border-radius", "4px");
      }
      this.editor.format.applyStyle("font-weight", "bold");
    },
    mostImportant() {
      this.editor.format.applyStyle("color", this.textBgColor); //"#3c373b"
      this.editor.format.applyStyle("background-color", this.textBgBgColor);
      this.editor.format.applyStyle("border-radius", "4px");
    },
    // cutBtn() {
    //   //获取权限
    //   navigator.permissions
    //     .query({
    //       name: "clipboard-read",
    //     })
    //     .then((result) => {
    //       if (result.state == "granted" || result.state == "prompt") {
    //         //读取剪贴板
    //         navigator.clipboard.read().then((data) => {
    //           console.log(data, data[0].getType("text/html"));
    //         });
    //       } else {
    //         alert("请允许读取剪贴板！");
    //       }
    //     });
    // },
    // async copyBtn() {
    //   await navigator.clipboard.write();
    // },
    async pasteBtn() {
      var data = await this.getClipboardText();
      if (this.config.pastePlain.isTrue()) {
        data.textHtml = data.textHtml.replace(/'/gim, '"');
        data.textHtml = data.textHtml
          .replace(/style=".*?"/gim, "")
          .replace(/id=".*?"/gim, "")
          .replace(/class=".*?"/gim, "")
          .replace(/data-lark-record-data=".*?"/gim, "");
        this.insertHtml(data.textHtml);
      } else {
        this.insertHtml(data.textHtml);
      }
      // console.log(data.textHtml);
    },
    today() {
      //构建方法
      var today = new Date(); //new 出当前时间
      var h = today.getFullYear() + ""; //获取年
      var m = today.getMonth() + 1 + ""; //获取月
      var d = today.getDate() + ""; //获取日
      var H = today.getHours() + ""; //获取时
      var M = today.getMinutes() + ""; //获取分
      var S = today.getSeconds() + ""; //获取秒
      return (
        h +
        "-" +
        m.padStart(2, "0") +
        "-" +
        d.padStart(2, "0") +
        " " +
        H.padStart(2, "0") +
        ":" +
        M.padStart(2, "0") +
        ":" +
        S.padStart(2, "0")
      ); //返回 年-月-日 时:分:秒
    },
    async getClipboardText() {
      if (!window.navigator.clipboard) {
        this.$root.tips("error", "浏览器不支持粘贴");
        return "";
      }
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-read",
      });
      console.log("permissionStatus---", permissionStatus);
      const clipboardItems = await window.navigator.clipboard.read();
      let textHtml, textPlain;
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          const item = await clipboardItem.getType(type);
          if (item && item.type === "text/html") {
            textHtml = await item.text();
          }
          if (item && item.type === "text/plain") {
            textPlain = await item.text();
          }
        }
      }
      // console.log(textPlain);
      return { textHtml, textPlain };
    },
    tipsMenuShow() {
      this.tipsMenuTime = window.setTimeout(() => {
        this.tipsMenu = true;
      }, 500);
    },
    tipsMenuHidden() {
      window.clearTimeout(this.tipsMenuTime);
      // this.tipsMenu = false;
    },
    tipsSubMenuShow() {
      this.tipsMenu = true;
    },
    tipsSubMenuHidden() {
      this.tipsMenu = false;
    },
    setArticleParagraphModels() {
      localStorage.setItem(
        "articleParagraphModels",
        '[{"id":null,"title":null,"content":null}]'
      );
      if (getSelection().baseNode != null) {
        // 获取光标位置文本
        var content = "";
        var selectDom = null;
        if (this.lastKey === "enter") {
          selectDom = this.editor.selection.blocks()[0].previousSibling;
        } else {
          // content = getSelection().getRangeAt(0).commonAncestorContainer.data;
          selectDom = this.editor.selection.blocks()[0];
          // console.log(this.editor.selection.blocks().innerHTML);
        }
        if (selectDom && selectDom.innerHTML) {
          content = selectDom.innerHTML;
        }
        if (content) {
          // 去除零宽字符
          content = content.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "");
          if (content == "<br>") {
            content = "";
          }
          var paragraphId = 1;
          while ((selectDom = selectDom.previousSibling) != null) paragraphId++;
          var title = this.editTitle;
          if (title == "") {
            title = "新建模型";
          }
          this.config.saveParams.articleParagraphModels = [
            {
              id: paragraphId,
              title: title,
              content: content,
            },
          ];
          localStorage.setItem(
            "articleParagraphModels",
            JSON.stringify(this.config.saveParams.articleParagraphModels)
          );
        }
      }
    },
    insertHRBtn() {
      // this.editor.commands.insertHR();
      this.insertHtml("<hr><p><br></p>");
    },
    cleanHtml() {
      const $ = cheerio.load(this.editor.html.get());
      if ($("span[data-lark-record-data]").length > 0) {
        $("span[data-lark-record-data]").remove();
        var html = $.html()
          .replace(/<html><head><\/head><body>/g, "")
          .replace(/<\/body><\/html>/g, "")
          .replace(/white-space:pre;/g, "")
          .replace(/<div/g, "<p")
          .replace(/<\/div>/g, "</p>");
        this.editor.html.set(html);
      }
    },
    save() {
      if (this.editor && this.editor.save && this.editor.save.save) {
        this.editor.save.save();
      } else {
        proxyApi.log({
          key: '"editor no exist:"',
          c1: !!this.editor,
          c2: !!(this.editor && this.editor.save),
          c3: !!(this.editor && this.editor.save && this.editor.save.save),
        });
      }
    },
    imgZoom() {
      this.imageList = [];
      const $ = cheerio.load(this.editor.html.get());
      for (let i = 0; i < $("img").length; i++) {
        this.imageList[i] = $("img")[i].attribs["data-src"];
      }
      let idx = this.imageList.indexOf(this.clickImgUrl);
      if (idx != -1) {
        this.imgIndex = idx;
      } else {
        this.imgIndex = 0;
      }
      this.$hevueImgPreview({
        multiple: true, // 开启多图预览模式
        nowImgIndex: this.imgIndex,
        imgList: this.imageList,
        mainBackground: "rgba(0, 0, 0, .8)", // 整体背景颜色
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.vditor-history {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #eaecef;
  padding: 5px 6px;
}

.vditor {
  border: 0 !important;
  padding-top: 0;
  background-color: #fafbfc;
}

:deep .vditor > .vditor-toolbar {
  visibility: var(--pinshow) !important;
  position: absolute !important;
  top: var(--toolbartop) !important;
  left: var(--toolbarleft) !important;
  padding: 0px 15px !important;
  z-index: 999 !important;
  box-shadow: 0px 0px 2px 2px rgba(142, 142, 142, 0.25);
  border-radius: 8px;
  mix-blend-mode: normal;
  /* pointer-events: none */
}

:deep .vditor > .vditor-content {
  margin-top: 0;
}

:deep .vditor > .vditor-panel--none {
  display: none !important;
}

:deep .vditor > .vditor {
  --toolbar-height: 45px;
  --toolbar-background-color: #ffffff;
}

:deep .vditor > .vditor-reset {
  padding: 0 20px !important;
  background-color: #ffffff !important;
}

:deep .el-drawer__body {
  padding: 0 !important;
}

.btn-recover {
  color: #fff;
  background: #3370ff;
  border-color: #3370ff;
  height: 32px;
  line-height: 22px;
  padding: 4px 11px;
  font-size: 14px;
  border-radius: 6px;
  min-width: 80px;
}

:deep .el-main {
  padding: 0;
}

:deep .el-tree-node__content {
  height: 80px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  border-left: 3px solid transparent;
  line-height: 25px;

  &:hover,
  &[active] {
    background: rgba(19, 128, 255, 0.08) !important;
    color: #1380ff !important;
    border-left: 3px solid #3370ff !important;
    border-radius: 8px;
  }
}

:deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  border-left: 3px solid #3370ff !important;
}

.history-list {
  margin-left: 37px;
  height: 80px;
  width: 100%;
  padding-top: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 1px solid var(--color-border);
}

.history-list-title {
  font-size: 12px;
  width: 100%;
}

:deep .fr-wrapper:nth-child(2) {
  display: none !important;
}

:deep .fr-wrapper > div[style*="z-index: 9999"] {
  /*position: absolute;
        top: -10000px;
        opacity: 0;*/
  display: none !important;
}

:deep .el-input__inner::placeholder {
  color: #aaa;
  font-weight: 400;
}

:deep .el-divider--horizontal {
  margin: 10px 0;
}

.diashowstyle {
  box-shadow: 0px 0px 4px 1px rgba(118, 118, 118, 0.25);
  border-radius: 4px;
  z-index: 999;
  // background-color: white;
  display: block;
}

.editor-right-menu {
  // box-shadow: 0px 0px 4px 1px rgba(118, 118, 118, 0.25);
  border-radius: 8px;
  z-index: 8;
  // background: white;
  padding: 5px;
  display: block;
  position: fixed;
  width: 60px;

  :deep .el-menu {
    border-right: 0;
  }

  :deep .el-menu-item {
    height: 36px;
    padding: 0 5px !important;
    border-radius: 8px;
  }
}

:deep .little-important {
  color: #cf4c45;
  font-weight: bold;
}

:deep .el-input__wrapper {
  box-shadow: 0 0 0 0;
  padding: 0;
}

:deep .fr-toolbar {
  // background: #e8f3ff;
  border: 0;
}

:deep .fr-toolbar.fr-top {
  // border-radius: 16px 16px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  border: 0;
}

:deep .fr-box.fr-basic .fr-wrapper {
  border: 0;
}

:deep .fr-second-toolbar {
  border: 0;
  display: none !important;
}

.tip-box {
  padding: 5px 10px;
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    // .custom-tree-node {
    //   color: #1380ff;
    // }
    .tip-box {
      background-color: #f5f7fa;
      display: flex;
    }
  }
}

.tip-tip {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tip-btn {
  display: none;

  &:hover {
    // .custom-tree-node {
    //   color: #1380ff;
    // }
    .tip-btn {
      display: block;
    }
  }
}

.formula {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

:deep .ML__mathlive {
  padding: 10px;
}

.math-close {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  width: 24px;
  height: 24px;
}

:deep .el-dialog__body {
  padding-top: 0 !important;
}

:deep .froala-box input,
.froala-box textarea {
  caret-color: red;
}

.tips-dialog {
  :deep .el-dialog {
    border-radius: 12px;

    .el-dialog__body {
      padding: 0 20px;
    }
  }
}

:deep .fr-element p {
  margin-block-start: 0;
  margin-block-end: var(--p-segment-spacing);
  line-height: var(--p-line-height);
  text-align: justify;
  font-size: var(--p-font-size);
}
// :deep .fr-element p:last-of-type {
//   padding-bottom: 400px;
// }

:deep .fr-element p span {
  border-radius: 4px;
}
:deep .fr-wrapper > .fr-element {
  padding-bottom: 400px !important;
}
.menu-key {
  margin-left: 20px;
  font-size: 12px;
  color: #a6a6a6;
}

.menu-tips {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  display: block;
}

.nounderline {
  border: 0 !important;
}
.mxs-title .title-input {
  padding: 0;
}
:deep .el-loading-spinner .circular {
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: calc(50% - 50px);
  color: var(--color-icon);
}
:deep .el-loading-spinner .el-loading-text {
  float: left;
  color: var(--color-icon);
}
.document-title .title {
  display: flex;
  align-items: center;
}
.document-close {
  width: 30px;
  height: 30px;
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
