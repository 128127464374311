<template>
  <div class="nav-header chat-nav">
    <div class="d-center">
      <div class="chat-s d-center hotkey" v-if="chatNav === 'hotkeyDrawer'">
        <div :class="['chat-0', 'd-center', 'hotkey']">
          <svg-icon icon-class="new-68" class-name="svg-small" />
          快捷键
        </div>
      </div>
      <div class="chat-s d-center" v-else>
        <div
          v-show="$root.config.shortcutMenu.flashThought === 'true'"
          :class="['chat-0', 'd-center', { active: chatNav === 'flashThought' }]"
          @click="onChangeChatNav('flashThought')"
        >
          <svg-icon icon-class="010" class-name="svg-small" />
          闪念
        </div>
        <div
          v-show="$root.config.shortcutMenu.chatgpt === 'true'"
          :class="['chat-0', 'd-center', { active: chatNav === 'ai' }]"
          @click="onChangeChatNav('ai')"
        >
          <svg-icon icon-class="007" class-name="svg-small" />
          AI
        </div>
        <div
          :class="['chat-0', 'd-center', { active: chatNav === 'transfer' }]"
          @click="onChangeChatNav('transfer')"
        >
          <svg-icon
            :icon-class="$root.config.theme.includes('dark') ? '019_b' : '019'"
            class-name="svg-small"
          />
          中转
        </div>
      </div>
    </div>
    <div class="d-center">
      <!-- <div class="btn btn-font-gray d-center" @click="onFullScreen">
        <el-icon>
          <FullScreen />
        </el-icon>
      </div>
      <div class="btn btn-font-gray d-center" @click="onChangeFixed">
        <svg-icon icon-class="003" class-name="svg-s2"></svg-icon>
      </div> -->
      <el-dropdown
        ref="dropdown"
        @command="handleCommand"
        :trigger="isMobile ? 'click' : 'hover'"
        :popper-class="[$root.config.theme, 'nav-dropdown']"
        v-if="chatNav !== 'hotkeyDrawer'"
      >
        <div class="el-dropdown-link btn btn-font-gray d-center">
          <svg-icon icon-class="new-float" class-name="svg-s2"></svg-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="right"
              ><svg-icon
                icon-class="028"
                class-name="svg-small"
              />侧边模式</el-dropdown-item
            >
            <el-dropdown-item command="fixed" v-if="false"
              ><svg-icon
                icon-class="026"
                class-name="svg-small"
              />弹窗模式</el-dropdown-item
            >
            <el-dropdown-item command="full"
              ><svg-icon
                icon-class="027"
                class-name="svg-small"
              />弹窗模式</el-dropdown-item
            >
            <el-dropdown-item command="newTab" v-if="chatNav === 'transfer'">
              <el-icon style="vertical-align: -18%; margin-right: 8px">
                <Notification /> </el-icon
              >独立窗口
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <div
        class="btn btn-font-gray d-center"
        @click="onClose"
        @touchend="
          (e) => {
            e.preventDefault();
            onClose();
          }
        "
      >
        <!-- <svg-icon icon-class="002" class-name="svg-s1"></svg-icon> -->
        <svg-icon icon-class="new-close" class="svg-s2"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../../../../svgIcon/index.vue";
import { Platform } from "../../../../utils/utils";

export default {
  name: "ChatNavHeader",
  components: { SvgIcon },
  props: {
    noFixed: { type: Boolean, default: true },
    chatNav: { type: String, default: "ai" },
  },
  data() {
    return {
      isMobile: Platform.isMobile,
    };
  },
  mounted() {

  },
  methods: {
    onChangeFixed() {
      this.$emit("update:noFixed", !this.noFixed);
    },
    onClose() {
      this.$emit("close", false);
    },
    onChangeChatNav(type) {
      this.$emit("changeNav", type);
    },
    handleCommand(command) {
      switch (command) {
        case "fixed":
          this.$emit("change-size", "fixed");
          break;
        case "full":
          this.$emit("change-size", "full");
          break;
        case "right":
          this.$emit("change-size", "right");
          break;
        case "newTab":
          this.onNewTab();
          break;
        default:
          break;
      }
    },
    onNewTab() {
      let routeUrl = this.$router.resolve({
        path: "/article/" + this.$root.activeItem.id + "/transfer/",
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped>
.hotkey {
  background-color: transparent;
}
</style>
