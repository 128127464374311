<template>
  <div class="hotkey-drawer">
    <div class="drawer-body">
      <el-scrollbar>
        <el-table :data="shortcutKeys" style="width: 100%">
          <el-table-column label="树目录快捷键">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <svg-icon :icon-class="scope.row.icon"></svg-icon>
                <span style="margin-left: 10px">{{ scope.row.title }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template #default="scope">
              <div style="display: flex; justify-content: flex-end">
                <span>{{ scope.row.shortcutText }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="mindShortcutKeys"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column label="导图快捷键">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <svg-icon :icon-class="scope.row.icon"></svg-icon>
                <span style="margin-left: 10px">{{ scope.row.title }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template #default="scope">
              <div style="display: flex; justify-content: flex-end">
                <span>{{ scope.row.shortcutText }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="editorShortcutKeys"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column label="存量快捷键">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <svg-icon :icon-class="scope.row.icon"></svg-icon>
                <span style="margin-left: 10px">{{ scope.row.title }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template #default="scope">
              <div style="display: flex; justify-content: flex-end">
                <span>{{ scope.row.shortcutText }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="markdownShortcutKeys"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column label="markdown 语法">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <svg-icon :icon-class="scope.row.icon"></svg-icon>
                <span style="margin-left: 10px">{{ scope.row.title }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template #default="scope">
              <div style="display: flex; justify-content: flex-end">
                <span>{{ scope.row.shortcutText }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import {
  shortcutKeys,
  mindShortcutKeys,
  editorShortcutKeys,
  markdownShortcutKeys,
} from "../../../../utils/constaints";

export default {
  components: {},
  props: {},
  data() {
    return {
      shortcutKeys: [],
      mindShortcutKeys: [],
      editorShortcutKeys: [],
      markdownShortcutKeys: [],
    };
  },
  mounted() {
    this.shortcutKeys = shortcutKeys;
    this.mindShortcutKeys = mindShortcutKeys;
    this.editorShortcutKeys = editorShortcutKeys;
    this.markdownShortcutKeys = markdownShortcutKeys;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.hotkey-drawer {
  height: calc(100% - 75px);
  width: auto;
}
.drawer-body {
  padding: 8px;
  height: 100%;
}
</style>
