<template>
  <div class="document-list">
    <div class="document-column">
      <svg-icon icon-class="new-01" class-name="tree-search size-3" />
      <div class="document-column-name" v-if="$root.curGroup">
        {{ $root.curGroup.groupName }}
      </div>
      <div class="document-column-name" v-else>我的模型树</div>
      <div class="document-column-methods d-center">
        <el-icon class="color-icon" size="16px" @click="insertAfter()">
          <CirclePlus />
        </el-icon>
        <svg-icon
          v-if="false"
          icon-class="mind"
          class-name="mind-all"
          @click="menuOpenMindMap(undefined)"
        />
        <group-list
          :theme="$root.config.theme"
          :is-local="true"
          @change="onOpenGroup"
        />
      </div>
    </div>
    <tree-list
      ref="treeList"
      class="main-tree scroll no-scrollbar"
      :class="{ 'tree-hide': !!$root.curGroup }"
      :tree-type="0"
      :load="loadNode"
      :is-cloud="true"
      :initLoad="getInitTreeNodes"
      :update-node-api="treeUpdateNodeApi"
      :clone-api="treeCloneApi"
      :delete-api="deleteApi"
      :check-mind-drag="!$root.curGroup"
      @onSelect="onSelectTreeItem"
      @onContextMenu="menuOpen"
      @onDouble="onDouble"
      @onAdd="(item) => this.$emit('add', item)"
      @drag-node="onDragNode"
      @drag-external="onDragExternal"
    >
      <template #default="{ data, node }">
        <document-item
          v-model:isEdit="node.isEdit"
          v-model:title="data.title"
          :is-mobile="isMobile"
          :item="data"
          @update:title="onChangeTitle(node)"
          @input-focus="onFocus"
          @onRename="treeRenameApi(node)"
          @onMenu="(node, type, data) => onMenu(node, type, data)"
        />
      </template>
    </tree-list>
    <group-tree
      ref="groupTree"
      v-if="$root.curGroup"
      :is-mobile="isMobile"
      :check-mind-drag="true"
      @onContextMenu="menuOpen"
      @on-menu="onMenu"
      :update-node-api="treeUpdateNodeApi"
      @rename-api="treeRenameApi"
      @on-select="onSelectTreeItem"
      @change-title="onChangeTitle"
      :key="groupKey"
    />
    <tree-menus
      ref="menus"
      :menuButtons="$root.config.menuButtons"
      v-model:select-node="menuSelectItem"
      :theme="$root.config.theme"
      :same-map="sameMap"
      @onChange="onMenu"
    />
    <tree-dialog
      :is-local="false"
      :init-list="[
        {
          id: 'root',
          hasChildren: true,
          title: '我的模型树',
          children: $refs.treeList && $refs.treeList.list,
        },
      ]"
      :load-node="loadNode"
      :group-enabled="true"
      v-model:filter-item="treeDialogItem"
      :theme="$root.config.theme"
      :update-node-api="treeUpdateNodeApi"
      :clone-api="treeCloneApi"
      :on-select="onSelectTreeItem"
      v-model:dialog-type="treeDialogType"
    />
  </div>
</template>

<script>
import TreeList from "../tree/TreeList";
import DocumentItem from "../tree/DocumentItem";
import TreeMenus from "../tree/TreeMenus";
import TreeDialog from "../tree/TreeDialog";
import GroupList from "./GroupList";
import GroupTree from "./GroupTree";
import { Platform } from "../../../../utils/utils";

export default {
  name: "CloudTree",
  components: {
    TreeDialog,
    TreeMenus,
    DocumentItem,
    TreeList,
    GroupList,
    GroupTree,
  },
  props: {
    midType: { type: String, default: "" },
    showMindMap: { type: String, default: "" },
    selectItem: { type: Object, default: undefined },
    changeTitleApi: { type: Function, default: undefined },
    deleteApi: { type: Function, default: undefined },
    checkSampMap: { type: Function, default: undefined },
  },
  data() {
    return {
      isMobile: Platform.isMobile,
      menuSelectItem: undefined,
      treeDialogItem: undefined,
      treeDialogType: 0,
      activeItem: undefined,
      sameMap: false,
      groupKey: 0,
    };
  },
  watch: {
    selectItem(val, old) {
      if (val !== old && this.activeItem !== val) {
        if (old) old.active = false;
        if (val && val.id.startsWith("local-")) {
          this.activeItem = undefined;
        } else {
          this.activeItem = val;
        }
      }
    },
  },
  inject: [
    "closeMindMap",
    "changeMarkIcon",
    "openMapDialog",
    "onDragTreeNode",
    "moveArticle",
    "getAiAnswer",
  ],
  methods: {
    moveNode(dragNode, endNode) {
      this.noAlwaysMind = true;
      this.$refs.treeList.moveNode(dragNode, endNode);
      setTimeout(() => {
        this.noAlwaysMind = false;
      }, 500);
    },
    onDragNode(node) {
      this.onDragTreeNode(node);
    },
    async onDragExternal(options) {
      return this.moveArticle(options);
    },
    onOpenGroup(item) {
      this.$root.curGroup = item;
    },
    onFocus() {
      this.$emit("input-focus");
    },
    onChangeTitle(node) {
      this.$emit("change-title", node.item);
    },

    onMenu(node, type, data) {
      switch (type) {
        case "more":
          this.menuOpen(data, node);
          break;
        case "new":
          this.menuNewTreeItem(node);
          break;
        case "map":
          this.menuOpenMindMap(node);
          break;
        case "expand":
          this.menuExpandAll(node, true);
          break;
        case "closed":
          this.menuExpandAll(node, false);
          break;
        case "rename":
          this.menuSelectItem.focus();
          break;
        case "delete":
          this.menuDelete(node);
          if (this.$refs.groupTree) {
            this.$refs.groupTree.groupDelete(node.item);
          }
          break;
        case "move":
          this.treeDialogItem = node.item;
          this.treeDialogType = 1;
          break;
        case "copy":
          this.treeDialogItem = node.item;
          this.treeDialogType = 2;
          break;
        case "share":
          this.menuShare(node);
          break;
        case "record":
          this.menuParagraphHistory(node);
          break;
        case "set":
          this.$root.configVisible = true;
          this.$root.configActive = "menu";
          break;
        case "sameMap":
          this.$emit("same-map", node);
          break;
        case "mark":
          this.updateMark(node.item);
          break;
        case "iconType":
          this.changeIconType(node.item, data);
          break;
        case "view-link":
          this.openMapDialog(this.$root.getShowEdgesAndChildrens(node.item));
          break;
        case "aiTranslate":
          var sort = null;
          this.getAiAnswer({ node, sort }, "treeMenuAiTranslate");
          break;
      }
    },
    changeIconType(node, type) {
      this.$root.updateIconType(node.id, type);
    },
    async updateMark(node) {
      await this.$root.updateMarkIcon(node.id);
      this.changeMarkIcon(node.id, node.iconDetail);
    },
    menuOpenMindMap(node) {
      this.$emit("mind-map", node, false);
    },
    menuParagraphHistory(node) {
      this.$emit("paragraph-history", node);
    },
    menuShare(node) {
      this.$emit("share", node);
    },
    menuExpandAll(node, expand) {
      this.$refs.treeList.expandAll(node.item, expand);
    },
    async menuDelete(node) {
      await this.$refs.treeList.deleteItem(node.item);
    },
    async menuNewTreeItem(node, type = "left", iconType = 0) {
      return await this.$refs.treeList.appendItem(node && node.item, iconType);
    },
    menuOpen(e, node) {
      this.menuSelectItem = node;
      this.sameMap = !(this.checkSampMap && this.checkSampMap(node.item.id));
      this.$nextTick(() => {
        let menu = this.$refs.menus.$el;
        // console.log(event, window.innerHeight, menu.offsetHeight);
        menu.style.left = e.clientX + 20 + "px";
        let top = e.clientY;
        if (e.clientY + menu.offsetHeight > window.innerHeight) {
          top = window.innerHeight - menu.offsetHeight;
        }
        menu.style.top = top - 5 + "px";
      });
    },
    async insertAfter() {
      let item = await this.menuNewTreeItem(null, "left", 99);
      if (this.$root.curGroup) {
        await this.$root.addGroupItem(
          this.$root.curGroup.id,
          item.id,
          this.$root.curGroup.itemList?.length || 0
        );
        this.groupKey += 1;
      }
    },
    async loadNode(node) {
      return await this.$api.article.childrenList(node && node.id);
    },
    async getInitTreeNodes() {
      let articleId = this.$route.params.articleId;
      articleId =
        articleId && !articleId.startsWith("local-") ? articleId : undefined;
      let dd = this.$api.article.articleTree(articleId);
      return dd || [];
    },
    async treeUpdateNodeApi(data) {
      if (data.articleId) {
        this.$emit("move", data);
      }
      // let count = 0;
      // const ssss = ()=> {
      //   if (count>=2000) return;
      //   count++;
      //   setTimeout( ()=>{
      //     this.$api.article.addOrUpdateArticleCatalog(data);
      //     ssss();
      //   }, 200);
      // }
      // ssss();
      return this.$api.article.addOrUpdateArticleCatalog(data);
    },
    async treeCloneApi(item, parent) {
      const s = await this.$root.cloneArticle(item, parent);
      // console.log('-treeCloneApi---', s, item, parent);
      this.$emit("clone", {newItem: s, itemId: item.id, parentId: parent.id});
      return s;
    },
    treeRenameApi(node) {
      if (this.changeTitleApi) {
        return this.changeTitleApi({
          id: node.item.id,
          title: node.item.title,
        });
      }
    },
    onDouble(e, item) {
      // console.log('-onDouble--', e, e.target.querySelector('.tree-content'))
      // const input = e.target.querySelector(".tree-title");
      // if (input) {
      //   input.focus();
      // }
    },
    onSelectTreeItem(item, checkExpand = true, isDelete = false) {
      const path = this.$route.path.startsWith("/testEditor")
        ? "/testEditor"
        : "/article";
      if (!item) {
        this.activeItem = undefined;
        this.$emit("update:selectItem", undefined);
        this.$router.push({
          path: path,
        });
        return;
      }
      if (this.activeItem && this.activeItem.id === item.id) {
        if (
          this.midType !== "vditor" &&
          (this.midType !== "recyclebin" || !isDelete)
        ) {
          this.$emit("update:midType", "vditor");
          return;
        }
        if (checkExpand) {
          this.$refs.treeList.expand(item);
        }
        return;
      }

      if (this.activeItem) this.activeItem.active = false;
      this.activeItem = item;
      if (
        this.midType !== "vditor" &&
        (this.midType !== "recyclebin" || !isDelete)
      ) {
        this.$emit("update:midType", "vditor");
      }
      if (this.showMindMap) {
        if (
          !this.noAlwaysMind &&
          (!this.$root.config.alwaysMind ||
            this.$root.config.alwaysMind === "false")
        ) {
          this.$emit("close-mindMap");
        }
      }
      this.$emit("update:selectItem", item);
      this.activeItem.active = true;
      if (checkExpand) {
        if (!this.activeItem.expand) {
          // this.$refs.treeList.expand(item);
        }
      }
      if (this.$route.params.articleId !== item.id) {
        this.$router.push({
          path: `${path}/${item.id}`,
        });
      }
    },
  },
};
</script>

<style scoped></style>
