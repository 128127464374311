<template>
  <div
    ref="chatbodyRef"
    id="chat-drawer-body"
    class="chat-drawer-body"
    element-loading-text="Loading..."
    element-loading-background="transparent"
  >
    <div ref="chatboxRef" id="chat-drawer-box" class="chat-drawer-box">
      <!-- <div class="chat-info">
          <div class="chat-avatar">
            <el-avatar
              :size="28"
              fit="fill"
              src="/pc/chatgpt.png"
              icon="UserFilled"
            />
          </div>
          <div class="chat-text">
            您有什么问题吗？
          </div>
          <div class="chat-space"></div>
        </div> -->
    </div>
  </div>
  <div class="chat-drawer-footer">
    <div class="chat-df-btn">
      <el-button
        @click="newChatGpt"
        :disabled="chatDisabled"
        size="small"
        class="chat-btn-r"
        ><svg-icon icon-class="013" />新聊天</el-button
      >
      <!-- <el-button :disabled="chatDisabled" size="small" class="chat-btn-n"
        ><el-icon><Opportunity /></el-icon>提示</el-button
      >
      <el-button :disabled="chatDisabled" size="small" class="chat-btn-n"
        ><el-icon><Clock /></el-icon>历史</el-button
      > -->
      <div class="chat-btn-n center right gpt">
        <svg-icon
          icon-class="021"
          class-name="svg-small"
          @mouseover="closeDropdown"
        />
        <el-dropdown
          ref="gptDropdownRef"
          @command="handleCommand"
          :popper-class="[$root.config.theme]"
        >
          <div class="el-dropdown-link">
            <span class="gpt-model">{{ chatModel }}</span>
            <el-icon class="el-icon--right">
              <CaretBottom />
            </el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="G3">G3</el-dropdown-item>
              <el-dropdown-item command="G4" v-if="showGpt4"
                >G4</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-button
        size="small"
        class="chat-btn-l"
        :disabled="chatDisabled"
        @click="confirmClick"
      >
        <svg-icon icon-class="014" />
      </el-button>
    </div>
    <div>
      <el-input
        ref="chatInput"
        v-model="chatInput"
        type="textarea"
        :rows="3"
        maxlength="150"
        resize="none"
        placeholder="输入消息..."
        @keyup.enter="confirmClick"
        :disabled="chatDisabled"
      />
    </div>
  </div>
</template>
<script>
import { gptMobiles } from "../../../../utils/vipMobile";
import axios from "axios";
export default {
  props: ["insertHtml", "theme"],
  data() {
    var height = document.body.clientHeight - 48 - 10 - 135;
    return {
      chatModel: "G3",
      chatInput: "",
      chatScrollbarHeight: height + "px",
      chatInputId: 1,
      timeOutClick: null,
      chatDisabled: false,
      avatarUrl: "/pc/avatar.png",
      chatAvatarUrl: "/pc/chatgpt.png",
      chatAnswer: [],
      chatText: "",
      mobile: "",
      showGpt4: false,
      authorize: "",
    };
  },
  mounted() {
    // if (this.theme.includes("dark")) {
    //   this.chatAvatarUrl = "/pc/chatgpt_b.png";
    // }
    let user = JSON.parse(localStorage.getItem("__user"));
    this.mobile = user.mobile;
    this.showGpt4 = gptMobiles.includes(this.mobile);

    // 1.5.8 chatjs
    // if (this.$root.config.shortcutMenu.chatgptToken) {
    //   var token = this.$root.config.shortcutMenu.chatgptToken;
    // } else {
    //   this.$root.tips("error", "设置不正确，请重新设置");
    //   this.$root.configActive = "laboratory";
    //   this.$root.configVisible = true;
    // }
    // if (!localStorage.getItem("authorize")) {
    //   axios
    //     .post("https://chat.onechatai.cn/api/v1/login/TokenLogin", {
    //       token: token,
    //     })
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         // this.authorize = res.data.token;
    //         localStorage.setItem("authorize", res.data.data.token);
    //       }
    //     });
    // }
  },
  methods: {
    async confirmClick() {
      if (this.chatInput == "") {
        this.$root.tips("error", "输入内容不能为空");
        return false;
      }
      if (this.chatdisabled == true) {
        return false;
      }
      this.chatDisabled = true;
      this.insertChatInput(this.chatInput, this.chatInputId);
      var messages = [];
      if (this.chatAnswer.length >= 12) {
        messages = this.chatAnswer.slice(2);
        this.chatAnswer = messages;
      } else {
        messages = this.chatAnswer;
      }
      var chatQuestion = {
        role: "user",
        content: this.chatInput,
      };
      messages.push(chatQuestion);
      this.$refs.chatbodyRef.scrollTop = this.$refs.chatboxRef.offsetHeight;
      var res = await this.getChatgpt(messages);
      // var res = await this.getStreamChatgpt(messages);

      // if (res.data.msg == "操作成功") {
      //   this.insertChatInput(res.data.data[0].message.content);
      //   this.chatAnswer.push(res.data.data[0].message);
      // } else {
      //   this.insertChatInput(res.data.msg);
      //   this.chatAnswer.push({
      //     role: "assistant",
      //     content: res.data.msg,
      //   });
      // }
      this.chatInput = "";
    },

    async quickChatgpt(type, data) {
      if (data == "") {
        this.$root.tips("error", "输入内容不能为空");
        return false;
      }
      if (this.chatdisabled == true) {
        return false;
      }
      this.insertChatInput(data, this.chatInputId);
      this.chatDisabled = true;
      var content = "";
      switch (type) {
        case "abstract":
          content = "请用四个字总结下以下内容：";
          break;
        case "sumUp":
          content = "请总结以下内容：";
          break;
        case "createTag":
          content = "请生成五个最重要的标签关键词：";
          break;
        case "explain":
          content = "请解释以下内容：";
          break;
        case "example":
          content = "请举出3个真实可靠的案例来论证以下内容：";
          break;
        case "counterExample":
          content = "请确定以下内容是否有漏洞，如果不正确请举出3个反例来证明：";
          break;
        case "askQuestion":
          content = "请回答这个问题：";
          break;
        default:
          break;
      }
      content = content + data;
      var messages = [];
      if (this.chatAnswer.length >= 12) {
        messages = this.chatAnswer.slice(2);
        this.chatAnswer = messages;
      } else {
        messages = this.chatAnswer;
      }
      var chatQuestion = {
        role: "user",
        content: content,
      };
      messages.push(chatQuestion);
      var res = await this.getChatgpt(messages);

      // if (res.data.msg == "操作成功") {
      //   this.insertChatInput(res.data.data[0].message.content);
      //   this.chatAnswer.push(res.data.data[0].message);
      // } else {
      //   this.insertChatInput(res.data.msg);
      //   this.chatAnswer.push({
      //     role: "assistant",
      //     content: res.data.msg,
      //   });
      // }
      this.chatInput = "";
    },

    // 1.5.8 chatjs
    // async getChatgpt(messages) {
    //   const that = this;
    //   var first = false;
    //   var authorize = localStorage.getItem("authorize");
    //   var chatInputId = that.chatInputId;
    //   let type = "gpt-4-vision-preview";
    //   let model = "gpt-3.5-turbo-16k-0613";
    //   // let type = "gpt3";
    //   // let model = "gpt-3.5-turbo";
    //   // if (that.chatModel === "G4") {
    //   //   type = "gpt4";
    //   //   model = "gpt-4";
    //   // }
    //   let msg = {
    //     type: type,
    //     rate: 150,
    //     prompts_id: 1,
    //     completion: { model: model, messages: messages },
    //   };
    //   axios
    //     .post("https://chat.onechatai.cn/api/v1/chat/stream", msg, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorize: authorize,
    //         token: 1,
    //         unit: 1,
    //         busType: 1,
    //         processId: 1,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         var eventSource = "";
    //         if (authorize && authorize != "") {
    //           eventSource = new EventSource(
    //             "https://chat.onechatai.cn/chat/v1/chat/completionStream?Authorize=" +
    //               authorize
    //           );
    //         } else {
    //           eventSource = new EventSource(
    //             "https://chat.onechatai.cn/chat/v1/chat/completionStream"
    //           );
    //         }
    //         eventSource.onopen = function (e) {
    //           if (first == false) {
    //             first = true;
    //             that.insertChatDom();
    //           }
    //         };
    //         eventSource.onmessage = function (e) {
    //           // console.log(e.data);
    //           if (!e.data.includes("[done]")) {
    //             var data = JSON.parse(e.data);
    //             // console.log(data[0].delta.content);
    //             if (data[0].delta.content) {
    //               that.chatText = that.chatText + data[0].delta.content;
    //             }
    //             var dom = document.getElementById("chatInput" + chatInputId);
    //             dom.innerHTML =
    //               that.chatText +
    //               '<span class="cursor"><span class="line"></span></span>';
    //             that.$refs.chatbodyRef.scrollTop =
    //               that.$refs.chatboxRef.offsetHeight;
    //             if (data[0].finish_reason == "stop") {
    //               eventSource.close();
    //               that.chatAnswer.push({
    //                 role: "assistant",
    //                 content: that.chatText,
    //               });
    //               var domHtml = that.chatText;
    //               that.chatText = "";
    //               setTimeout(() => {
    //                 dom.innerHTML = domHtml;
    //               }, 3000);
    //               that.chatDisabled = false;
    //               setTimeout(() => {
    //                 that.$refs.chatInput.focus();
    //               }, 100);
    //             }
    //           }
    //         };
    //       } else {
    //         that.insertChatInput(res.data.msg, chatInputId);
    //         that.chatAnswer.push({
    //           role: "assistant",
    //           content: res.data.msg,
    //         });
    //         that.chatDisabled = false;
    //         setTimeout(() => {
    //           that.$refs.chatInput.focus();
    //         }, 100);
    //       }
    //     });
    // },

    async getChatgpt(messages) {
      const that = this;
      var first = false;
      var chatInputId = that.chatInputId;
      // console.log(e);
      if (typeof EventSource !== "undefined") {
        let type = "";
        let model = "gpt-3.5-turbo";
        if (that.chatModel === "G4") {
          type = "?type=gpt4";
          model = "gpt-4o";
        }
        const eventSource = new EventSource(
          "https://chat-gpt.moxingshu.com.cn/v2/completionStream" + type
        );
        eventSource.onopen = function (e) {
          if (first == false) {
            first = true;
            that.insertChatDom();
            that.$api.other
              .chatgptV2({
                model: model,
                // model: "gpt-3.5-turbo",
                // prompt: prompt, //必填 输入文本
                // temperature: 0.9, //可选
                // max_tokens: 150, //可选 响应文本长度
                // top_p: 1, //可选
                // frequency_penalty: 0.0, //可选
                // presence_penalty: 0.6, //可选
                // stop: [" Human:", " AI:"], //可选
                messages: messages,
              })
              .then((res) => {
                if (res.data.msg == "成功") {
                  // console.log(messages);
                } else {
                  that.insertChatInput(res.data.msg, chatInputId);
                  that.chatAnswer.push({
                    role: "assistant",
                    content: res.data.msg,
                  });
                  that.chatDisabled = false;
                  setTimeout(() => {
                    that.$refs.chatInput.focus();
                  }, 100);
                  eventSource.close();
                }
              });
          }
        };
        eventSource.onmessage = function (e) {
          // console.log(e.data);
          if (!e.data.includes("[done]")) {
            var data = JSON.parse(e.data);
            that.chatText = that.chatText + data[0].delta.content;
            var dom = document.getElementById("chatInput" + chatInputId);
            dom.innerHTML =
              that.chatText +
              '<span class="cursor"><span class="line"></span></span>';
            that.$refs.chatbodyRef.scrollTop =
              that.$refs.chatboxRef.offsetHeight;
            if (data[0].finish_reason == "stop") {
              that.chatAnswer.push({
                role: "assistant",
                content: that.chatText,
              });
              var domHtml = that.chatText;
              that.chatText = "";
              setTimeout(() => {
                dom.innerHTML = domHtml;
              }, 3000);
              that.chatDisabled = false;
              setTimeout(() => {
                that.$refs.chatInput.focus();
              }, 100);
              eventSource.close();
            }
          }
        };
        eventSource.onerror = function (e) {
          // console.log(e);
          // console.log("错误断开");
          // eventSource.close();
        };
      } else {
        that.chatDisabled = false;
        setTimeout(() => {
          that.$refs.chatInput.focus();
        }, 100);
        that.$root.tips("error", "当前浏览器不支持");
      }
    },

    insertChatDom() {
      var chatBox = document.getElementById("chat-drawer-box");
      var chatInfo = document.createElement("div");
      chatInfo.classList.add("chat-info");
      var chatAvatar = document.createElement("div");
      chatAvatar.classList.add("chat-avatar");
      var chatSpace = document.createElement("div");
      chatSpace.classList.add("chat-space");

      var chatText = document.createElement("div");
      chatText.classList.add("chat-text");
      chatText.setAttribute("id", "chatInput" + this.chatInputId);
      // chatText.onclick = () => {
      //   this.copyBtn(text);
      // };
      chatText.ondblclick = () => {
        this.insertText(chatText.innerText);
      };

      if (this.chatInputId % 2 == 0) {
        chatAvatar.innerHTML =
          '<span class="el-avatar el-avatar--icon el-avatar--circle" style="--el-avatar-size:28px;background:transparent;"><img src="' +
          this.chatAvatarUrl +
          '" style="object-fit: fill;"></span>';
        chatText.innerHTML =
          '&nbsp;<span class="cursor"><span class="line"></span></span>';
        chatInfo.classList.add("chat-left");
        chatInfo.appendChild(chatAvatar);
        chatInfo.appendChild(chatText);
        chatInfo.appendChild(chatSpace);
      } else {
        chatAvatar.innerHTML =
          '<span class="el-avatar el-avatar--icon el-avatar--circle" style="--el-avatar-size:28px;background:transparent;"><img src="' +
          this.avatarUrl +
          '" style="object-fit: fill;"></span>';
        chatText.classList.add("ctr");
        chatInfo.classList.add("chat-right");
        chatInfo.appendChild(chatSpace);
        chatInfo.appendChild(chatText);
        chatInfo.appendChild(chatAvatar);
      }
      chatBox.appendChild(chatInfo);
      this.chatInputId += 1;
    },

    insertChatInput(text, chatInputId) {
      this.insertChatDom();
      setTimeout(() => {
        var dom = document.getElementById("chatInput" + chatInputId);
        dom.innerHTML = text;
      }, 200);
    },
    copyBtn(text) {
      if (this.timeOutClick) {
        window.clearTimeout(this.timeOutClick);
      }
      this.timeOutClick = window.setTimeout(() => {
        navigator.clipboard.writeText(text).then(() => {
          this.$root.tips("success", "复制成功");
        });
      }, 300);
    },
    insertText(text) {
      window.clearTimeout(this.timeOutClick);
      this.insertHtml(text);
      this.$root.tips("success", "已插入模型文档中");
    },
    newChatGpt() {
      this.chatInput = "";
      this.chatAnswer = [];
      this.chatText = "";
      this.chatInputId = 1;
      var chatBox = document.getElementById("chat-drawer-box");
      chatBox.innerHTML = "";
    },
    handleCommand(command) {
      this.chatModel = command;
    },
    openDropdown() {
      this.$refs.gptDropdownRef.handleOpen();
    },
    closeDropdown() {
      this.$refs.gptDropdownRef.handleClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right {
  float: right;
}

.gpt {
  .svg-icon {
    font-size: 20px;
    margin-right: 4px;
  }

  .el-icon--right {
    margin: 0 4px;
  }
}

.gpt-model {
  color: var(--color-white);
  font-size: 12px;
}
</style>
