<template>
  <div class="transfer-title">模型三问</div>
  <div class="t-box">
    <el-form
      class="transfer-form"
      ref="formRef"
      :model="mindList"
      label-width="auto"
      label-position="top"
    >
      <el-row :gutter="2">
        <el-col :span="7">
          <el-form-item label="模型">
            <el-input v-model="mindList.title" />
          </el-form-item>
        </el-col>
        <el-col
          :span="1"
          style="color: #717cfc; text-align: center; padding-top: 35px"
          >-</el-col
        >
        <el-col :span="8">
          <el-form-item label="关系">
            <el-input v-model="mindList.children[0].title">
              <template #append>
                <el-dropdown
                  @command="handleCommand"
                  :popper-class="[$root.config.theme]"
                >
                  <div class="el-dropdown-link">
                    <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="item.data"
                        v-for="(item, index) in $root.relationList"
                        :key="index"
                        >{{ item.data }}
                      </el-dropdown-item>
                      <el-dropdown-item command="自定义"
                        >自定义
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col
          :span="1"
          style="color: #717cfc; text-align: center; padding-top: 35px"
          >-</el-col
        >
        <el-col :span="7">
          <el-form-item label="模型">
            <el-input v-model="mindList.children[0].children[0].title" />
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="textShow" class="transfer-text">
        （{{ mindList.title }}） 的 （{{ mindList.children[0].title }}） 是 （{{
          mindList.children[0].children[0].title
        }}）
      </div>
    </el-form>
    <el-button class="transfer-btn" @click="editMind">
      <svg-icon icon-class="new-transfer" />转成导图</el-button
    >
    <div class="transfer-mind" v-show="mindShow">
      <div class="transfer-md">
        <div class="transfer-k">模型导图</div>
        <div v-if="!type" class="transfer-m" @click="editMind">编辑</div>
      </div>
      <div class="transfer-map">
        <mind-graph
          ref="transferMindRef"
          mode="default"
          theme="white"
          :wheel="false"
          v-model="mindList"
          :mindmap="true"
          :resize-center="true"
          :sharp-corner="true"
          :hide-edge="false"
          :default-edge-style="this.$root.mindEdgeStyle"
          :default-node-style="this.$root.mindNodeStyle"
          :key="key"
        />
      </div>
    </div>
    <el-button
      v-if="closeBtn"
      class="mind-right-btn transfer-close"
      size="small"
      @click="onClose"
    >
      <el-icon>
        <CloseBold />
      </el-icon>
    </el-button>
    <div class="drag-win-shadow" v-if="showDialog">
      <map-dialog
        :model-value="mindList"
        :isTransfer="true"
        :type="type"
        mode="edit"
        @close="onCloseDialog"
      />
    </div>
  </div>
</template>
<script>
import MindGraph from "../mind-map/MindGraph";
import MapDialog from "../mind-map/MapDialog";
export default {
  components: { MindGraph, MapDialog },
  props: {
    closeBtn: { type: Boolean, default: false },
    type: { type: String, default: "" },
    isWide: { type: Boolean, default: false },
    modelValue: { type: String, default: null },
    list: { type: Array, default: null },
  },
  data() {
    return {
      mindShow: false,
      textShow: false,
      key: 0,
      mindList: {},
      showDialog: false,
      dialogData: [],
    };
  },
  watch: {
    mindList: {
      handler(val) {
        if (
          val.title == "" ||
          val.children[0].title == "" ||
          val.children[0].children[0].title == ""
        ) {
          this.mindShow = false;
          this.textShow = false;
        } else {
          this.textShow = true;
          this.key += 1;
        }
        this.$emit("update:list", val);
      },
      deep: true,
    },
  },
  inject: ["transferNewTreeItem"],
  created() {
    this.mindList = this.list;
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      if (command === "自定义") {
        this.$root.configActive = "transfer";
        this.$root.configVisible = true;
        return;
      }
      this.mindList.children[0].title = command;
    },
    openMind() {
      if (
        this.mindList.title != "" &&
        this.mindList.children[0].title != "" &&
        this.mindList.children[0].children[0].title != ""
      ) {
        this.mindShow = true;
        this.key += 1;
      }
    },
    onClose() {
      this.$emit("close-box");
    },
    onCloseDialog() {
      this.showDialog = false;
      this.dialogData = [];
    },
    editMind() {
      this.$emit("changeModel", this.mindList);
      // if (this.isWide) {
      //   this.$emit("changeModel", this.mindList);
      // } else {
      //   this.showDialog = true;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.transfer-btn {
  width: 100%;
  box-shadow: none;
  border-radius: 8px;
  background: white;
  color: #333;
  border: 1px solid #000;

  .svg-icon {
    width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-right: 2px;
  }
}
</style>
