<template>
  <el-popover placement="bottom-end" :width="516" trigger="click" @show="onOpen">
    <template #reference>
      <div class="btn btn-gray d-center btn-box">
        <svg-icon icon-class="msg" class-name="svg-small mxs-message-icon" />
      </div>
    </template>
    <template #default>
      <div class="mxs-msg-pop">
        <div class="d-flex mxs-msg-head">
          <div class="flex-1">文档通知</div>
          <div>
            <el-button type="plain" text>
              <svg-icon icon-class="msg" class-name="svg-small mxs-ss" />
              <span>关闭通知</span>
            </el-button>
            <el-button type="plain" text>
              <span>全部已读</span>
            </el-button>
          </div>
        </div>
        <el-scroll>
          <ul class="mxs-msg-list">
            <li>
              <div class="d-flex">
                <span>复制节点</span>
                <span class="mxs-msg-link">原链接</span>
                <span>已完成。</span>
                <el-button type="primary" class="mxs-msg-cc">查看新节点</el-button>
              </div>
              <div>
                <span class="mxs-msg-time">2024年11月06日 14:21:25</span>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <span>复制节点</span>
                <span class="mxs-msg-link">原链接</span>
                <span>已完成。</span>
                <el-button type="primary" class="mxs-msg-cc">查看新节点</el-button>
              </div>
              <div>
                <span class="mxs-msg-time">2024年11月06日 14:21:25</span>
              </div>
            </li>
          </ul>
        </el-scroll>
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
        pageNo: 1,
        pageSize: 10
    };
  },
  methods: {
    onOpen() {
      this.$api.message.getList({PageNo: this.pageNo, PageSize: this.pageSize});
    },
    onClickOutside() {
      this.$refs.popoverRef.popperRef?.delayHide?.();
    },
  },
};
</script>
<style lang="scss">
.nav-header .btn .mxs-message-icon {
  width: 20px;
  height: 20px;
}
.mxs-msg-head {
  padding: 10px 16px;
  border-bottom: 1px solid #eee;
}
.mxs-msg-pop {
  .mxs-ss {
    width: 16px;
    height: 16px;
    margin-right: 3px;
  }
}
.mxs-msg-list {
  padding: 0 10px;
  ul {
    margin: 0;
  }
  li {
    list-style: none;
    font-size: 16px;
    border-bottom: 1px solid #d6d6d6;
    padding: 16px 6px;
  }
  li:last-child {
    border-bottom: none;
  }
  .mxs-msg-link {
    color: #3191ff;
    text-decoration: underline;
    margin: 0 6px;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
      color: #5ea9ff;
    }
  }
  .mxs-msg-time {
    color: #999;
    font-size: 12px;
  }
  .mxs-msg-cc {
    margin-left: 6px;
    height: 24px;
    padding: 4px 10px;
    font-size: 12px;
  }
}
</style>
