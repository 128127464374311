<template>
  <el-container style="height: 100%">
    <el-main class="history-main">
      <div class="loading-text"
           v-if="loading">
        加载中...
      </div>
      <div v-loading="loading"
           :style="[{ height: height }]"
           element-loading-background="transparent">
        <el-scrollbar :max-height="height" always v-if="!loading">
          <div v-if="!nodesList" class="box-card title">暂无多余段落记录</div>
          <div v-else class="box-card title">{{ title }} 段落记录</div>
          <div v-for="(nodes, index) in nodesList" :key="index">
            <el-card class="box-card" v-for="(item, i) in nodes.items" :key="i">
              <template #header>
                <div class="card-header">
                  <span v-if="nodes.articleName == '新建模型' || nodes.articleName == '新建树'"></span>
                  <span v-else>{{ nodes.articleName }}</span>
                </div>
              </template>
              <div class="card-content"
                   v-html="'第' + item.paragraphId + '段落：' + item.content"></div>
              <div class="card-time">更新时间：{{ item.updateTime }}</div>
            </el-card>
          </div>
        </el-scrollbar>
      </div>
      <div class="box-card page">
        <el-pagination
            layout="prev, pager, next"
            v-model:current-page="pageIndex"
            :background="false"
            :total="total"
            :page-size="pageSize"
        />
      </div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  props: ["articleId", "getTitle"],
  data() {
    var height = window.innerHeight - 130 + "px";
    return {
      title: null,
      height: height,
      pageIndex: 1,
      nodesList: null,
      total: 0,
      pageSize: 10,
      loading: true,
    };
  },
  mounted() {
    this.getParagraphHistory();
  },
  watch: {
    articleId(val, old) {
      if (val !== old) {
        this.nodesList = null;
        this.title = null;
        this.loading = true;
        this.total = 0;
        this.pageIndex = 1;
        this.getParagraphHistory();
      }
    },
    pageIndex(val) {
      this.getParagraphHistory();
    },
  },
  methods: {
    getParagraphHistory() {
      this.nodesList = null;
      this.$api.historyArticle.paragraphHistoryByPage(this.articleId, this.pageIndex)
          .then((res) => {
            if (res.isSuccess) {
              // this.total = res.data.total;
              // this.pageSize = res.data.pageSize;
              if (res.data.length > 0) {
                this.title = res.data[0].topArticleName;
                this.getTitle && this.getTitle(this.title);
                this.nodesList = res.data;
              }
            }
            this.loading = false;
          })
          .catch( (error) => {
            this.loading = false;
          });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  color: #000;
  font-size: 20px;
  padding: 0;
  font-family: PingFang SC;
}

.dark3 .title {
  color: #8ab4f8;
}

.page {
  margin: 0px auto;
  padding: 0;
}

.history-main {
  padding: 20px 0 0 20px;

  .loading-text {
    font-size: 20px;
  }
}
</style>
