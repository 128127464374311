<template>
  <div class="recycle flex-1 d-column">
    <div class="recycle-title">
      <el-button
        class="tree-expand"
        size="small"
        v-if="!showLeft"
        @click="onShowLeft"
      >
        <svg-icon icon-class="new-left" class-name="svg-small" />
      </el-button>
      回收站
    </div>
    <div class="recycle-header">
      <div class="d-flex">
        <div
          v-if="list.length > 0"
          class="check-box d-center"
          :class="'check-select-' + this.selectState"
          @click="onSelectAll"
        ></div>
        <span class="recycle-all-text" v-if="list.length > 0">全选</span>
        <div
          class="recycle-all d-flex"
          v-if="selectState === 1 || selectState === 2"
        >
          <div class="recycle-all-opa" @click="onRecover($event)">全部恢复</div>
          <div class="recycle-all-opa" @click="onDelete($event)">全部删除</div>
        </div>
      </div>
      <div class="recycle-search flex-1 d-flex">
        <div :class="isStartSearch ? 'recycle-search-inner' : ''">
          <el-button
            link
            size="small"
            class="recycle-search-btn"
            @click="onSearch"
          >
            <svg-icon class="size-2" icon-class="new-search" />
          </el-button>
          <el-input
            ref="searchInput"
            class="recycle-search-input"
            v-model="filterText"
            v-if="isStartSearch"
            placeholder="请输入搜索内容"
            clearable
          />
        </div>
        <div class="change-btn" @click="onChangeMode">
          {{ mode === 1 ? "2023以前文档" : "2023以后文档" }}
        </div>
      </div>
    </div>
    <el-scrollbar class="flex-1" ref="scrollContent" @scroll="onScrollEvt">
      <el-tree
        v-if="list.length > 0"
        class="document-list-elm"
        ref="tree"
        :icon="recycleIcon"
        :icon-reverse="true"
        node-key="id"
        :data="list"
        :props="{ label: 'title', children: 'children', class: treeClass }"
        :draggable="false"
        :expand-on-click-node="false"
        :check-on-click-node="true"
        :highlight-current="true"
        :filter-node-method="filterTree"
        show-checkbox
        check-strictly
        @check="onChangeSelect"
      >
        <template #default="{ data }">
          <div class="recycle-item flex-1 d-between">
            <div class="recycle-node d-flex">
              <div v-if="iconMaps[data.iconType]" class="tree-icon d-center">
                <svg-icon
                  :icon-class="iconMaps[data.iconType].icon"
                  class-name="size-3"
                  :class="[
                    { active: data.active && data.iconType !== 1 },
                    iconMaps[data.iconType].className || '',
                  ]"
                />
              </div>
              <div v-else class="tree-icon d-center">
                <svg-icon
                  v-if="!data.parent"
                  icon-class="new-02"
                  class-name="size-3"
                />
                <svg-icon v-else icon-class="new-04" class-name="size-3" />
              </div>
              <span el-tree-node__label style="line-height: 33px">
                {{ data.title }}
              </span>
            </div>
            <div class="recycle-opa d-flex">
              <div class="d-flex">
                <div class="recycle-btn" @click="onRecover($event, data)">
                  <svg-icon icon-class="new-refresh"></svg-icon>
                </div>
                <div class="recycle-btn" @click="onDelete($event, data)">
                  <svg-icon icon-class="new-delete"></svg-icon>
                </div>
              </div>
              <div v-if="data.updateTime" class="recycle-time">
                {{ $proxy.moment("m月d日 H:i", data.updateTime) }}
              </div>
            </div>
          </div>
        </template>
      </el-tree>
      <div class="recycle-empty" v-else>暂无数据</div>
    </el-scrollbar>
    <el-dialog
      v-model="dialogVisible"
      :title="(type === 0 ? '全部删除' : '永久删除') + '确认'"
      width="380px"
    >
      <span style="color: red">
        是否确认 {{ type === 0 ? "全部删除" : "删除" }} {{ rowData.title }}？
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            :disabled="submitState === 1"
            @click="onDeleteSubmit()"
          >
            {{ submitState === 1 ? "删除中" : "确认" }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <tree-dialog
      :is-local="false"
      :group-enabled="true"
      :init-list="[
        {
          id: 'root',
          hasChildren: true,
          title: '我的模型树',
          children: $root.cloudTreeList,
        },
      ]"
      :theme="$root.config.theme"
      :filter-item="{}"
      v-model:dialog-type="treeDialogType"
      @submit="onRecoverSubmit"
    />
  </div>
</template>

<script>
import { iconMaps } from "../../../../utils/TreeConstants";
import TreeDialog from "../tree/TreeDialog";
import RecycleIcon from "./RecycleIcon";
import SvgIcon from "../../../../svgIcon/index.vue";

export default {
  components: { SvgIcon, TreeDialog },
  props: {
    showLeft: { type: Boolean, default: false },
    openRecycleBin: { type: Object, default: () => {} },
    treeType: { type: Object, default: () => {} },
  },
  data() {
    return {
      recycleIcon: RecycleIcon,
      mode: 1,
      // 0 loading 1 success 2 fail
      loadingState: 0,
      iconMaps,
      list: [],
      // 0 no select 1 select all 2 select no all
      selectState: 0,
      filterText: "",
      isStartSearch: false,

      dialogVisible: false,
      rowData: {},
      type: 0,
      treeDialogType: 0,
      treeMap: {},
      // 0 normal 1 submitting
      submitState: 0,
      recoverSum: 0,
      recoverTotal: 0,

      PageNo: 1,
      PageSize: 50,
      hasMore: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    onChangeMode() {
      this.mode = this.mode === 1 ? 2 : 1;
      this.clear();
      if (this.mode === 2) {
        this.getUnArticleList();
      } else {
        this.fetchData();
      }
    },
    clear() {
      this.selectState = 0;
      this.loadingState = 0;
      this.hasMore = true;
      this.list = [];
      this.type = 0;
      this.PageNo = 1;
      this.treeMap = {};
      this.rowData = {};
    },
    onScrollEvt() {
      const rect = this.$refs.scrollContent.$el.getBoundingClientRect();
      const scrollHeight = this.$refs.scrollContent.$el.scrollHeight;
      const scrollTop = this.$refs.scrollContent.$el.scrollTop;
      if (scrollTop + rect.height > scrollHeight - 120) {
        if (this.isLoadingMore || !this.hasMore) {
          return;
        }
        this.PageNo++;
        this.getUnArticleList();
      }
    },
    async getUnArticleList() {
      if (this.isLoadingMore || !this.hasMore) return;
      this.isLoadingMore = true;
      this.$api.article
        .unArticleList(this.PageNo, this.PageSize, this.treeType === 1)
        .then((res) => {
          this.isLoadingMore = false;
          if (res.data) {
            res.data.forEach((item) => {
              this.treeMap[item.id] = item;
            });
            this.list.push(...res.data);
            if (res.data.length < this.PageSize) {
              this.hasMore = false;
            }
          } else {
            this.hasMore = false;
          }
          this.isTable = true;
        });
    },
    async netRecover(params) {
      const res = await this.$api.article.recycleRecover(params);
      if (res.code !== 0) {
        throw new Error("error recover");
      }
      this.recoverSum++;
    },
    getList(items, parentId) {
      const result = [];
      items.forEach((item, idx) => {
        item.sort = idx;
        item.parentId = parentId;
        result.push({
          ...item,
          iconDetail: item.iconDetail? {...item.iconDetail}:null,
          parent: undefined,
          children: undefined,
        });
        if (item.children) {
          result.push(...this.getList(item.children, item.parentId));
        }
      });
      return result;
    },
    async startRecover(ids, parent, items) {
      this.recoverSum = 0;
      this.recoverTotal = ids.length;
      const all = [];
      for (let i = 0; i < ids.length; ++i) {
        all.push(
          this.netRecover({
            articleId: ids[i],
            parentId: parent.id === "root" ? "" : parent.id,
          })
        );
      }
      await Promise.all(all);
      const data = {
        parentId: parent.id === "root" ? "" : parent.id,
        items: this.getList(items, parent.id || "root"),
      }
      this.$api.article.recycleAppend(data);
    },
    async onRecoverSubmit(parent) {
      try {
        let recoverArr = [];
        if (this.rowData) {
          recoverArr = [this.rowData.id];
          await this.startRecover(recoverArr, parent, [this.rowData]);
          if (this.rowData.parent) {
            const idx = this.rowData.parent.children.findIndex(
              (r) => r.id === this.rowData.id
            );
            if (idx !== -1) {
              this.rowData.parent.children.splice(idx, 1);
            }
          } else {
            const idx = this.list.findIndex((r) => r.id === this.rowData.id);
            if (idx !== -1) {
              this.list.splice(idx, 1);
            }
          }
          delete this.treeMap[this.rowData.id];
          const ids = this.getIds(this.rowData.children);
          ids.forEach((id) => {
            delete this.treeMap[id];
          });
          this.selectState = 0;
          this.$root.recoverToParent(parent, [this.rowData]);
        } else if (this.selectState === 1) {
          recoverArr = this.list.map((r) => r.id);
          await this.startRecover(recoverArr, parent, this.list);
          this.$root.recoverToParent(parent, this.list);
          this.list = [];
          this.treeMap = {};
          this.selectState = 0;
          this.type = 0;
        } else if (this.selectState === 2) {
          const selectMap = this.getPds();
          recoverArr = Object.keys(selectMap);
          await this.startRecover(recoverArr, parent, Object.values(selectMap));
          for (const key in selectMap) {
            const item = selectMap[key];
            if (item.parent) {
              const idx = item.parent.children.findIndex(
                (r) => r.id === item.id
              );
              if (idx !== -1) {
                item.parent.children.splice(idx, 1);
              }
            } else {
              const idx = this.list.findIndex((r) => r.id === item.id);
              if (idx !== -1) {
                this.list.splice(idx, 1);
              }
            }
          }
          const checkedNodes = this.$refs.tree.getCheckedNodes();
          checkedNodes.forEach((item) => {
            delete this.treeMap[item.id];
          });
          this.selectState = 0;
          this.$root.recoverToParent(parent, Object.values(this.getPds()));
        }
        this.$root.tips("success", "已成功还原");
      } catch (e) {
        this.$root.tips("error", "还原失败");
      }
    },
    getPds() {
      const checkedNodes = this.$refs.tree.getCheckedNodes();
      const map = {};
      checkedNodes.forEach((item) => {
        map[item.id] = item;
      });
      const ids = {};
      checkedNodes.forEach((item) => {
        if (!item.parent || !map[item.parent.id]) {
          ids[item.id] = item;
        }
      });
      return ids;
    },
    async onDeleteSubmit() {
      if (this.submitState === 1) return;
      this.submitState = 1;

      try {
        if (this.type === 1) {
          const res = await this.$api.article.recycleDelete([this.rowData.id]);
          if (res.code === 0) {
            if (this.rowData.parent) {
              const idx = this.rowData.parent.children.findIndex(
                (r) => r.id === this.rowData.id
              );
              if (idx !== -1) {
                this.rowData.parent.children.splice(idx, 1);
              }
            } else {
              const idx = this.list.findIndex((r) => r.id === this.rowData.id);
              if (idx !== -1) {
                this.list.splice(idx, 1);
              }
            }

            delete this.treeMap[this.rowData.id];
            const ids = this.getIds(this.rowData.children);
            ids.forEach((id) => {
              delete this.treeMap[id];
            });
            this.$root.tips("success", "删除成功");
            this.dialogVisible = false;
            this.selectState = 0;
          } else {
            this.$root.tips("error", "删除失败");
          }
        } else if (this.selectState === 1) {
          const res = await this.$api.article.recycleDelete();
          if (res.code === 0) {
            this.list = [];
            this.treeMap = {};
            this.$root.tips("success", "删除成功");
            this.dialogVisible = false;
            this.selectState = 0;
          } else {
            this.$root.tips("error", "删除失败");
          }
        } else if (this.selectState === 2) {
          const selectMap = this.getPds();
          const res = await this.$api.article.recycleDelete(
            Object.keys(selectMap)
          );
          if (res.code === 0) {
            for (const key in selectMap) {
              const item = selectMap[key];
              if (item.parent) {
                const idx = item.parent.children.findIndex(
                  (r) => r.id === item.id
                );
                if (idx !== -1) {
                  item.parent.children.splice(idx, 1);
                }
              } else {
                const idx = this.list.findIndex((r) => r.id === item.id);
                if (idx !== -1) {
                  this.list.splice(idx, 1);
                }
              }
            }
            const checkedNodes = this.$refs.tree.getCheckedNodes();
            checkedNodes.forEach((item) => {
              delete this.treeMap[item.id];
            });
            this.$root.tips("success", "删除成功");

            this.dialogVisible = false;
            this.selectState = 0;
          } else {
            this.$root.tips("error", "删除失败");
          }
        }
        this.submitState = 0;
      } catch (e) {
        this.submitState = 0;
      }
    },
    onRecover(e, row) {
      e.stopPropagation();
      this.treeDialogType = 3;
      this.rowData = row;
    },
    onDelete(e, row) {
      e.stopPropagation();
      this.type = row ? 1 : 0;
      if (!row) {
        this.rowData = {
          title: "",
        };
      } else {
        this.rowData = row;
      }
      this.dialogVisible = true;
    },
    onSearchBlur() {
      this.$refs.searchInput.$el.classList.add("close");
      setTimeout(() => {
        this.isStartSearch = false;
      }, 250);
    },
    onSearch() {
      this.isStartSearch = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    filterTree(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    getIds(items) {
      if (items) {
        const ids = [];
        items.forEach((r) => {
          ids.push(r.id);
          if (r.children) {
            ids.push(...this.getIds(r.children));
          }
        });
        return ids;
      }
      return [];
    },
    treeClass(data, node) {
      return node.hasChecked ? "half-active" : "";
    },
    hasChecked(node) {
      return (
        node.childNodes.reduce((c, p) => {
          const num = p.checked || p.hasChecked ? 1 : 0;
          return c + num;
        }, 0) !== 0
      );
    },
    onChangeSelect(a, b) {
      const node = this.$refs.tree.getNode(a);
      if (b.checkedKeys.indexOf(a.id) !== -1) {
        const ids = this.getIds(this.treeMap[a.id].children);
        b.checkedKeys.push(...ids);
        b.checkedKeys = Array.from(new Set(b.checkedKeys));
        this.$refs.tree.setCheckedKeys(b.checkedKeys, false);
        let parent = node.parent;
        while (parent && parent.id) {
          parent.hasChecked = true;
          parent = parent.parent;
        }
      } else {
        let parent = a.parent;
        while (parent) {
          const idx = b.checkedKeys.indexOf(parent.id);
          if (idx !== -1) {
            b.checkedKeys.splice(idx, 1);
          }
          parent = parent.parent;
        }
        this.$refs.tree.setCheckedKeys(b.checkedKeys, false);
        let parentNode = node;
        while (parentNode && parentNode.id) {
          parentNode.hasChecked = this.hasChecked(parentNode);
          parentNode = parentNode.parent;
        }
      }
      const len = Object.keys(this.treeMap).length;
      this.selectState =
        b.checkedNodes.length === len ? 1 : b.checkedNodes.length > 0 ? 2 : 0;
    },
    onSelectAll() {
      if (this.selectState === 1) {
        this.selectState = 0;
        this.$refs.tree.getCheckedNodes().forEach((data) => {
          this.$refs.tree.getNode(data).hasChecked = false;
        });
        this.$refs.tree.setCheckedKeys([], false);
      } else {
        this.selectState = 1;
        const keys = Object.keys(this.treeMap);
        this.$refs.tree.setCheckedKeys(keys, false);
      }
    },
    formatData(data) {
      if (!data) return [];

      const children = (item) => {
        this.treeMap[item.id] = item;
        if (!item.children) return;
        item.children.forEach((child) => {
          child.parent = item;
          children(child);
        });
      };

      data.forEach((child) => {
        children(child);
      });
      return data;
    },
    fetchData() {
      this.loadingState = 0;
      this.$api.article.recycleTree().then((res) => {
        if (res.code === 0) {
          if (res.data) {
            res.data.sort((r1, r2) => {
              return new Date(r1.updateTime).getTime() >
                new Date(r2.updateTime).getTime()
                ? -1
                : 1;
            });
            this.list = this.formatData(res.data);
          }
          this.loadingState = 1;
        } else {
          this.loadingState = 2;
        }
      });
    },
    appendItems(items) {
      items.forEach((item) => {
        item.parent = undefined;
      });
      const list = this.formatData(items);
      this.list.unshift(...list);
    },
    onShowLeft() {
      this.$emit("update:showLeft", !this.showLeft);
    },
  },
};
</script>

<style scoped lang="scss">
.recycle {
  overflow: hidden;
  padding: 24px 74px 0;
}

.recycle-title {
  color: var(--main-text-color);
  font-family: PingFang SC;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.recycle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 8px;
  color: #888;
  border-bottom: 1px solid var(--border-color);
  height: 54px;
  white-space: nowrap;
}

.el-scrollbar {
  padding-bottom: 32px;
}

.check-box {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 1px solid;
  color: #999;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
}

.check-select-1 {
  &:before {
    font-size: 14px;
    content: "\e610";
    font-family: "iconfont";
  }
}

.check-select-2 {
  &:before {
    content: "";
    width: 6px;
    height: 1px;
  }
}

:deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: transparent;
  transition: all 0.25s;
}

.recycle-node {
  color: var(--main-text-color);

  .tree-icon {
    color: #737373;
    margin-right: 10px;
  }
}

.recycle-opa {
  padding: 0 14px;
}
.recycle-time {
  font-size: 14px;
  color: #999;
  width: 120px;
}
.recycle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin: 0 8px;
  color: #999;
  cursor: pointer;
  transition: color 0.25s;
  opacity: 0;
  pointer-events: none;

  &:hover {
    color: #606060;
  }
}

.recycle-btn.red {
  color: #f00;

  &:hover {
    color: #a25353;
  }
}

:deep .el-tree-node__content:hover {
  .recycle-btn {
    opacity: 1;
    pointer-events: auto;
  }
}

.recycle-search {
  padding: 0 0 0 16px;
  justify-content: flex-end;
}

.recycle-search-btn {
  color: var(--main-text-color);
  margin-right: 10px;
}

.recycle-search-inner {
  border: 1px solid var(--main-text-color);
  border-radius: 8px;
  padding-left: 8px;
  max-width: 400px;
  margin-right: 10px;
}

.recycle-search-input {
  margin-right: 10px;
  animation: searchA 0.25s forwards;

  :deep .el-input__wrapper {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }

  :deep .el-input__inner {
    color: var(--main-text-color);
  }
}

.recycle-search-input.close {
  animation: searchB 0.25s forwards;
}

@keyframes searchB {
  0% {
    width: 360px;
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}

@keyframes searchA {
  0% {
    width: 0;
  }
  100% {
    width: 360px;
  }
}

.recycle-all-text {
  font-size: 14px;
}

.recycle-all {
  padding: 0 12px;
}

.recycle-all-opa {
  cursor: pointer;
  font-size: 14px;
  padding: 4px 12px;
  margin: 0 4px;
  border-radius: 8px;
  color: var(--main-text-color);
}

.el-button.is-disabled,
.el-button.is-disabled:hover {
  cursor: not-allowed;
  opacity: 0.6;
}

.recycle-empty {
  padding: 20px 0;
  text-align: center;
  color: #999;
}

.change-btn {
  cursor: pointer;
  padding: 4px 12px;
  transition: all 0.2s;
  white-space: nowrap;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
}

:deep .el-tree-node__expand-icon {
  padding: 6px 0 !important;
}
.recycle-item {
  padding-left: 6px;
}
.recycle .el-checkbox__inner {
  border-radius: 4px;
  border: 1px solid #494949;
}

:deep .el-tree-node__content .el-checkbox {
  margin: 0 8px;

  .el-checkbox__inner {
    padding: 0;
    margin: 0;
    &:after {
      content: "\e610";
      font-family: "iconfont";
      transition: none !important;
      border: none;
    }
  }

  .is-checked {
    //.el-checkbox__inner {
    //  background-color: #999;
    //  border-color: #999;
    //}

    .el-checkbox__inner::after {
      transform: none;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
}
</style>
