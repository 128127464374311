<template>
  <div class="article-history" :class="{show:show, hide:!show}">
    <div class="history-left">
      <div class="article-history-header">
        <el-button class="history-back" @click="onClose">
          <svg-icon icon-class="back"></svg-icon>
          返回文档
        </el-button>
        <div class="flex-1 d-center" type="gray">
          <el-button class="history-recover" @click="onRecover">
            还原此文档
          </el-button>
        </div>
      </div>
      <div class="history-left-inner">
        <mxs-editor v-if="curHistory && loadingState===1 && historyContent"
                    :article-id="articleId"
                    :title="historyTitle"
                    :disabled-collaborative="true"
                    :no-net-data="true"
                    :read-only="true"
                    :init-value="historyContent"
        />
        <div v-else-if="loadingState===0" class="w-100 h-100 d-center">
          <div class="ui-loading"></div>
        </div>
      </div>
    </div>
    <drag-view class="history-right"
               direction="left"
               :no-fixed="true"
               :init-width="300"
               :min-width="0">
      <div class="article-history-header">
        <span class="history-label">历史记录</span>
      </div>
      <el-scrollbar>
        <ul class="article-history-list">
          <li v-for="(item, idx) in histories"
              :class="{active: curHistory&&curHistory.title===item.title&&curHistory.articleUpdateTime===item.articleUpdateTime}"
              :key="idx+'-s'" @click="onChangeCur(item)">
            <div class="ah-content">
              <div class="history-time">{{ item.articleUpdateTime }}</div>
              <div class="history-title">{{ item.title }}</div>
            </div>
          </li>
        </ul>
      </el-scrollbar>
    </drag-view>
  </div>
</template>

<script>
import DragView from "../widget/DragView";
import MxsEditor from "../../../../components/mxs-editor";

export default {
  name: "ArticleHistory",
  props: {
    articleId: {type: String, default: ''}
  },
  components: {
    DragView,
    MxsEditor
  },
  data() {
    return {
      histories: [],
      curHistory: null,
      // 0 loading 1 success 2 fail
      loadingState: 0,
      historyTitle: '',
      historyContent: '',
      show: true
    }
  },
  mounted() {
    if (this.articleId) {
      this.fetchData();
    }
  },
  watch: {
    curHistory(val, old) {
      if (val !== old) {
        this.fetchDetail();
      }
    }
  },
  methods: {
    close() {
      this.show = false;
      setTimeout(()=>{
        this.$emit('close')
      }, 250)
    },
    onClose() {
      this.close();
      // this.$emit('close')
    },
    onRecover() {
      this.$emit('recover', this.historyContent);
      this.close();
    },
    fetchData() {
      this.loadingState = 0;
      this.$api.article.historyArticleList(this.articleId).then((res) => {
        if (res.data.length > 0) {
          this.histories = res.data;
          this.curHistory = this.histories[0];
        } else {
          this.histories = [];
          this.loadingState = 1;
        }
      }).catch(() => {
        this.loadingState = 2;
      })
    },
    fetchDetail() {
      if (!this.curHistory) return;
      this.loadingState = 0;
      this.$api.article.historyArticleDetail(this.curHistory.id).then((res) => {
        this.historyTitle = res.data.title;
        this.historyContent = res.data.content;
        this.loadingState = 1;
      }).catch(() => {
        this.loadingState = 2;
      })
    },
    onChangeCur(item) {
      this.curHistory = item;
    }
  }
}
</script>

<style lang="scss">
.article-history {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: var(--color-atl-left);
  display: flex;
  &.show {
    animation: showHistory .25s forwards;
  }
  &.hide {
    animation: hideHistory .25s forwards;
  }
}

@keyframes showHistory {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes hideHistory {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}

.article-history-header {
  height: 64px;
  min-height: 64px;
  display: flex;
  padding: 0 32px;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
}

.history-back {
  background-color: transparent;
  border: none;
  color: #eee;
  font-size: 14px;

  .svg-icon {
    margin-right: 6px;
  }

  &:hover {
    color: #fff;
    background-color: transparent;
    border: none;
  }

  &:active, &:focus {
    background-color: transparent;
    color: #eee;
    border: none;
  }
}

.history-recover {
  background-color: #333;
  color: #eee;
  font-size: 12px;
  border-color: #333;

  &:hover {
    color: #fff;
    background-color: #666;
    border-color: #666;
  }

  &:active, &:focus {
    background-color: #333;
    color: #eee;
    border-color: #333;
  }
}

.history-label {
  font-size: 16px;
  color: #eee;
}

.history-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.history-right {
  width: 300px;
  display: flex;
  flex-direction: column;

  .drag-line-right {
    top: 0;
    bottom: 0;

    &:before {
      width: 1px;
    }
  }

  .el-scrollbar {
    flex: 1;
  }
}

.article-history-list {
  color: #fff;
  padding: 0 0 20px;
  margin: 0;
  font-size: 14px;

  .ah-content {
    padding: 16px 20px 16px 0;
    margin-left: 20px;
    border-bottom: 1px solid var(--color-border);
    flex: 1;
  }

  li {
    list-style: none;
    display: flex;
    align-items: center;
    border-left: 3px solid transparent;
    border-radius: 8px;
    transition: all .25s;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: #eee;
      margin: 0 10px;
      border-radius: 2rem;
    }

    &.active, &:hover {
      background-color: #201e3d;
      border-left: 3px solid #3370ff;

      .ah-content {
        border-bottom: 1px solid var(--color-border);
      }
    }
  }

  .history-title {
    font-size: 12px;
  }
}

.history-left-inner {
  width: 100%;
  height: 100%;
}
</style>
